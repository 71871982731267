import Cookies from "js-cookie";

var CryptoJS = require("crypto-js");
export const encryptToken = (currentURL) => {
  return CryptoJS.AES.encrypt(currentURL, "9GxbhX0FNMP7Y8MP").toString();
};

export const validateEmail = (email) => {
    const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
    return regex.test(email); 
  };

  export const setStorageData = (key, value) => {
    return localStorage.setItem(key,JSON.stringify(value));
  }

  export const getStorageData = (key) => {
    return JSON.parse(localStorage.getItem(key));
  }

  export const setCookiesData = (key, value) => {
    try {
      if (typeof value !== 'string') {
        throw new Error('Invalid data format');
      }
      const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 2);
      Cookies.set(key, encryptedData,{expires: expirationDate});
      return encryptedData;
    } catch (error) {
      console.error('Error in setCookiesData:', error);
      // Handle the error gracefully, e.g., by returning an error message.
      return 'Error: Unable to set data in cookies';
    } // You might want to return the encrypted data for further debugging
  }

  export const setCookiesDataWithTime = (key, value,rememberMe) => {
    try {
      if (typeof value !== 'string') {
        throw new Error('Invalid data format');
      }
      const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
      const expirationDate = new Date();
      if(rememberMe){
        expirationDate.setDate(expirationDate.getDate() + 7);
      }else {
        expirationDate.setDate(expirationDate.getDate() + 1);
      }
      Cookies.set(key, encryptedData,{expires: expirationDate});
      return encryptedData;
    } catch (error) {
      console.error('Error in setCookiesData:', error);
      // Handle the error gracefully, e.g., by returning an error message.
      return 'Error: Unable to set data in cookies';
    } // You might want to return the encrypted data for further debugging
  }

  const decruptUrl = (str) => {
    return CryptoJS.AES.decrypt(str, 'secret_key').toString(CryptoJS.enc.Utf8);
  }

  export const decodeURL = () => {
    const params = new URLSearchParams(window.location.search);
    const redirectInfoParam = params.get('redirectInfo');
const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
try {
  const redirectObject = JSON.parse(decodedRedirectInfo);
  const decryptedURL  = decruptUrl(redirectObject.encryptedURL);
 
} catch (e) {
  console.error('Error parsing redirectInfo:', e);
}
  }

  export const getCookiesData = (key) => {
    const encryptedData = Cookies.get(key);

  try {
    if (encryptedData) {
      const decryptedData = CryptoJS.AES.decrypt(encryptedData, "Dad").toString(CryptoJS.enc.Utf8);
     
      return JSON.parse(decryptedData);
    } else {
      return null; // Return null or handle the case where the cookie data is missing
    }
  } catch (error) {
    console.error('Error in getCookiesData:', error);
    // Handle decryption or parsing errors gracefully
    return null; // Return null or handle the error as appropriate
  }
  }

  export const removeAllCookies = () => {
    const cookies = Cookies.get();
    for (const cookieName in cookies) {
      Cookies.remove(cookieName); // Remove each cookie by name
    }
  }
  const userMange = [
    {
      id: "name",
      label: " Name",
      sort: true,
      minWidth: 200,
      color: "#7b7d84",
      selector: (row) => row.name,
      align: "center",
      border: "1px solid #ededed",
      borderTop: "4px soild #FFD705",
      // class: "px-1 text-left  upper-case table-header-item font-size-xs",
    },
    {
      id: "plaxaarID",
      label: "Plexar ID",
      // class: "text-left  upper-case table-header-item font-size-xs",
      minWidth: 170,
      align: "center",
      selector: (row) => row.plaxaarID,
      border: "1px solid #ededed",
      color: "#7b7d84",
    },
    {
      id: "role",
      label: "Role",
      // class: "text-left  upper-case table-header-item font-size-xs",
      color: "#7b7d84",
      align: "center",
      selector: (row) => row.role,
      border: "1px solid #ededed",
      minWidth: 170,
      borderTop: "",
    },
    {
      id: "email",
      label: "Email",
      // class: "text-left  upper-case table-header-item font-size-xs",
      color: "#7b7d84",
      selector: (row) => row.email,
      border: "1px solid #ededed",
      align: "center",
      minWidth: 170,
      borderTop: "",
    },
    {
      id: "status",
      label: "Status",
      // class: "text-left  upper-case table-header-item font-size-xs",
      color: "#7b7d84",
      align: "center",
      border: "1px solid #ededed",
      selector: (row) => row.status,
      minWidth: 170,
      borderTop: "",
    },
    {
      id: "active",
      label: "Active",
      // class: "text-left  upper-case table-header-item font-size-xs",
      align: "center",
      color: "#7b7d84",
      border: "1px solid #ededed",
      selector: (row) => row.active,
      minWidth: 170,
      borderTop: "",
    },
    {
      id: "actions",
      label: "Actions",
      // class: "text-center  upper-case table-header-item font-size-xs",
      align: "center",
      border: "1px solid #ededed",
      color: "#7b7d84",
      selector: (row) => row.action,
      minWidth: 170,
      borderTop: "",
    },
  ];
  


  export const removeAllLocalStorage = () => {
    return localStorage.clear();
  }

  /* -------------------------------------------------------------------------- */
  /*                            Cookies in SubDomain                            */
  /* -------------------------------------------------------------------------- */


  export const setCookiesDataInSubDomain = (key, value) => {
    try {
      if (typeof value !== "string") {
        throw new Error("Invalid data format");
      }
      const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
      const expirationDate = new Date();
      expirationDate.setDate(expirationDate.getDate() + 2);
      Cookies.set(key, encryptedData, { expires: expirationDate, domain: ".plexaar.com" });
      return encryptedData;
    } catch (error) {
      console.error("Error in setCookiesData:", error);
      return "Error: Unable to set data in cookies";
    }
  }


  export const setCookiesDataWithTimeInSubDomain = (key, value, rememberMe) => {
    try {
      if (typeof value !== "string") {
        throw new Error("Invalid data format");
      }
      const encryptedData = CryptoJS.AES.encrypt(value, "Dad").toString();
      const expirationDate = new Date();
      if (rememberMe) {
        expirationDate.setDate(expirationDate.getDate() + 7);
      } else {
        expirationDate.setDate(expirationDate.getDate() + 1);
      }
      Cookies.set(key, encryptedData, { expires: expirationDate });
      return encryptedData;
    } catch (error) {
      console.error("Error in setCookiesData:", error);
      return "Error: Unable to set data in cookies";
    }
  };
  
  
  export const getCookiesDataInSubDomain = (key) => {
    const encryptedData = Cookies.get(key);
    try {
      if (encryptedData) {
        const decryptedData = CryptoJS.AES.decrypt(encryptedData, "Dad").toString(CryptoJS.enc.Utf8);
        return JSON.parse(decryptedData);
      } else {
        return null;
      }
    } catch (error) {
      console.error("Error in getCookiesData:", error);
      return null;
    }
  };
  
  export const removeAllCookiesInSubDomain = () => {
    const cookies = Cookies.get();
    for (const cookieName in cookies) {
      Cookies.remove(cookieName, { domain: ".plexaar.com" });
    }
  };


  // const formatDate = (dateString) => {
  //   if (!dateString) return ""; // Return empty string if dateString is not provided
  //   const date = new Date(dateString);
  //   const year = date.getFullYear();
  //   const month = String(date.getMonth() + 1).padStart(2, "0");
  //   const day = String(date.getDate()).padStart(2, "0");
  //   return `${year}-${month}-${day}`;
  // };
  

  export const getPrevUrls = () => {
    const params = new URLSearchParams(window.location.search);
    const redirectInfoParam = params.get('redirectInfo');
const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
try {
  const redirectObject = JSON.parse(decodedRedirectInfo);
  const decryptedURL  = decruptUrl(redirectObject.encryptedURL);
 
      return decryptedURL;
} catch (e) {
  console.error('Error parsing redirectInfo:', e);
}
  }
  export function encryptURL(currentURL) {
    return CryptoJS.AES.encrypt(currentURL, "9GxbhX0FNMP").toString();
  }
import React, { useState, useEffect } from "react";
import { AiOutlineUser } from "react-icons/ai";
import { TbFileSettings } from "react-icons/tb";
import { MdAdd } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { fetchProductRoleRequest } from "../../../Store/User/actions";
import { customSnackBar, DataNotFound } from "../../../utils";
import { EditSvg } from "../../../Assets/svgs";
import instance from "../../../utils/utils";
import { Spin } from "antd";
import { Backdrop, CircularProgress } from "@mui/material";

const Permissions = ({
  showNewRole,
  handleAddNewRole,
  selectedOptions,
  productIndex,
  setSelectedPermissions,
  setRole,
  role,
  serviceId,
  data,
  businessId,
  handleRoles,
  selectedRole,
  setSelectedRole,
  selectedSwitch,
  permission,
  roleType,
  loader,
}) => {
  const dispatch = useDispatch();
  const [loading,setLoading]=useState(false)
  const serviceRole = useSelector((state) => state.User?.serviceRole);
  const roles = serviceRole[serviceId];
  const [showPermissions, setShowPermissions] = useState(true);
  const selectedRolese = useSelector((state) => state.roleReducer.selectedRole);
  useEffect(() => {
    const id = serviceId;
    dispatch(fetchProductRoleRequest({ id, businessId,setLoading }));
  }, [serviceId]);
  const handleEditClick = async (row) => {
    try {
      // setLoader(true);

      const response = await instance.get(`/business_svc/pv/role/${row}`);

      if (response.data.code === 0) {
        const result = response?.data?.result;

        // Parse permissions and modules directly
        const preselectedPermissions = [];
        const preexpandedModules = [];

        result?.role_modules_permissions?.forEach((module) => {
          module.module_permissions.forEach((perm) => {
            preselectedPermissions.push({
              permissionKey: perm.identifier,
              module_id: module.id,
              id: perm.id,
            });
          });

          if (module.module_permissions.length > 0) {
            preexpandedModules.push(module.id);
          }
        });

        // Update states directly
        setSelectedPermissions(preselectedPermissions);
        // setExpandedPermissions(preexpandedModules);
        setRole(result.name || "");

        // Store data in localStorage
        localStorage.setItem("RoleInfo", JSON.stringify(result));
        localStorage.setItem("action", "edit");

        // Show the edit role modal/component
        // setLoader(false);
        showNewRole(true);
      } else {
        // setLoader(false);
        customSnackBar("Something went wrong. Please try again.");
      }
    } catch (error) {
      // setLoader(false);
      console.error("Error fetching role data:", error);
      // customSnackBar("An error occurred while fetching data. Please try again.");
    }
  };
console.log("loading",loading)
  const action = "edit";
  return (
    <>
      <div
        className="w-100 pt-4 d-sm-flex !m-n2 justify-content-between"
        // style={{ backgroundColor: "#FBFCFF" }}
      >
        <div className="d-flex p-2 pb-0">
          <AiOutlineUser style={{ color: "#b2b2b2", fontSize: 20 }} />
          <span className="span ps-1">Role</span>
        </div>
        {permission === null ||
              permission?.canAddNewRole  ||
              permission?.canAddNewRole === null ||
              permission?.canAddNewRole === undefined ? (
        <div
          className="d-flex p-2 pb-0"
          onClick={() => handleAddNewRole(data, action)}>
          <MdAdd style={{ color: "#FFD705", fontSize: 20 }} />
          <span
            className="span ps-1"
            style={{
              color: "#FFD705",
              fontSize: "12px",
              fontWeight: 500,
              cursor: loader ? "progress" : "pointer",
            }}>
            Add New Role
          </span>
        </div>
              ):null}
      </div>
      {/* { */}
        <Backdrop
              sx={(theme) => ({
                color: "#fff",
                zIndex: theme.zIndex.drawer + 1,
              })}
              open={loading}
              onClick={()=>{
                setLoading(false)
              }}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
        {/* ):( */}
      <div
        className="d-flex flex-wrap px-2 align-items-center"
        // style={{ backgroundColor: "#FBFCFF" }}
      >
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "repeat(4, 1fr)",
            gap: "20px",
          }}>
          {roles?.length > 0 ? (
            roles?.map((option) => (
              <div key={option.value} className="d-flex flex-column">
                <div
                  key={option.value}
                  className="d-flex flex-column flex-wrap pe-4">
                  <label
                    style={{
                      fontSize: "13px",
                      display: "flex",
                      flexDirection: "row",
                    }}>
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={option.id}
                      checked={selectedRolese.includes(option.id)}
                      onChange={() =>
                        handleRoles(
                          option.id,
                          option?.service?.id,
                          setSelectedRole,
                          "Product"
                        )
                      }
                      style={{
                        marginBottom: "20px",
                        marginRight: "10px",
                        marginTop: "3px",
                      }}
                    />
                    {option.name}
                  </label>
                </div>
              </div>
            ))
          ) : (
            <div>
              <DataNotFound />
            </div>
          )}
        </div>
      </div>
        {/* )} */}
      {selectedRole !== null &&
        roleType === "Product" &&
        (permission?.canViewUserPermissions ||
        permission?.canViewUserPermissions === null ||
        permission?.canViewUserPermissions === undefined ? (
          <div className="d-flex flex-column flex-wrap px-2">
            <div className="d-flex justify-content-between">
              <span
                className={`${!showPermissions ? "permission2" : "permission"}`}
                onClick={() => setShowPermissions((prev) => !prev)}>
                {`${showPermissions ? "See" : "Hide"} Permissions`}
              </span>
              {!showPermissions &&
                (permission?.canUpdateUserPermissions ||
                permission?.canUpdateUserPermissions === null ||
                permission?.canUpdateUserPermissions === undefined ? (
                  <div
                    onClick={() => handleEditClick(selectedRole)}
                    className="d-flex cursor-pointer">
                    <EditSvg />
                  </div>
                ) : null)}
            </div>
          
              <div
                style={{
                  height: !showPermissions ? "15vh" : "0vh",
                  overflow: "auto",
                }}
                className="d-flex flex-column">
                {!showPermissions &&
                  roles
                    .filter((role) => role.id === selectedRole) // Filter by selected role
                    .map((selectedRole) => {
                      const modules =
                        selectedRole.role_modules_permissions || []; // Ensure modules exist

                      return modules.map((module) => {
                        const permissions = module.module_permissions || []; // Extract permissions

                        // Filter active permissions
                        const activePermissions = permissions.filter(
                          (permission) => permission.status === "active"
                        );

                        return (
                          <ul
                            className="d-flex flex-column orderlist"
                            key={module.id}>
                            <div>
                              <TbFileSettings style={{ color: "#b2b2b2" }} />{" "}
                              <span className="span pt-3">{module.name}</span>
                              <div className="ps-3">
                                {activePermissions.map((permission) => (
                                  <li
                                    className="text-capitalize"
                                    key={permission.id}>
                                    {permission.title}
                                  </li>
                                ))}
                              </div>
                            </div>
                          </ul>
                        );
                      });
                    })}
              </div>
           
          </div>
        ) : null)}
    </>
  );
};

export default Permissions;
import * as Yup from "yup";

//column

const userMange = [
  {
    id: "name",
    label: " Name",
    sort: true,
    minWidth: 200,
    color: "#7b7d84",
    selector: (row) => row.name,
    align: "center",
    border: "1px solid #ededed",
    borderTop: "4px soild #FFD705",
    // class: "px-1 text-left  upper-case table-header-item font-size-xs",
  },
  {
    id: "plaxaarID",
    label: "Plexar ID",
    // class: "text-left  upper-case table-header-item font-size-xs",
    minWidth: 170,
    align: "center",
    selector: (row) => row.plaxaarID,
    border: "1px solid #ededed",
    color: "#7b7d84",
  },
  {
    id: "role",
    label: "Role",
    // class: "text-left  upper-case table-header-item font-size-xs",
    color: "#7b7d84",
    align: "center",
    selector: (row) => row.role,
    border: "1px solid #ededed",
    minWidth: 170,
    borderTop: "",
  },
  {
    id: "email",
    label: "Email",
    // class: "text-left  upper-case table-header-item font-size-xs",
    color: "#7b7d84",
    selector: (row) => row.email,
    border: "1px solid #ededed",
    align: "center",
    minWidth: 170,
    borderTop: "",
  },
  {
    id: "status",
    label: "Status",
    // class: "text-left  upper-case table-header-item font-size-xs",
    color: "#7b7d84",
    align: "center",
    border: "1px solid #ededed",
    selector: (row) => row.status,
    minWidth: 170,
    borderTop: "",
  },
  {
    id: "active",
    label: "Active",
    // class: "text-left  upper-case table-header-item font-size-xs",
    align: "center",
    color: "#7b7d84",
    border: "1px solid #ededed",
    selector: (row) => row.active,
    minWidth: 170,
    borderTop: "",
  },
  {
    id: "actions",
    label: "Actions",
    // class: "text-center  upper-case table-header-item font-size-xs",
    align: "center",
    border: "1px solid #ededed",
    color: "#7b7d84",
    selector: (row) => row.action,
    minWidth: 170,
    borderTop: "",
  },
];

const contentMange = [
  {
    id: "name",
    label: "Template Name",
    sort: true,
    minWidth: 200,
    color: "#7b7d84",
    selector: (row) => row.name,
    align: "center",
    border: "1px solid #ededed",
    borderTop: "4px soild #FFD705",
    // class: "px-1 text-left  upper-case table-header-item font-size-xs",
  },
  {
    id: "plaxaarID",
    label: " ID",
    // class: "text-left  upper-case table-header-item font-size-xs",
    minWidth: 170,
    align: "center",
    selector: (row) => row.plaxaarID,
    border: "1px solid #ededed",
    color: "#7b7d84",
  },
  {
    id: "role",
    label: "No of Sections",
    // class: "text-left  upper-case table-header-item font-size-xs",
    color: "#7b7d84",
    align: "center",
    selector: (row) => row.role,
    border: "1px solid #ededed",
    minWidth: 170,
    borderTop: "",
  },
  {
    id: "email",
    label: "Created On",
    // class: "text-left  upper-case table-header-item font-size-xs",
    color: "#7b7d84",
    selector: (row) => row.email,
    border: "1px solid #ededed",
    align: "center",
    minWidth: 170,
    borderTop: "",
  },
  {
    id: "status",
    label: "Status",
    // class: "text-left  upper-case table-header-item font-size-xs",
    color: "#7b7d84",
    align: "center",
    border: "1px solid #ededed",
    selector: (row) => row.status,
    minWidth: 170,
    borderTop: "",
  },
 
  
];

// dummydata{
export  const permissionData = [
    {id:1 ,value: "Create", key: "create" },
    { id:2 ,value: "View", key: "read" },
    { id:3 ,value: "Update", key: "update" },
    { id:4 ,value: "Delete", key: "delete" },
  ];
//search

export const OnSearch = (searchedVal, data, setRowData) => {
  if (searchedVal.length > 0) {
    const filteredRows = data.filter((row) => {
      let match = false;
      if (!match) {
        match = row.userName
          ? row.userName.toLowerCase().includes(searchedVal.toLowerCase())
          : "";
      }
      if (!match) {
        match = row.role
          ? row.role.toLowerCase().includes(searchedVal.toLowerCase())
          : "";
      }
      if (!match) {
        match = row.status.toLowerCase().includes(searchedVal.toLowerCase());
      }
      return match;
    });
    setRowData(filteredRows);
  } else {
    setRowData([...data]);
  }
};

export const userInitialValue = (action, editData) => {
  const initialValues = {
    lastName: action === "edit" ? editData.lastname : "",
    firstName: action === "edit" ? editData.firstname : "",
    email: action === "edit" ? editData.email : "",
  };
  return initialValues;
};
export const formateData = (values, team, id,action,userId) => {
  return {
    userId:null,
    firstname: values.firstName,
    lastname: values.lastName,
    password: "arshad",
    userName: values.firstName +"  " +values.lastName,
    email: values.email,
    team: null,
    roleType: "Backend",
    business: action==="edit"?[team]:[team],
    userRoles:id, // Use the userRoleID variable here instead of id
  };
};
export const validationSchema = Yup.object({
  firstName: Yup.string().required("Enter the First Name"),
  lastName: Yup.string().required("Enter the Last Name"),
  email: Yup.string().email().required("Enter email"),
  // description: Yup.string().required("Team is required"),
});

export const permissions = [
  { values: "Service Booking Permission" },
  { values: "service Edit Permission" },
  { values: "service Delete Permission" },
  { values: "Service Booking Permission" },
  { values: "4 Permission" },
  { values: "5 Permission" },
  { values: "6 Permission" },
];
const options = [
  { label: "Admin", value: "admin" },
  { label: "Developer", value: "developer" },
  { label: "Finance", value: "finance" },
  { label: "App Manager", value: "app_manager" },
  { label: "Sale", value: "sale" },
  { label: "Customer Support", value: "customer_support" },
  { label: "Marketing", value: "marketing" },
];
// const permission = [
//   {
//     id: 1,
//     permission: "Booking",
//     value: ["Create", "Edit", "View", "Delete"],
//   },
//   {
//     id: 2,
//     permission: "Events",
//     value: ["Create", "Edit", "View", "Delete"],
//   },
//   {
//     id: 3,
//     permission: "Documents",
//     value: ["Add", "Save", "View"],
//   },
//   {
//     id: 3,
//     permission: "Forms",
//     value: ["Edit", "Save", "View"],
//   },
//   {
//     id: 3,
//     permission: "Setting",
//     value: ["Notification Access", "Email Access"],
//   },
// ];

const businessDatas = [
  {
    image: "images/download.jpg",
    businessName: "Plaxaar Business",
  },
  {
    image: "images/logo.jpg",
    businessName: "Slack",
  },
  {
    image: "images/user.jpg",
    businessName: "LinkedIn",
  },
];
export { userMange, options, businessDatas,contentMange };

import React from "react";
import styles from "../AppLayout.module.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { FormCreatorSvg } from "../../../data/SidebarManu";

const Sidebar = () => {
  const history = useHistory();
  // const url =useSelector()
  const url = useSelector((state) => state.Business?.url);

  const handleProfile = () => history.push("/business");
  const handleBack = () => {
    // let url = localStorage.getItem("url");
    window.location.href = url; // Redirect to the desired URL
    //
    localStorage.clear();
    // Move to the desired domain URL
  };
  return (
    <div className={`${styles.sidebar} pt-2 bg-white bod_overflow`}>
      <ul
        className={`pt-2 w-100 d-flex flex-column ps-1 ${styles.sidebarContent}`}>
        <div className={`${styles.profileList}`}>
          <li
            className={`cursor-pointer w-100 mb-3 py-2 pe-1 rounded-start ${styles.profileList}`}
            onClick={handleProfile}>
            <div className="d-flex align-items-center">
              <div style={{ marginLeft: "4.5px" }}>
                <img
                  src="/images/Frame 515605.svg"
                  className={`${styles.textYellow}`}
                />
              </div>
              <div className={`ps-2 pt-1 ${styles.profile}`}>Business</div>
            </div>
          </li>
        </div>

    
        <div className={`${styles.profileList}`}>
          <li
            className={`cursor-pointer w-100  py-2 pe-1 rounded-start ${styles.profileList}`}
            onClick={handleBack}>
            <div className="d-flex align-items-center">
              <div style={{ marginLeft: "4.5px" }}>
                <img
                  src="/images/Frame 515605.svg"
                  className={`${styles.textYellow}`}
                />
              </div>
              <div className={`ps-2 pt-1 ${styles.profile}`}>Back</div>
            </div>
          </li>
        </div>
      </ul>
    </div>
  );
};

export default Sidebar;

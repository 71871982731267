import produce from "immer";
import {
  REGISTER_USER_SUCCESSFUL,
  LOGIN_SUCCESS,
  REGISTERAPI_SUCCESS,
  SEND_OTP_SUCCESS_EMAIL,
  SET_USER_PASSWORD_SUCCESS,
  ADD_USER_MOBILE_SUCCESS,
  GET_USER_DATA_AFTER_LOGIN_SUCCESS,
  ADD_SECONDARY_EMAIL_SUCCESS,
  VERIFY_SECONDARY_EMAIL_SUCCESS,
  VERIFY_SECONDARY_MOBILE_SUCCESS,
  GET_ALL_GENDERS_SUCCESS,
  ADD_SECONDARY_PHONE_NUMBER_SUCCESS,
  MOBILE_OTP_VERIFY_SUCCESS,
  UPLOAD_PROFILE_IMG_SUCCESS,
  SUCCESS_LOGIN_USER,
  PERMISSION_API_SUCCESS,
  FORGET_PASSWORD_SUCCESS,
  GET_USER_DATA_AFTER_LOGIN,
} from "./actionTypes";

const initialState = {
  user: null,
  role: null,
  tokenn: null,
  registersuccess: null,
  registersuccessId: null,
  otpuser: null,
  alluserdata: null,
  mobilenumberreducer: null,
  alluserdataafterlogin: null,
  secondaryemailsuccess: null,
  allgenders: null,
  secondaryMobile: null,
  primaryEmailVerify: null,
  uploadedprofileinfoimg: null,
  userloginsuccessyasir: null,
  permissionsModule:null,
  forgetPassword:null
};

const Auth = produce((state, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      state.user = action.payload.user;
      state.role = action.payload.role;
      state.token = action.payload.token;
      break;
    case REGISTER_USER_SUCCESSFUL:
      state.user = action.payload.user;
      state.token = action.payload.accesToken;
      break;
    case REGISTERAPI_SUCCESS:
      state.registersuccess = action.payload;
      state.registersuccessId = action.payload.result.id;
      break;

      case FORGET_PASSWORD_SUCCESS:
        state.forgetPassword = action.payload;
        break;
    case SUCCESS_LOGIN_USER:
      state.userloginsuccessyasir = action.payload;
      break;

      case GET_USER_DATA_AFTER_LOGIN:
        state.userloginsuccessyasir = action.payload;
        break;
    case SEND_OTP_SUCCESS_EMAIL:
      state.otpuser = action.payload.otpCode;
      break;
    case SET_USER_PASSWORD_SUCCESS:
      state.alluserdata = action.payload;
      state.userloginsuccessyasir = action.payload;
      break;
    case ADD_USER_MOBILE_SUCCESS:
      state.mobilenumberreducer = action.payload;
      break;
    case UPLOAD_PROFILE_IMG_SUCCESS:
      return {
        ...state,
        uploadedprofileinfoimg: action.payload,
        alluserdataafterlogin: {
          ...state.alluserdataafterlogin,
          imageURL: action.payload,
        }
      };
    case GET_USER_DATA_AFTER_LOGIN_SUCCESS:
      state.alluserdataafterlogin = action.payload;
      break;
      case PERMISSION_API_SUCCESS:
        if (action.payload && action.payload.length > 0) {
          state.permissionsModule = action.payload;
        }
        break;
    case ADD_SECONDARY_EMAIL_SUCCESS:
      return {
        ...state,
        secondaryEmail: action.payload,
        alluserdataafterlogin: [
          { ...state.alluserdataafterlogin[0], secondaryEmail: action.payload },
        ],
      };
    case VERIFY_SECONDARY_EMAIL_SUCCESS:
      return {
        ...state,
        secondaryEmailVerify: action.payload,
        alluserdataafterlogin: [
          {
            ...state.alluserdataafterlogin[0],
            secondaryEmailVerify: action.payload,
          },
        ],
      };
    case VERIFY_SECONDARY_MOBILE_SUCCESS:
      return {
        ...state,
        secondaryMobileVerify: action.payload,
        alluserdataafterlogin: [
          {
            ...state.alluserdataafterlogin[0],
            secondaryMobileVerify: action.payload,
          },
        ],
      };
    case ADD_SECONDARY_PHONE_NUMBER_SUCCESS:
      return {
        ...state,
        secondaryMobile: action.payload,
        alluserdataafterlogin: [
          {
            ...state.alluserdataafterlogin[0],
            secondaryMobile: action.payload,
          },
        ],
      };
    case GET_ALL_GENDERS_SUCCESS:
      state.allgenders = action.payload;
      break;
    case MOBILE_OTP_VERIFY_SUCCESS:
      return {
        ...state,
        primaryEmailVerify: action.payload,
        alluserdataafterlogin: [
          {
            ...state.alluserdataafterlogin[0],
            primaryEmailVerify: action.payload,
          },
        ],
      };
    default:
      break;
  }
}, initialState);

export default Auth;

import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
import SideNavBar from "../../../../SideNavBar";
// import "../AddBusiness/AddBusiness.scss";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";

import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBusinessRequest } from "../../../../Store/Business/actions";
import { ClipLoader } from "react-spinners";
import BannerProfile from "./bannerProfile";
import BusinessDetail from "./businessDetail";
import EditBusinessName from "./editbusinessName";
import EditBusinessType from "./editBusinessType";
import EditWebsite from "./editWebsite";
import CardLink from "./cardsLink";
import BusinessOwner from "./BusinessOwner";
import AppLayout from "../../../Layout/AppLayout";
const EditBusinessAddress = () => {
  const { id } = useParams();

  const dispatch = useDispatch();
  const newid = parseInt(id);
  const businesProfile = useSelector((state) => state.Business.businesProfile);
  const history = useHistory();
  const callBackHandler = () => {
    history.goBack('/business-profile');
  };
  return (
    <AppLayout
    name={"Manage Business Profile"}
    middle={
      <div className="position-relative">
      
      <div
        className="card"
        onClick={() => {
          history.push("/edit-map");
        }}>
        <div className="row">
          <div className="col-md-6 ps-2 mb-2">
            <div className="d-flex">
              <div>
                <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                  Address Name
                </p>
              </div>
              <div className="default-setting ms-2">
                <p className="">Default</p>
              </div>
            </div>
            {businesProfile?.businessAddress?.addressName ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.addressName}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
          </div>
          <div className="col-md-6 text-end m-auto">
            <img src="/images/Clip path group (2).svg" alt="" />{" "}
          </div>
         
            <hr className="background_color_line" />
         

          <div className="col-md-6 ps-2 my-2">
            <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
              Flat & Building
            </p>
            {businesProfile?.businessAddress?.flat ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.flat}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
          </div>
         
            <hr className="background_color_line" />
         
          <div className="col-md-6 ps-2 my-2 ">
            <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
              Address Line 1
            </p>
            {businesProfile?.businessAddress?.streetAddress ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.streetAddress}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
          </div>
         
            <hr className="background_color_line" />
        
          <div className="col-md-6 ps-2 mt-2">
            <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
              Postal Code
            </p>
            {businesProfile?.businessAddress?.postalCode ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.postalCode}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
          </div>
        </div>
      </div>
      <div className="card mt-3"  >
        <div className="row">
          <div className="col-md-6 ps-2 ">
            <div className="d-flex">
              <div>
                <p className="mb-0 ms-2 sub_text_color_account_alltext_show">
                  Address Name
                </p>
              </div>

            </div>
            {businesProfile?.businessAddress?.addressName ? (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize">
                {businesProfile?.businessAddress?.addressName}
              </p>
            ) : (
              <p className="mb-0 ms-2 mt-2 sub_text_color_account_alltext_show_actual text-capitalize text-black-50">
                {"N/A"}
              </p>
            )}
          </div>
          <div className="col-md-6 text-end m-auto">
            <ExpandMoreIcon
              sx={{
                fontSize: 22,
                color: "#bbbbbb",
                // backgroundColor: "#EBEBEB",
                // height: "45px",
                // width: "45px",
                // borderRadius: "8px",
              }}
            />            </div>
        </div>
      </div>
    </div>
    }
     
    />
  );
};

export default EditBusinessAddress;

import React, { useEffect, useState } from "react";

import Button from "@mui/material/Button";
import Role from "./AddRole";
import { Formik, Form } from "formik";
import Services from "./AddServices";
import Header from "../Header";
import { ClipLoader } from "react-spinners";
import { userList } from "../../../utils/constant";
import { formateData, userInitialValue, validationSchema } from "./helper";
import {
  addUserRequest,
  fetchProductList,
  fetchRoleRequest,
  fetchProvidersListSuccess,
  updateUserRequest,
  fetchProvidersList,
} from "../../../Store/User/actions";
import AddUserInfo from "./addUserInfo";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useHistory } from "react-router-dom";
import { customSnackBar, error, isBusinessRolePermissionAllowed } from "../../../utils";
import { MdAdd } from "react-icons/md";
import BusinessRole from "./AddBusinessRole";
import { selectRole, selectSwitch } from "./store/action";
import AppLayout from "../../Layout/AppLayout";

const AddUser = () => {
  const flag = true;
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const action =
    location &&
    location.state &&
    location.state.data &&
    location.state.data.action;
  const editData =
    location &&
    location.state &&
    location.state.data &&
    location.state.data.data;
  // const businessId =
  //   location &&
  //   location.state &&
  //   location.state.data &&
  //   location.state.data.data;

  const [showPermissions, setShowPermissions] = useState(false);
  const [showPermissionsProudct, setShowPermissionsProduct] = useState(false);
  const [isChecked, setIsChecked] = useState([]);
  const [showNewRole, setShowNewRole] = useState(false);
  const [showBusinessRole, setShowBusinessRole] = useState(false);
  const [showServices, setShowService] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState("");
  const [selectedOption, setSelectedOption] = useState(null); // Track the selected service ID
  const [loading, setLoading] = useState(false);
  const [team, setTeam] = useState("");
  const [businessData, setBusiness] = useState("");
  const [createdRoles, setCreatedRoles] = useState([]);
  const [userRole, setUserRole] = useState("");
  const [userRoleID, setUserRoleID] = useState([]);
  const [perData, setPerData] = useState("");
  const [data, setData] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedIds, setSelectedIds] = useState([]);
  // State to store selected options
  const [selectedObject, setSelectedObject] = useState([]);
  // const [selectedSwitch, setSelectedSwitch] = useState(null);
  const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [roles, setRole] = useState("");
  const [roleType, setRoleType] = useState("");
  const [isCheckeds, setIsCheckeds] = useState(action === "edit" ?true:false);
  const [loader, setLoader] = useState(false);

  const service = useSelector((state) => state.User?.productList);
  const role = useSelector((state) => state.User?.roles);
  const userId = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const selectedRolese = useSelector((state) => state.roleReducer.selectedRole);
  const selectedSwitch = useSelector(
    (state) => state.roleReducer.selectedSwitch
  );
  const businessId = businessData.id;
  const [viewPermssion, setViewPermissions] = useState(null);

  const permissionsToCheck = [
    "canViewUserPermissions",
    "canUpdateUserRoles ",
    "canAddNewRole",
    "canUpdateUserPermissions",
  ];
  let permissions = useSelector((state) => state?.Auth?.permissionsModule);
  useEffect(() => {
    const fetchPermissions = async () => {
      const fetchedPermissions = {};
      for (const permission of permissionsToCheck) {
        const result = await isBusinessRolePermissionAllowed(
          permissions,
          "Business Roles & Permissions",
          permission
        );
        fetchedPermissions[permission] = result;
      }
      setViewPermissions(fetchedPermissions);
    };

    if (permissions) {
      fetchPermissions();
    } else {
    }
  }, [permissions]);
  useEffect(() => {
    // Retrieve the data from local storage when the component mounts
    const business = localStorage.getItem("businessRecord");
    const userID = localStorage.getItem("userRoleID");
    if (business) {
      setBusiness(JSON.parse(business));
      setUserRole(JSON.parse(userID));
      // setUserRole(userRoleID.map((role) => role.id));
    }
  }, []);
  useEffect(() => {
    if (businessId) {

      dispatch(fetchRoleRequest(businessId));
      dispatch(fetchProductList(businessId));
      dispatch(fetchProvidersList(businessId));
    }
  }, [businessId]);

  const addUser = (values, handles) => {
    let data = formateData(values, businessId, selectedRolese);
    data = { ...data, providersList: [...selectedIds] };
    if (selectedRolese.length === 0) {
      customSnackBar("Please Select Atleast One Role");
    } else {
      dispatch(
        addUserRequest({
          data,
          setLoading,
          businessId,
        })
      );
    }
  };

  const handleRoleCreated = (role) => {
    setCreatedRoles((prevRoles) => [...prevRoles, role]);
  };

  const updateUser = (values, handle) => {
    
    let id = editData.id;
    let data = formateData(values, businessId, selectedRolese, action);
    data = { ...data, providersList: [...selectedIds] };
    if (selectedRolese.length === 0) {
      customSnackBar("Please Select Atleast One Role");
    } else {
    dispatch(updateUserRequest({ data, setLoading, id, businessId }));
    }
  };
  const submitForm = (values, handles, result) => {
    if (action === "edit") {
      updateUser(values, handles, result);
    } else {
      addUser(values, handles);
    }
  };

  const handleAddNewRole = () => {
    setShowNewRole(true);
    // setSelectedOption([]);
    setRole("");
    setSelectedPermissions([]);
    localStorage.setItem("action", "");

  
  };
  const handleAddNewBusinessRole = () => {
    setShowBusinessRole(true);
    setRole("");
    setSelectedPermissions([]);
    localStorage.setItem("action", "");
  };
  const handleCloseBusinessRole = () => {
    setShowBusinessRole(false);
  };
  const handleCloseNewRole = () => {
    setShowNewRole(false);
  };

  const handleCloseSevices = () => {
    setShowService(false);
  };
//   useEffect(() => {
//     if (
//       location &&
//       location.state &&
//       location.state.data &&
//       location.state.data.data
//     ) {
//       const result = location.state.data.data;
// console.log(result,"djfskjfkdsjfk")
//       const roles = [];
//       // Processing BusinessRoles
//       if (result.BusinessRoles && result.BusinessRoles.length > 0) {
//         result.BusinessRoles.forEach((roleObj) => {
//           if (
//             roleObj &&
//             roleObj.allowPermission &&
//             roleObj.allowPermission.length > 0
//           ) {
//             roleObj.allowPermission.forEach((permission) => {
//               const module = permission.modules && permission.modules[0];
//               if (module && (module.business || module.business === null)) {
//                 roles.push({
//                   productId: module.business,
//                   roleId: roleObj.id,
//                 });
//               }
//             });
//           }
//         });
//       }

//       // Processing ServiceRoles
//       if (result.ServiceRoles && result.ServiceRoles.length > 0) {
//         result.ServiceRoles.forEach((serviceRoleObj) => {
//           if (
//             serviceRoleObj &&
//             serviceRoleObj.id &&
//             serviceRoleObj.service &&
//             serviceRoleObj.service.id
//           ) {
//             roles.push({
//               productId: serviceRoleObj.service.id,
//               roleId: serviceRoleObj.id,
//             });
//           }
//         });
//       }

//       // Update selected roles and dispatch actions
//       setSelectedRole(roles);
//       const rolesArr = roles.map((obj) => obj.roleId);
//       const switchArr = roles.map((obj) => obj.productId);
//       dispatch(selectRole(rolesArr));
//       dispatch(selectSwitch(switchArr));
//     }

//     return () => {
//       dispatch(selectRole([]));
//       dispatch(selectSwitch([]));
//     };
//   }, [location.state]);
useEffect(() => {
  if (
    location &&
    location.state &&
    location.state.data &&
    location.state.data.data
  ) {
    const result = location.state.data.data;

    const roles = [];
    const roleIdsSet = new Set(); // To track unique role IDs
    let roleType = "Product"; // Default to Product
    let hasBusinessRole = false;

    // Processing BusinessRoles
    if (result.BusinessRoles && result.BusinessRoles.length > 0) {
      hasBusinessRole = true; // Mark that we have BusinessRoles
      result.BusinessRoles.forEach((roleObj) => {
        if (
          roleObj &&
          roleObj.allowPermission &&
          roleObj.allowPermission.length > 0
        ) {
          roleObj.allowPermission.forEach((permission) => {
            const module = permission.modules && permission.modules[0];
            if (
              module &&
              (module.business || module.business === null) &&
              !roleIdsSet.has(roleObj.id) // Check for duplicates
            ) {
              roles.push({
                productId: module.business,
                roleId: roleObj.id,
                roleType: "Business",
              });
              roleIdsSet.add(roleObj.id); // Add to the Set
            }
          });
        }
      });
    }

    // Processing ServiceRoles
    if (result.ServiceRoles && result.ServiceRoles.length > 0) {
      result.ServiceRoles.forEach((serviceRoleObj) => {
        if (
          serviceRoleObj &&
          serviceRoleObj.id &&
          serviceRoleObj.service &&
          serviceRoleObj.service.id &&
          !roleIdsSet.has(serviceRoleObj.id) // Check for duplicates
        ) {
          roles.push({
            productId: serviceRoleObj.service.id,
            roleId: serviceRoleObj.id,
            roleType: "Product",
          });
          roleIdsSet.add(serviceRoleObj.id); // Add to the Set
        }
      });
    }

    // Determine the roleType
    if (hasBusinessRole && (!result.ServiceRoles || result.ServiceRoles.length === 0)) {
      roleType = "Business";
    }

    // Set selected roles and roleType
    setSelectedRoles(roles);
    setRoleType(roleType); // Update roleType based on conditions

    // Call handleRoles for each role
    roles.forEach(({ roleId, productId, roleType }) => {
      handleRoles(roleId, productId, setSelectedRole, roleType);
    });

    // Handle selectedOption and design value
    if (result.ServiceRoles && result.ServiceRoles.length > 0) {
      const selectedService = result.ServiceRoles[0].service; // Assuming first service is to be selected
      setSelectedOption(selectedService);
      setPerData([selectedService]); // Append the selected service to perData
    }

    // Dispatch actions to update Redux store
    const rolesArr = roles.map((obj) => obj.roleId);
    const switchArr = roles.map((obj) => obj.productId);
    dispatch(selectRole(rolesArr));
    dispatch(selectSwitch(switchArr));
  }

  // Cleanup on component unmount
  return () => {
    dispatch(selectRole([]));
    dispatch(selectSwitch([]));
  };
}, [location.state]);




  const handlePermissionProductClick = () => {
    setShowPermissionsProduct(!showPermissionsProudct);
  };
  const handleChangeCheckBox = (currentIndex, data) => {
    if (selectedSwitch.includes(data.id)) {
      // localStorage.setItem("serviceId",data)
      dispatch(selectSwitch(selectedSwitch.filter((id) => id !== data.id)));
    } else {
      dispatch(selectSwitch([...selectedSwitch, data.id]));
    }
    setIsChecked((prevChecked) => {
      const newChecked = [...prevChecked];
      newChecked[currentIndex] = !newChecked[currentIndex];
      if (newChecked[currentIndex]) {
        setSelectedOption(data);
        setPerData((prevPerData) => [...prevPerData, data]); // Append the data to perData
      } else {
        setSelectedOption((prevSelectedOption) => {
          // Remove the data from selectedOption
          if (prevSelectedOption === data) {
            return [];
          }
          return prevSelectedOption;
        });
        setPerData((prevPerData) =>
          prevPerData.filter((item) => item !== data)
        ); // Remove the data from perData
      }
      return newChecked;
    });
  };
 
  const handleRoles = (roleId, productId, setSelectedRole, roleType) => {
    const isBusinessRole = roleType === "Business";
    const isProductRole = roleType === "Product";
  
    const isRoleAlreadySelected = selectedRoles.some(
      (obj) => obj.roleId === roleId
    );
  if(isBusinessRole){
    setRoleType("Business")
  }else{
    setRoleType("Product")

  }

    if (isRoleAlreadySelected) {
      const updatedSelectedRoles = selectedRoles.filter(
        (obj) => obj.roleId !== roleId
      );
  
      setSelectedRoles(updatedSelectedRoles);
      setSelectedRole(null); // Deselect the role
      dispatch(selectRole(updatedSelectedRoles.map((obj) => obj.roleId))); // Update Redux store
    } else {
      const isTypeAlreadySelected = selectedRoles.some((obj) =>
        isBusinessRole ? obj.roleType === "Business" : obj.roleType === "Product"
      );
  
      if (isTypeAlreadySelected) {
        const updatedSelectedRoles = selectedRoles.map((obj) => {
          if (
            (isBusinessRole && obj.roleType === "Business") ||
            (isProductRole && obj.roleType === "Product")
          ) {
            return { roleId, productId, roleType };
          }
          return obj;
        });
  
        setSelectedRoles(updatedSelectedRoles);
        setSelectedRole(roleId);
        dispatch(selectRole(updatedSelectedRoles.map((obj) => obj.roleId)));
      } else {
        const updatedSelectedRoles = [
          ...selectedRoles,
          { roleId, productId, roleType },
        ];
  
        setSelectedRoles(updatedSelectedRoles);
        setSelectedRole(roleId); 
        dispatch(selectRole(updatedSelectedRoles.map((obj) => obj.roleId))); 
      }
    }
  };
  const [formValues, setFormValues] = useState(
    userInitialValue(action, editData) // Initial values for the form
  );

  const handleFormChange = (values) => {
    setFormValues(values); // Update the form state in the parent component
  };

  console.log(selectedOption,"selectedOptionselectedOption")
  return (
    <>
      <AppLayout
        name="Add User"
        middle={
          <>
            {!showNewRole && !showServices && !showBusinessRole && (
              <>
                <Formik
                    initialValues={formValues}
              onSubmit={(values) => submitForm(values)}
              validationSchema={validationSchema}>
              {(options) => {
                const { handleSubmit } = options;

                // Keep form values synchronized with the parent state
              
                    return (
                      <Form onSubmit={handleSubmit}>
                        <AddUserInfo
                        loader={loader}
                        setLoader={setLoader}
                        handleFormChange={handleFormChange}
                          permission={viewPermssion}
                          selectedPermissions={selectedPermissions}
                          handleAddNewRole={handleAddNewBusinessRole}
                          roles={roles}
                          role={role}
                          setRole={setRole}
                          action={action}
                          setSelectedPermissions={setSelectedPermissions}
                          userRoleID={userRoleID}
                          setUserRoleID={setUserRoleID}
                          handleRoles={handleRoles}
                          roleType={roleType}
                          selectedRolese={selectedRolese}
                          selectedRole={selectedRole}
                          showNewRole={setShowBusinessRole}
                          setSelectedRole={setSelectedRole}
                          {...options}
                        />
                        {/* {action === "edit" ? ( */}
                        <Services
                          permission={viewPermssion}
                          service={service}
                          handleChangeCheckBox={handleChangeCheckBox}
                          isChecked={isChecked}
                          showNewRole={showNewRole}
                          setShowNewRole={setShowNewRole}
                          role={role}
                          loader={loader}
                          setLoader={setLoader}
                          roleType={roleType}
                          businessId={businessId}
                          perData={perData}
                          createdRoles={createdRoles}
                          selectedSwitch={selectedSwitch}
                          handleAddNewRole={handleAddNewRole}
                          showPermissions={showPermissionsProudct}
                          handlePermissionClick={handlePermissionProductClick}
                          selectedOption={selectedOption}
                          handleRoles={handleRoles}
                          selectedRole={selectedRole}
                          setSelectedRole={setSelectedRole}
                          setSelectedIds={setSelectedIds}
                          selectedIds={selectedIds}
                          setSelectedObject={setSelectedObject}
                          selectedObject={selectedObject}
                          editData={editData}
                          action={action}
                          roles={roles}
                          setRole={setRole}
                          setSelectedPermissions={setSelectedPermissions}
                        />

                        <div className="p-2 pt-3">
                          <div className="d-flex ">
                            <span className="span small">
                              You are Allowing the below user to access your
                              account to perform action on your behalf
                            </span>
                          </div>
                          <div className="d-flex  flex-wrap">
                            <label
                              style={{
                                fontSize: "13px",
                                display: "flex",
                                flexDirection: "row",
                              }}>
                              <input
                                className="form-check-input"
                                type="checkbox"
                                checked={isCheckeds}
                // className="lager"
                onChange={() => setIsCheckeds(!isCheckeds)}
                                style={{
                                  marginBottom: "20px",
                                  marginRight: "10px",
                                  marginTop: "3px",
                                }}
                              />
                              I agree to the{" "}
                              <span className="bold"> Term of the Service</span>
                            </label>
                          </div>
                        </div>
                        <div className="w-100 d-flex justify-content-end  ">
                          <div className="p-2">
                            <Button
                              type="submit"
                              sx={{
                                width: "150px",
                                backgroundColor: "#FFD705",
                                height: "40px",
                                borderRadius: "10px",
                                textTransform: "capitalize",
                                boxShadow: "none",

                                "&:hover": {
                                  backgroundColor: "#FFD705",
                                  color: "white",
                                  boxShadow: "none",
                                },
                              }}
                              onClick={handleSubmit} // Update the onClick handler
                              variant="contained"
                              disabled={loading || !isCheckeds}
                              >
                              {loading ? (
                                <ClipLoader color="#fffff" size={30} />
                              ) : action === "edit" ? (
                                "Update"
                              ) : (
                                "Invite"
                              )}{" "}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </>
            )}
            {showServices ? <Services onClose={handleCloseSevices} /> : null}
            {showNewRole ? (
              <Role

                businessData={businessId}
                service={service}
                action={action}
                selectedOptions={selectedOption}
                onClose={handleCloseNewRole}
                onRoleCreated={handleRoleCreated}
                selectedRole={selectedRole}
                setSelectedPermissions={setSelectedPermissions}
                setRole={setRole}
                role={roles}
                showNewRole={setShowBusinessRole}
                // selectedRole={selectedRole}
                selectedPermissions={selectedPermissions}
   loader={loader}
              setLoader={setLoader}
                setSelectedRole={setSelectedRole}
              />
            ) : null}
            {showBusinessRole ? (
              <BusinessRole
              loader={loader}
              setLoader={setLoader}
                businessData={businessId}
                service={service}
                action={action}
                setRole={setRole}
                setSelectedPermissions={setSelectedPermissions}
                selectedPermissions={selectedPermissions}
                role={roles}
                selectedOptions={selectedOption}
                onClose={handleCloseBusinessRole}
                onRoleCreated={handleRoleCreated}
                selectedRole={selectedRole}
                setSelectedRole={setSelectedRole}
              />
            ) : null}
          </>
        }
      />
    </>
  );
};

export default AddUser;

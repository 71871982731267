import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import { useField } from "formik";
import { useDropzone } from "react-dropzone";
import { FaFileUpload } from "react-icons/fa";
import { CgAdd } from "react-icons/cg";

const Dropzone = ({
  actionState,
  upload,
  setUpload,
  icon,
  name,
  setFieldValue,
  touched,
  errors,
  validationSchema,
}) => {
  const [files, setFiles] = useState([]);
  const buttonStyle = {
    border: "2px solid #000", // Set the border here
  };
  const [fileLargeError, setFileLargeError] = useState(false);
  useEffect(() => {
    if (upload && upload.preview) {
      setFiles([upload]);
    } else {
      if (actionState === "edit" && upload) {
        setFiles([{ name: upload, path: upload, preview: upload }]);
      } else setFiles([]);
    }
  }, [upload]);

  // const { getRootProps, getInputProps, isDragActive, open } = useDropzone({
  //   accept: "image/jpg, image/jpeg, image/png",
  //   maxSize: 1000 * 350,
  //   onDrop: (acceptedFiles) => {
  //     const file = acceptedFiles[0];
  //     setFiles([{ name: file.name, preview: URL.createObjectURL(file) }]);
  //     setFieldValue(name, file);
  //   },
  // });

  const { isDragActive, open, getRootProps, getInputProps, rejectedFiles } =
    useDropzone({
      noClick: true,
      noKeyboard: true,
      multiple: false,
      // ,accept: "image/.jpeg, image/.jpg, image/.png",
      accept: {
        "image/png": [".png"],
        "image/jpg": [".jpg"],
        "image/jpeg": [".jeg"],
        "text/html": [".html", ".htm"],
      },
      minSize: 0, // Minimum file size (0 means no minimum)
      maxSize: 500 * 500, // Maximum file size (500 KB in this example)
      onDrop: (acceptedFiles, rejectedFiles) => {
        if (rejectedFiles.length > 0) {
          setFiles([]); // Clear the files if any are rejected
          setFileLargeError(
            rejectedFiles &&
              rejectedFiles[0] &&
              rejectedFiles[0].errors &&
              rejectedFiles[0].errors[0].code
          );
        } else {
          setFileLargeError(null); // Clear any previous error messages
          // Handle accepted files as before
          setFiles(
            acceptedFiles.map((file) =>
              Object.assign(file, {
                preview: URL.createObjectURL(file),
              })
            )
          );
          if (setUpload) {
            setUpload(
              acceptedFiles && acceptedFiles.length > 0
                ? acceptedFiles[0]
                : icon
            );
          }
          if (setFieldValue) {
            setFieldValue(name, acceptedFiles[0]);
          }
        }
      },
    });
  const thumbs = files.map((file) => (
    <div key={file.name} className="rounded overflow-hidden mb-3 mt-4">
      <img
        style={{ height: "90px", width: "90px" }}
        className="img-fluid img-fit-container rounded-sm text-white"
        src={file.preview}
        alt="..."
      />
    </div>
  ));

  return (
    <>
      <div className="align-items-center">
        <div className=" rounded">
          <div {...getRootProps({ className: "" })}>
            <input {...getInputProps()} />
            <div className=" rounded  mx-0">
              <Button
                onClick={open}
                id="file-submit"
                // style={buttonStyle}
                className=" mt-1 text-black"
                sx={{
                  width: "80px",

                  minWidth: "138px",
                  height: "136px",
                  backgroundColor: "white",
                  borderRadius: "6px",
                  border: "1px solid #FFD705",
                }}
              >
                {!isDragActive && thumbs.length === 0 && (
                  <div className="p-3">
                    <img
                      src="../images/upload.svg"
                      alt="no-data"
                      style={{ height: "40px", width: "40px" }}
                    />
                    {/* <FaFileUpload size={25} className="text-black-50" /> */}
                    <div className="d-flex flex-column">
                      <span
                        style={{
                          color: "#616161",
                          fontSize: "12px",
                          lineHeight: "12px",
                        }}
                        className="mt-1  text-capitalize "
                      >
                        Upload Image{" "}
                      </span>
                      <span
                        style={{ fontSize: "10px", color: "#A4A4A4" }}
                        className=" mb-2 text-capitalize text-black-50"
                      >
                        200px By 200px
                      </span>
                      {/* <div className="upload ">
                          {" "}
                          <span className="text-capitalize ">Upload</span>
                        </div> */}
                    </div>
                  </div>
                )}
                {thumbs.length > 0 && <div>{thumbs}</div>}
              </Button>
              <div>
                {files.map((_, index) => (
                  <span
                    key={index}
                    onClick={() => {
                      setFieldValue(name, "");
                      setFiles([]);
                    }}
                  >
                    {" "}
                    <i
                      icon={["fas", "times"]}
                      className="font-size-sm"
                      style={{
                        position: "relative",
                        bottom: 60,
                        left: 130,
                        cursor: "pointer",
                      }}
                    />
                  </span>
                ))}
              </div>
              {fileLargeError && (
                <div
                  style={{
                    color: "#ff4d4d",
                    fontSize: "12px",
                    marginLeft: "15px",
                  }}
                >
                  {/* {fileLargeError} */}
                  {fileLargeError === "file-too-large"
                    ? "Image is too large"
                    : fileLargeError === "file-invalid-type"
                    ? "Invalid file type. Only jpg, jpeg and png files are allowed"
                    : "Invalid file type. Only jpg, jpeg and png files are allowed"}
                </div>
              )}{" "}
              {validationSchema ? (
                <div>
                  {errors && touched && (
                    <p
                      style={{
                        color: "#ff4d4d",
                        fontSize: "12px",
                        marginLeft: "15px",
                      }}
                    >
                      {errors}
                    </p>
                  )}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dropzone;

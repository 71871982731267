import React from "react";
// import "../../../../../Calendar/scheduler/AppointmentDetails/appointmentDetails.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { useDispatch } from "react-redux";
// import { RightAngle } from "Calendar/scheduler/AppointmentDetails/Svgs/svgs";
// import { providerImageURL } from "utils/imageUrlsExtension";
import { Avatar } from "@mui/material";
import { RightAngle } from "../../../../../Assets/svgs";

const LeftSection = ({ providerRecrord }) => {
  
  return (
    <div className="left-section">
      <div className={`image-container `}>
        <div className="d-flex align-items-center ">
          {providerRecrord?.imageUrl ? (
            <Avatar
              // alt={providerRecrord?.firstName}
              src={
                providerRecrord?.imageUrl
                // providerImageURL(providerRecrord.staffId)
                // providerImageURL.isExpert
                //   ? `${process.env.REACT_APP_IMAGE_URL}/ServiceInventory/${providerRecrord.serviceId}.webp`
                //   : providerRecrord?.serviceImage
              }
              className=" "
              style={{
                width: "100%",
                height: "120px",
                borderRadius:"8px",
                backgroundColor: "#F0F0F0",
                color: "#B5B5B5",
              }}
            />
          ) : (
            <Avatar
              alt={providerRecrord?.firstName}
              // src={
              //   // providerImageURL(providerRecrord.staffId)
              //   // providerRecrord.isExpert
              //   //   ? `${process.env.REACT_APP_IMAGE_URL}/ServiceInventory/${providerRecrord.serviceId}.webp`
              //   //   : providerRecrord?.serviceImage
              // }
              className=""
              style={{
                width: "100%",
                height: "120px",
                borderRadius:"8px",
                backgroundColor: "#F0F0F0",
                color: "#B5B5B5",
              }}
            />
           
          )}
        
        </div>
      </div>
      <div className="order-details-section-container mt-4">
        <div
          style={{
            cursor: "pointer",
          }}
          className="Order-details_order-num ">
          <label className="order-num_heading pb-1">Business Name</label>
          <div
            onClick={() => {
              // if (bookingData?.quantity > 1) {
              // dispatch(saleOrderDetails(bookingData.plexaarSalesOrderId));
              // history.push("/scheduler/order_details");
              // }
            }}
            className="d-flex justify-content-between align-items-baseline">
            <span className="order-num_number">
              {providerRecrord?.name} 
            </span>
            {/* <RightAngle /> */}
          </div>
        </div>

        <hr className="left-section-container-lines" />
        {/* {bookingData?.quantity>1&&( */}
        <div className="Order-details_quantity">
          <label className="quantity_heading pb-1">Business ID</label>
          <span className="quantity_number">{providerRecrord?.id}</span>
          {/* <RightAngle /> */}

        </div>
        {/* )} */}
      </div>

      {/* <div className="all-appointments-section mt-4">
        <label className="section-heading">All Appointment</label>
        <div className="all-appointments-section-container ">
          {data.Dates.map((date, index) => (
            <div className="">
              <div className="d-flex justify-content-between align-items-baseline">
                <h6 className="all-appointments_dates">{date}</h6>
                <RightAngle />
              </div>
              {index < data.Dates.length - 1 ? (
                <hr className="left-section-container-lines" />
              ) : (
                ""
              )}
            </div>
          ))}

          {/* <div className="Order-details_quantity">
            <label className="quantity_heading">Quantity</label>
            <span className="quantity_number">{data.Quantity}</span>
          </div> */}
      {/* </div>
      </div> */}
    </div>
  );
};

export default LeftSection;

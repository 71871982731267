import React, { useState } from "react";
import BusinessMap from "../../Business/BusinessMap";
import SideNavBar from "../../../../SideNavBar";
import { ClipLoader } from "react-spinners";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";

import BusinessAddressForm from "./BusinessAddressEditForm";
import AppLayout from "../../../Layout/AppLayout";

const BusineeAddressMap = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [address, setAddress] = useState("");
  const [showNext, setNextStep] = useState(false);
  const handleNext = () => {
    setNextStep(true);
  };
  const handleNextClose = () => {
    setNextStep(false);
  };
  console.log(showNext, "showNextshowNext");

  return (
    <AppLayout
      name={"Business Address"}
      middle={
        <div className="position-relative">
          {showNext && (
            <BusinessAddressForm handleNextClose={handleNextClose} />
          )}
          {showNext ? null : (
            <BusinessMap
              handleNext={handleNext}
              loading={loading}
              setAddress={setAddress}
              address={address}
            />
          )}
        </div>
      }
    />
  );
};

export default BusineeAddressMap;

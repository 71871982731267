import React, { useState, useEffect } from "react";
import InputTextField from "../TextField";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import {
  addRoleRequest,
  editRoleRequest,
  fetchPermissionRequest,
  fetchRoleRequest,
} from "../../../Store/User/actions";
import { useHistory } from "react-router-dom";
import arrowUp from "../../../../src/Assets/Images/uparrows.svg";
import arrowDown from "../../../../src/Assets/Images/arrorbottommm.png";
import { customSnackBar } from "../../../utils";
import instance from "../../../utils/utils";
import { Spin } from "antd";

const BusinessRole = ({
  onClose,
  selectedOptions,
  action,
  businessData,
  onRoleCreated,
  selectedRole,
  role,
  setRole,
  selectedPermissions,
  setSelectedPermissions,
  loader,
  setLoader
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const permission = useSelector((state) => state.User?.permissionList);
  const actions = localStorage.getItem("action");
  const data = localStorage.getItem("RoleInfo")
    ? JSON.parse(localStorage.getItem("RoleInfo"))
    : null;
  const [showAllPermissions, setShowAllPermissions] = useState(false);
  const handleShowMoreLess = () => {
    setShowAllPermissions(!showAllPermissions);
  };
 
  const validateName = (name) => {
   

    if (!role) {
      return "Role Tile is required.";
    } else if (role.length < 3 || role.length > 15) {
      return "Role should be between 3 and 15 characters long.";
    } else {
      return ""; // No validation error
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateName(role);
    if (validationError) {
      setError(validationError);
      return; // Stop submission if there's a validation error
    }
  
    setError("");
    // setLoading(true);
    const permissionObjectsByModule = {};
    selectedPermissions.forEach((permission) => {
      const { permissionKey, module_id } = permission;
      if (!permissionObjectsByModule[module_id]) {
        permissionObjectsByModule[module_id] = {};
      }
      permissionObjectsByModule[module_id][permissionKey] = true;
    });

    // Convert the object into an array
    const selectedPermissionObjects = Object.keys(
      permissionObjectsByModule
    ).map((module_id) => ({
      module: parseInt(module_id),
      permissions: permissionObjectsByModule[module_id],
    }));
    const idArray = selectedPermissions.map((permission) => permission.id);

    const data = {
      name: role,
      roleType: "Backend",
      service: null,
      business: action === "edit" ? businessData : businessData,
      permissions: idArray,
    };
    if (actions === "edit") {
      if(idArray.length===0){
        customSnackBar("Please Select Atleast one Permssion ")
      }else{
     await dispatch(
        editRoleRequest({
          data,
          id: selectedRole,
          setLoading,
          onClose,
          onRoleCreated,
        })
      );
      const response = await instance.get(`/business_svc/pv/role/${selectedRole}`);
console.log(response,"responseresponse")
      dispatch(fetchRoleRequest(businessData));

    }
    } else {
      if(idArray.length===0){
        customSnackBar("Please Select Atleast one Permssion ")
      }else{
      dispatch(
        addRoleRequest({
          data,
          setLoading,
          onClose,
          onRoleCreated,
        })
      );
    }
    }
  
  };
  useEffect(() => {
    dispatch(fetchPermissionRequest({businessId:businessData,setLoader}));

  }, [businessData.id]);
  const handlePermissionChange = (id, permissionKey, checked, module_id) => {
    setSelectedPermissions((prevSelectedPermissions) => {
      if (checked) {
        // Add new permission
        const isAlreadySelected = prevSelectedPermissions.some(
          (perm) =>
            perm.permissionKey === permissionKey && perm.module_id === module_id
        );
        if (!isAlreadySelected) {

          return [
            ...prevSelectedPermissions,
            { permissionKey, module_id, id },
          ];
        }
        return prevSelectedPermissions; // No duplicate additions
      } else {
        // Remove permission
        return prevSelectedPermissions.filter(
          (perm) =>
            !(perm.permissionKey === permissionKey && perm.module_id === module_id)
        );
      }
    });
  };
  
  const [expandedPermissions, setExpandedPermissions] = useState([]);
  const toggleChildrenVisibility = (permissionIndex) => {
    setExpandedPermissions((prev) =>
      prev.includes(permissionIndex)
        ? prev.filter((i) => i !== permissionIndex)
        : [...prev, permissionIndex]
    );
  };

  const handleNameChange = (event) => {
    const value = event.target.value;
    setRole(value);
    setError(validateName(value));
  };
  return (
    <div className="position-relative ">
      <div
        style={{
          position: "absolute",
          top: "7px",
          left: "-35px",
        }}
        onClick={onClose}
        role="button">
        <i id="icon_left_color" className="fas fa-chevron-left"></i>
      </div>{" "}
      <div className="">
        <form onSubmit={handleSubmit}>
          <div className="">
            <InputTextField
              id="role"
              label="Add Role"
              name="role"
              variant="filled"
              value={role}
              onChange={handleNameChange}
              autoFocus
              sx={{
                width: "100%",
              }}
            />
            {error && <span className="text-danger f-12">{error}</span>}
          </div>

          <div className="d-flex p-2  mt-2">
            <span
              style={{ fontWeight: 400, fontSize: "14px" }}
              className=" sub_text_color_account_alltext1">
              Please allow user following premission
            </span>
          </div>

          <>
          {loader?(
            <div className="center-loader">
<Spin/>
            </div>
          ):(
            <div className="accordion" id="permissionsAccordion">
              {permission &&
                permission.map((data, index) => {
                  const processPermissions = (permissions, level = 0) => {
                    return permissions.map((permissionSet, permissionIndex) => (
                      <div key={permissionIndex}>
                        <label
                          style={{
                            fontSize: "12px",
                            display: "flex",
                            fontWeight: 400,
                            flexDirection: "row",
                            marginTop: "10px",
                            color: "#7B7B7B",
                          }}>
                          <div
                            className="custom-checkbox-wrapper"
                            onClick={() =>
                              handlePermissionChange(
                                permissionSet?.id,
                                permissionSet.identifier,
                                !selectedPermissions.some(
                                  (permission) =>
                                    permission.permissionKey ===
                                      permissionSet.identifier &&
                                    permission.module_id === data.id
                                ),
                                data.id
                              )
                            }
                            style={{
                              marginBottom: "10px",
                              marginRight: "10px",
                              marginTop: "2px",
                              cursor: "pointer",
                            }}>
                            <div
                              className={`custom-checkbox ${
                                selectedPermissions.some(
                                  (permission) =>
                                    permission.permissionKey ===
                                      permissionSet.identifier &&
                                    permission.module_id === data.id
                                )
                                  ? "checked"
                                  : ""
                              }`}
                              style={{
                                width: "15px",
                                height: "15px",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "4px",
                                backgroundColor: selectedPermissions.some(
                                  (permission) =>
                                    permission.permissionKey ===
                                      permissionSet.identifier &&
                                    permission.module_id === data.id
                                )
                                  ? "#FFD705"
                                  : "white",
                                border: selectedPermissions.some(
                                  (permission) =>
                                    permission.permissionKey ===
                                      permissionSet.identifier &&
                                    permission.module_id === data.id
                                )
                                  ? "none"
                                  : "1px solid #ccc",
                              }}>
                              {selectedPermissions.some(
                                (permission) =>
                                  permission.permissionKey ===
                                    permissionSet.identifier &&
                                  permission.module_id === data.id
                              ) && <span style={{ color: "white" }}>✔</span>}
                            </div>
                          </div>

                          {/* Show title for main and immediate children, identifier for sub-children if no further children */}
                          {permissionSet?.children === null ||
                          permissionSet?.children?.length === 0
                            ? permissionSet.identifier
                            : permissionSet.title}

                          {/* Show arrow for children if has_child is true */}
                          {permissionSet.has_child && (
                            <div
                              style={{
                                marginLeft: "10px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                toggleChildrenVisibility(permissionIndex)
                              }>
                              <img src={arrowDown} alt="Toggle Children" />
                            </div>
                          )}
                        </label>

                        {/* Recursively display child permissions if has_child is true */}
                        {permissionSet.has_child &&
                          expandedPermissions.includes(permissionIndex) &&
                          permissionSet.children && (
                            <div style={{ paddingLeft: "20px" }}>
                              {processPermissions(
                                permissionSet.children,
                                level + 1
                              )}{" "}
                              {/* Increment level for deeper children */}
                            </div>
                          )}

                        {/* If no children and no further children, just show the identifier */}
                        {/* {!permissionSet.has_child && permissionSet?.children?.length === 0 && (
                          <div style={{ paddingLeft: "20px", color: "#7B7B7B" }}>
                            {permissionSet.identifier}
                          </div>
                        )} */}
                      </div>
                    ));
                  };

                  return (
                    <div
                      className="accordion-item mb-3"
                      key={index}
                      style={{ borderRadius: "8px" }}>
                      <h2 className="accordion-header" id={`heading-${index}`}>
                        <button
                          className="accordion-button collapsed bg-white"
                          style={{
                            borderRadius: "8px",
                            padding: "10px",
                            fontSize: "14px",
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                          type="button"
                          data-bs-toggle="collapse"
                          data-bs-target={`#collapse-${index}`}
                          aria-expanded="false"
                          aria-controls={`collapse-${index}`}>
                          <span className="text2 text-capitalize fw-normal text-d-flex">
                            {data.name}
                          </span>
                          <span>
                            <img src={arrowDown} alt="" />
                          </span>
                        </button>
                      </h2>

                      <div
                        id={`collapse-${index}`}
                        className="accordion-collapse collapse"
                        aria-labelledby={`heading-${index}`}
                        data-bs-parent="#permissionsAccordion">
                        <div
                          className="accordion-body"
                          style={{
                            marginBottom:
                              index !== permission.length - 1 ? "20px" : "0",
                            padding: "10px",
                          }}>
                          {/* Recursively process permissions */}
                          {processPermissions(data.module_permissions)}
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          )}
          </>

          <div className="w-100 d-flex flex-wrap justify-content-center pt-5 !m-n2">
            <Button
              style={{
                textTransform: "capitalize",
                backgroundColor: "#FFD705",
                cursor: "pointer",
                color: "#FFFFFF",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                borderRadius: "8px",
                fontWeight: 500,
                border: "none",
                boxShadow: "none",
                width: "40%",
              }}
              onClick={handleSubmit}
              variant="contained"
              disabled={loading}>
              {loading
                ? "Processing..."
                : actions === "edit"
                ? "Update"
                : "Save & Continue"}
            </Button>

            {/* <div className="p-2">
              <Button
                type="submit"
                sx={{
                  width: "150px",
                  backgroundColor: "#FFD705",
                  boxShadow: "none",
                  height: "40px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                disabled={loading}>
                {loading ? "Creating..." : "Create Role"}
              </Button>
            </div> */}
          </div>
        </form>
      </div>
    </div>
  );
};

export default BusinessRole;

import * as React from "react";
import Box from "@mui/material/Box";
import { FiSearch, FiFilter, FiX } from "react-icons/fi";
import { InputAdornment } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import Button from "@mui/material/Button";
import { useHistory } from "react-router-dom";
import InputTextField from "../TextField";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const Header = ({
  data,
  OnSearch,
  rowData,
  setRowData,
  heading,
  buttonText,
  link,
  flag,
  record,
}) => {
  const history = useHistory();
  const [isSearchExpanded, setIsSearchExpanded] = React.useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = React.useState(false);
  const handleSearchIconClick = () => {
    setIsSearchExpanded(!isSearchExpanded);
    setIsFilterExpanded(false);
  };

  const handleFilterIconClick = () => {
    setIsFilterExpanded(!isFilterExpanded);
    setIsSearchExpanded(false);
  };
  const handleCloseSearch = () => {
    setIsSearchExpanded(false);
  };

  const handleCloseFilter = () => {
    setIsFilterExpanded(false);
  };

  return (
    <div className="d-flex">
      <Box
        sx={{
          flex: 1,
          width: "100%",
          border: "1px solid #ededed",
          bgcolor: "background.paper",
        }}
      >
        <div className="d-flex flex-wrap justify-content-between ps-4 pe-4 pt-2 align-items-center">
          <div className="d-flex align-items-center">
            <IconButton
              onClick={() => {
                history.goBack();
              }}
              size="small"
              sx={{ mr: 3 }}
              className="mb-3"
            >
              <KeyboardBackspaceIcon sx={{ fontSize: 24, color: "#AAAAAA" }} />
            </IconButton>
            <h4 className="mb-3 ml-3">{heading}</h4>
          </div>
          {!flag && (
            <div className="d-flex mb-3 align-content-center flex-wrap">
              <div className="d-flex me-3 search-card">
                <div className=" pe-3">
                  {isSearchExpanded || isFilterExpanded ? (
                    <InputTextField
                      id="input-with-icon-textfield-search"
                      placeholder={
                        isSearchExpanded ? "Search Here.." : "Filter Here.."
                      }
                      onChange={(e) =>
                        OnSearch(e.target.value, data, setRowData)
                      }
                      sx={{
                        width: "200px",
                        maxWidth: "300px",
                        "& .MuiOutlinedInput-root": {
                          borderRadius: 1,
                          backgroundColor: "#fff",
                          height: "30px",
                          border: "1px solid #FFD705",
                          transition:
                            "border-color, background-color, box-shadow",
                          "&:hover": {
                            backgroundColor: "transparent",
                            border: "1px solid #FFD705",
                          },
                        },
                      }}
                      startAdornment={
                        <InputAdornment position="start">
                          <FiSearch
                            style={{ color: "#b2b2b2", fontSize: "20px" }}
                          />
                        </InputAdornment>
                      }
                      endAdornment={
                        <IconButton
                          onClick={
                            isSearchExpanded
                              ? handleCloseSearch
                              : handleCloseFilter
                          }
                          sx={{
                            backgroundColor: "transparent",
                            padding: "2px",
                            borderRadius: "50%",
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                          }}
                        >
                          <FiX style={{ color: "#b2b2b2", fontSize: "14px" }} />
                        </IconButton>
                      }
                    />
                  ) : null}
                </div>
                <IconButton
                  onClick={handleSearchIconClick}
                  sx={{
                    // backgroundColor: "#ffffff",
                    // height: "30px",
                    padding: "0px",
                    borderRadius: "4px",
                    marginRight: "20px",

                    // marginRight: "10px",
                    display:
                      isFilterExpanded || isSearchExpanded ? "none" : "block",
                  }}
                >
                  <FiSearch
                    style={{
                      color: "#b2b2b2",
                      fontSize: "16px",
                    }}
                  />
                  &nbsp;
                  <span className="search-bar-text">Search</span>
                </IconButton>
                <IconButton
                  onClick={handleFilterIconClick}
                  sx={{
                    // backgroundColor: "#ffffff",
                    // height: "30px",
                    padding: "0px",
                    marginRight: "20px",
                    borderRadius: "4px",
                    display:
                      isFilterExpanded || isSearchExpanded ? "none" : "block",
                  }}
                >
                  <img
                    src="../images/Vector.svg"
                    alt="no-data"
                    style={{ height: 15, width: 12 }}
                  />
                  &nbsp;
                  {/* <FiFilter style={{ color: "#b2b2b2", fontSize: "20px" }} /> */}
                  <span className="search-bar-text">Filter</span>
                </IconButton>
              </div>
              <Button
                sx={{
                  backgroundColor: "#FFD705",
                  height: "30px",
                  borderRadius: "4px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                onClick={() => {
                  const data = {
                    data: record,
                    actionState: "new",
                  };
                  // history.push(`/users?${queryParams.toString()}`);

                  history.push(link, { data });
                }}
              >
                + &nbsp;{buttonText}
              </Button>
            </div>
          )}
        </div>
      </Box>
    </div>
  );
};

export default Header;

import React, { useCallback, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import ControlPointIcon from "@mui/icons-material/ControlPoint";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { FaRegEdit } from "react-icons/fa";
import AddBoxIcon from "@mui/icons-material/AddBox";
import SyncOutlinedIcon from "@mui/icons-material/SyncOutlined";
import { BookmarkAdd } from "@mui/icons-material";
import { customSnackBar } from "../../../utils";
const Actions = (props) => {
  const history = useHistory();
  const { index, row, actionLoading, deleteBusiness, action } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClick = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl]
  );
  const handleEditClick = (row) => {
    const data = {
      data: row,
      action: action,
    };
    history.push(`/add-new-business`, { data })
    
    // history.push(`/edit-business-profile/${row.id}`, { data });
  };

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);

  return (
    <>
      <Button
        disabled={actionLoading}
        id={"action-button-" + index}
        size={"small"}
        onClick={handleClick}
        className="d-30 ps-5 btn-pill p-0 btn-icon"
      >
        <BsThreeDotsVertical
          style={{ color: "#9a9999", fontSize: 16 }}
        />
      </Button>
      {/* <div className="dropdown" style={{ positition: "relative" }}>
        <button
          className="btn bt-info p-0 m-0"
          id="dropdownMenuButton"
          type="button"
          data-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          style={{ position: "relative", zIndex: "0" }}
        >
          <MoreVertIcon
            className="float"
            sx={{ fontSize: "18px", zIndex: "99" }}
          />
        </button>
        <div
          className="dropdown-menu px-2 mt-2"
          aria-labelledby="dropdownMenuButton"
          style={{ position: "relative", zIndex: "4" }}
        >
          <div
            className="py-1"
            onClick={() =>
              history.push(`/add-business-service/${row?.serviceId}`)
            }
          >
            <SyncOutlinedIcon
              className="text-primary"
              sx={{ fontSize: "18px" }}
            />
            <span className="text-secondary ps-3" style={{ fontSize: "12px" }}>
              Assign Business
            </span>
          </div>
          {!row?.hasChild && (
            <div
              className="py-1"
              onClick={() =>
                history.push(`/create-child-service/${row?.serviceId}`)
              }
            >
              <AddBoxIcon className="text-success" sx={{ fontSize: "18px" }} />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Add Child
              </span>
            </div>
          )}
          <div
            className="py-1"
            onClick={() => history.push(`/service-detail/${row?.serviceId}`)}
          >
            <FaRegEdit className="text-warning" sx={{ fontSize: "18px" }} />
            <span className="text-secondary ps-3" style={{ fontSize: "12px" }}>
              Edit Service
            </span>
          </div>
          <div
            className="py-1"
            onClick={() => {
              // deleteService(row?.serviceId)
            }}
          >
            <DeleteForeverIcon
              className="text-danger"
              sx={{ fontSize: "18px" }}
            />
            <span className="text-secondary ps-3" style={{ fontSize: "12px" }}>
              Delete Service
            </span>
          </div>
        </div>
      </div> */}

      <Menu
        id={"action-menu-" + index}
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {!row.verified && (
          <>
            {/* <MenuItem
              id={"email-verification-" + index}
              className="pr-5 px-3 text-success"
              // onClose={handleClose}
              onClick={() => {
                handleClose();
                handleEditClick(row);
              }}
            >
              <SyncOutlinedIcon
                className="text-primary"
                sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Switch Business
              </span>

             
            </MenuItem> */}

            {/* <MenuItem
              id={"email-verification-" + index}
              className="pr-5 px-3 text-success"
              onClick={() => {
                handleClose();
                history.push("/on-board", row)
              }}
            >
              <BookmarkAdd
                className="text-success"
                sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                OnBoard
              </span>

              

            
            </MenuItem> */}
            {/* <MenuItem
              id={"email-verification-" + index}
              className="pr-5 px-3"
              // onClose={handleClose}
              onClick={() => {
                handleClose();
                handleEditClick(row);
              }}
            >
              <FaRegEdit className="text-primary" sx={{ fontSize: "18px" }} />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Edit
              </span>

              
            </MenuItem> */}
            <MenuItem
              id={"delete-business-" + index}
              className="pr-5 px-3 text-danger"
              onClose={handleClose}
              onClick={() => {
                customSnackBar("Working on it")
                // deleteBusiness(row.id);
                // handleClose();
              }}
            >
              <DeleteForeverIcon
                className="text-danger"
                sx={{ fontSize: "18px" }}
              />
              <span
                className="text-secondary ps-3"
                style={{ fontSize: "12px" }}
              >
                Delete Business
              </span>
              {/* <div className="">
            <GrPowerReset
              style={{ color: "#b2b2b2", fontSize: 20, marginLeft: "10px" }}
            />
          </div> */}
            </MenuItem>
          </>
        )}
      </Menu>
    </>
  );
};
export default Actions;

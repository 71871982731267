import React, { useEffect, useState } from "react";
import { Avatar, Divider, Switch } from "@mui/material";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Permissions from "./Permissions";
import { selectRole } from "./store/action";
import { DataNotFound } from "../../../utils";

const Services = ({
  service,
  handleChangeCheckBox,
  isChecked,
  showNewRole,
  handleAddNewRole,
  showPermissions,
  handlePermissionClick,
  selectedOption,
  handleChanges,
  role,
  roles,
  createdRoles,
  businessId,
  selectedRole,
  setSelectedRole,
  handleRoles,
  selectedSwitch,
  setSelectedIds,
  selectedIds,
  setSelectedObject,
  selectedObject,
  editData,
  action,
  setSelectedPermissions,
  setRole,
  setShowNewRole,
  roleType,
  loader,
  permission 
}) => {
  const dispatch = useDispatch();
  const providers = useSelector((state) => state?.User?.providers);

  // State for providers data
  const [providerData, setProviderData] = useState([]);

  // State for service data
  const [newdata, setNewData] = useState();

  // Update providerData dynamically when providers change
  useEffect(() => {
    if (providers && providers[0]?.length > 0) {
      const mappedProviders = providers[0].map((item) => ({
        label: `${item.firstName} ${item.lastName}`,
        value: item.providerId,
      }));
      setProviderData(mappedProviders);
    } else {
      setProviderData([]);
    }
  }, [providers]);

  // Update service data when service changes
  useEffect(() => {
    setNewData(service);
  }, [service]);

  // Update selected object in case of "edit" mode
  useEffect(() => {
    if (action === "edit") {
      if (!providerData.length) {
        setSelectedObject([]);
        setSelectedIds([]);
      } else {
        const filteredIds = providerData
          .filter((option) =>
            editData?.ServiceRoles[0]?.providersList?.includes(option.value)
          )
          .map((option) => option.value); // Extract IDs directly
  
        setSelectedIds(filteredIds); // Save IDs
        setSelectedObject(
          providerData.filter((option) =>
            editData?.ServiceRoles[0]?.providersList?.includes(option.value)
          )
        ); // Save the corresponding objects
      }
    } else {
      setSelectedObject([]);
      setSelectedIds([]);
    }
  }, [action, providerData, editData]);
  

  const handleCheckboxChange = (selectedValues) => {
    const selectedIds = selectedValues.map((value) => value.value);
    setSelectedIds(selectedIds);
    setSelectedObject(selectedValues);
  };

  const handleSelectAll = () => {
    const allProviderIds = providerData?.map((provider) => provider.value);
    setSelectedIds(allProviderIds);
    setSelectedObject(providerData);
  };

  const handleSelectChange = (selectedValues) => {
    if (selectedValues.length === 0) {
      setSelectedObject([]);
      setSelectedIds([]);
    } else if (selectedValues && selectedValues.length > 0) {
      const selectAllOption = selectedValues.find(
        (option) => option.value === "selectAll"
      );
      if (selectAllOption) {
        handleSelectAll();
      } else {
        handleCheckboxChange(selectedValues);
      }
    } else {
      setSelectedIds([]);
    }
  };

  return (
    <>
      <div>
        <span className="sub_text_color_account_alltext1 ps-1">
          Select product for this user
        </span>
      </div>
      <div className="card px-3 py-2 me-2 min-h-[250px] card-cont">
        {newdata && newdata?.length > 0 ? (
          newdata.map((data, index) => (
            <div key={index}>
              <div className="d-flex pt-3 align-items-center justify-content-between">
                <div className="d-flex">
                  <Avatar
                    src={
                      data?.serviceImage
                        ? data?.serviceImage
                        : "/images/Group 29410.svg"
                    }
                    alt="not-found"
                    variant="rounded"
                    style={{ width: "40px", height: "40px", marginRight: 10 }}
                  />
                  <div className="font-size-sm pt-2 text-capitalize">
                    <span className="bolds text-black small">{data?.name}</span>
                  </div>
                </div>
                <div>
                  <Switch
                    sx={{
                      color: "#ffd705",
                      ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked":
                        { color: "#ffd705" },
                      ".css-5ryogn-MuiButtonBase-root-MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track":
                        { backgroundColor: "#ffd705" },
                    }}
                    onChange={() => handleChangeCheckBox(index, data)}
                    checked={
                      selectedSwitch &&
                      selectedSwitch?.includes(data.id)
                    }
                    color="primary"
                  />
                </div>
              </div>
              {selectedSwitch && selectedSwitch.includes(data.id) && (
                <div>
                  <Permissions
                        loader={loader}
                        permission={permission}
                  roleType={roleType}
                    role={role}
                    serviceId={data?.id}
                    productIndex={index}
                    businessId={businessId}
                    createdRoles={createdRoles}
                    showNewRole={setShowNewRole}
                    handleRoles={handleRoles}
                    setSelectedPermissions={setSelectedPermissions}
                    selectedRole={selectedRole}
                    setSelectedRole={setSelectedRole}
                    handleAddNewRole={handleAddNewRole}
                    showPermissions={showPermissions}
                    selectedSwitch={selectedSwitch}
                    handlePermissionClick={handlePermissionClick}
                    selectedOptions={selectedOption ? selectedOption : []}
                    handleChanges={handleChanges}
                    setRole={setRole}
                  />
                  {(data.name === "Calendex" || data.name === "Calendax") && (
                    <div>
                      <span className="span m-auto">Select Staff</span>
                      <Select
                        isMulti
                        name="providers"
                        options={[
                          { label: "Select All", value: "selectAll" },
                          ...providerData,
                        ]}
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={handleSelectChange}
                        value={selectedObject}
                      />
                    </div>
                  )}
                </div>
              )}
              <Divider style={{ marginTop: "10px", width: "100%" }} />
            </div>
          ))
        ) : (
          <DataNotFound />
        )}
      </div>
    </>
  );
};

export default Services;

import React, { useState } from "react";
import styles from "../style.module.css";
import uploadBtn from "../../../../Assets/svgs/NEWiMAGE.svg";
import { BusinessContentImage } from "../../../../Assets/svgs";
import instance from "../../../../utils/utils";
import { ClipLoader } from "react-spinners";
import { customSnackBar } from "../../../../utils";

const SectionService = ({
  pageId,
  sectionId,
  fetchPageSection,
  businessRecrord,
  setSectionLength,
  sectionOrder,
  userID,
}) => {
  const [cards, setCards] = useState([
    { id: 1, text: "", subText: "", uploadedImage: null },
    { id: 2, text: "", subText: "", uploadedImage: null },
  ]);
  const [formData, setFormData] = useState({
    titleText: "Text",
    subText: "Text",
  });
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const [error, setError] = useState({});
  const handleInputChanges = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setError({ ...error, [name]: false });
  };
  // Add a new card
  const handleAddProvider = () => {
    const newCard = { id: Date.now(), text: "" };
    setCards([...cards, newCard]);
  };

  // Handle input change
  const handleInputChange = (id, field, value) => {
    const updatedCards = cards.map((card) =>
      card.id === id ? { ...card, [field]: value } : card
    );
    setCards(updatedCards);
    setError({ ...error, [id]: value === "" }); // Reset error if fixed
  };

  // Handle removing a card
  const handleRemoveCard = (id) => {
    const updatedCards = cards.filter((card) => card.id !== id);
    setCards(updatedCards);
  };

  // Handle form submission and check errors
  const handleSave = async () => {
    let newErrors = {};
    let hasErrors = false;

    let positionCounter = 1; // Counter for assigning positions

    // Validate card data
    cards.forEach((card) => {
      if (!card.text) {
        newErrors[`${card.id}-text`] = "Text is required";
        hasErrors = true;
      }
      if (!card.subText) {
        newErrors[`${card.id}-subText`] = "Subtext is required";
        hasErrors = true;
      }
      if (!card.uploadedImage) {
        newErrors[`${card.id}-uploadedImage`] = "Image upload is required";
        hasErrors = true;
      }
      // if (!card.buttonTitle) {
      //   newErrors[`${card.id}-buttonTitle`] = "Button title is required";
      //   hasErrors = true;
      // }
    });

    // Validate form data
    if (!formData.titleText || formData.titleText === "Text") {
      newErrors.titleText = "Title is required";
      hasErrors = true;
    }
    if (!formData.subText || formData.subText === "Text") {
      newErrors.subText = "Subtitle is required";
      hasErrors = true;
    }

    setError(newErrors);

    // Stop submission if there are errors
    if (hasErrors) {
      customSnackBar("Please fix the highlighted errors!");
      return;
    }

    // Prepare base object for each submission item
    const baseSubmissionData = {
      service_sku: "",
      section_id: sectionId,
      page_id: pageId?.id,
      alignment: null,
      content_Width: 2147483647,
      content_Height: 2147483647,
      content_style: null,
      font: null,
      font_color: null,
      font_family: null,
      font_style: null,
      font_size: null,
      entity_id: businessRecrord?.id,
      entity_type: "BUSINESS",
    };

    try {
      setLoading(true);

      const submissionData = [];

      // Include formData fields
      submissionData.push({
        ...baseSubmissionData,
        content: formData.titleText,
        attachment_url: null,
        content_type: 1, // 1 for text
        content_variation: 1, // 1 for title text
        position: positionCounter++,
      });

      submissionData.push({
        ...baseSubmissionData,
        content: formData.subText,
        attachment_url: null,
        content_type: 1, // 1 for text
        content_variation: 2, // 2 for subtitle text
        position: positionCounter++,
      });

      // Include card fields
      cards.forEach((card) => {
        // Text
        submissionData.push({
          ...baseSubmissionData,
          content: card.text,
          attachment_url: null,
          content_type: 1, // 1 for text
          content_variation: 1, // 1 for text
          position: positionCounter++,
        });

        // SubText
        submissionData.push({
          ...baseSubmissionData,
          content: card.subText,
          attachment_url: null,
          content_type: 1, // 1 for text
          content_variation: 2, // 2 for subtext
          position: positionCounter++,
        });

        // Uploaded Image
        submissionData.push({
          ...baseSubmissionData,
          content: null,
          attachment_url: card.uploadedImage,
          content_type: 2, // 2 for image
          content_variation: 4, // 4 for image
          position: positionCounter++,
        });

        // Button Title
        submissionData.push({
          ...baseSubmissionData,
          // content: card.buttonTitle,
          attachment_url: null,
          content_type: 1, // 1 for text
          content_variation: 2, // 2 for button text
          position: positionCounter++,
        });
      });

      // Send API request
      const response = await instance.post(
        "https://expertgateway-staging.findanexpert.net/dynamicpage_svc/content/",
        submissionData
      );

      if (response.data.code === 0) {
        fetchPageSection();
        setSectionLength(sectionOrder - 1);
        setLoading(false);
        setSubmit(true);
        customSnackBar("Form Submitted Successfully!");
      } else {
        setLoading(false);

        customSnackBar("Failed to submit form. Please try again.");
      }
    } catch (error) {
      setLoading(false);

      customSnackBar(
        "Error submitting form. Please check your network or try again."
      );
    }
  };

  const handleFileUpload = async (e, cardId) => {
    const selectedFile = e.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (!selectedFile || !allowedFileTypes.includes(selectedFile.type)) {
      setError((prev) => ({
        ...prev,
        [`${cardId}-uploadedImage`]:
          "Invalid file type. Upload jpg, jpeg, or png.",
      }));
      return;
    }

    const uploadData = new FormData();
    uploadData.append("Id", 16);
    uploadData.append("ServiceName", "serviceinventory");
    uploadData.append("Source", "Plexaar");
    uploadData.append("Files", selectedFile);
    uploadData.append("Category", "main");
    uploadData.append("FileType", "i");
    uploadData.append("CreatedBy", userID);

    setIsLoadingImage(true);

    try {
      const response = await instance.post(
        `${process.env.REACT_APP_BASE_URL}/serviceinventory_svc/pv/ServiceAttachment/UploadAttachment`,
        uploadData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      if (response.data.code === 0) {
        const updatedCards = cards.map((card) =>
          card.id === cardId
            ? { ...card, uploadedImage: response.data.paths[0] }
            : card
        );
        setCards(updatedCards);
        setError((prev) => ({
          ...prev,
          [`${cardId}-uploadedImage`]: false,
        }));
      } else {
        customSnackBar("Error uploading image.");
      }
    } catch (error) {
      customSnackBar("Error uploading image. Please try again.");
    } finally {
      setIsLoadingImage(false);
    }
  };

  return (
    <>
      <div className="row pt-4">
        <div className="col-md-4"></div>

        <div className="col-md-4">
          <div className={styles.largeBox}>
            <input
              type="text"
              name="titleText"
              placeholder="Text"
              value={formData.titleText}
              onChange={handleInputChanges}
              style={{
                backgroundColor: "transparent",
                color: "#000",
                fontSize: "20px",
                fontWeight: 500,
              }}
              className={`${styles.inputField} p-0${
                error.titleText ? styles.errorBorder : ""
              }`}
            />
            {error.titleText && (
              <span className={styles.errorText}>Title is required</span>
            )}
          </div>
          <div className={`${styles.largeBox} mt-2`}>
            <textarea
              type="text"
              name="subText"
              placeholder="Enter Title"
              value={formData.subText}
              onChange={handleInputChanges}
              style={{
                height: 40,
              }}
              className={`${styles.textareaField} mt-2 ${
                error.subText ? styles.errorBorder : ""
              }`}
            />
            {error.subText && (
              <span className={styles.errorText}>Title is required</span>
            )}{" "}
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>

      <div className={styles.containeCard}>
        <div className={styles.cardsContainer}>
          {cards.map((card, index) => (
            <div
              style={{ background: "#03A9F426" }}
              key={card.id}
              className={styles.card}>
              {cards.length === 1 ? null : (
                <button
                  className={styles.closeBtn}
                  onClick={() => handleRemoveCard(card.id)}>
                  &times;
                </button>
              )}
              {/* Upload Button */}
              <div className="pt-1 mt-2">
                <div className={`${styles.textBox} col-md-10`}>
                  <div
                    style={{
                      backgroundColor: "transparent",
                    }}
                    className={styles.smallBox}>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        padding: "0px",
                        fontWeight: "bold",
                        textAlign: "center",
                        fontSize: "16px",
                      }}
                      className={`${styles.inputField} p-0`}
                      value={card.text}
                      placeholder="Enter Text"
                      onChange={(e) =>
                        handleInputChange(card.id, "text", e.target.value)
                      }
                    />
                    {error[`${card.id}-text`] && (
                      <span className={styles.errorText}>
                        {error[`${card.id}-text`]}
                      </span>
                    )}
                  </div>

                  <div
                    style={{
                      backgroundColor: "transparent",
                    }}
                    className={`${styles.smallBox} mt-1 `}>
                    <input
                      style={{
                        backgroundColor: "transparent",
                        color: "#fff",
                        textAlign: "center",
                        fontSize: "12px",
                      }}
                      className={`${styles.inputField} p-0`}
                      value={card.subText}
                      placeholder="Enter Text"
                      onChange={(e) =>
                        handleInputChange(card.id, "subText", e.target.value)
                      }
                    />
                    {error[`${card.id}-subText`] && (
                      <span className={styles.errorText}>
                        {error[`${card.id}-subText`]}
                      </span>
                    )}
                  </div>
                </div>
                {/* Editable Button */}
                <label className={styles.uploadLabel}>
                  <input
                    type="file"
                    accept="image/*"
                    onChange={(e) => handleFileUpload(e, card.id)}
                    style={{ display: "none" }}
                  />
                  {isLoadingImage ? (
                    <ClipLoader size={20} />
                  ) : (
                    <img
                      alt="none"
                      height="120px"
                      style={{
                        borderRadius: card.uploadedImage ? "20%" : "",
                      }}
                      src={card.uploadedImage || uploadBtn}
                      className={styles.uploadedImage}
                    />
                  )}
                </label>
                {error[`${card.id}-uploadedImage`] && (
                  <span className={styles.errorText}>
                    {error[`${card.id}-uploadedImage`]}
                  </span>
                )}
                <div className="">
                  <button
                    className={`${styles.submitButton}`}
                    style={{
                      // width:"max-content",
                      fontSize: "14px",
                      padding: " 0px 40px",
                      backgroundColor: "#D9D9D933",
                      color: "white",
                      textAlign: "center",
                      borderRadius: "8px",
                      height: 40,
                      border: " solid #fff",
                    }}>
                    View Detail{" "}
                  </button>
                  {/* <input
                    type="text"
                    name="buttonTitle"
                    placeholder="Text"
                    value={card.buttonTitle}
                    onChange={(e) =>
                      handleInputChange(card.id, "buttonTitle", e.target.value)
                    }
                    // onChange={handleInputChange}
                    style={{
                      backgroundColor: "#D9D9D933",
                      color: "white",
                      fontSize: "14px",
                      maxWidth: "max-content",
                      textAlign: "center",
                      borderRadius: "8px",
                      height: 40,
                      border:" solid #fff"
                    }}
                    className={`${styles.inputField} ${
                      error.buttonTitle ? styles.errorBorder : ""
                    }`}
                  />
                  {error.buttonTitle && (
                    <span className={styles.errorText}>
                      Button title is required
                    </span>
                  )} */}
                </div>{" "}
              </div>
              {/* Editable Input Field */}
            </div>
          ))}

          {/* Add Provider Card */}
          <div className={`${styles.addCard} `} onClick={handleAddProvider}>
            {/* <div className={styles.addIcon}>+</div>
            <p className={styles.addText}>Add Provider</p> */}
            <BusinessContentImage />
          </div>
        </div>

        {/* Save Button */}
      </div>
      <div className={styles.saveContainer}>
        <button
          disabled={loading || submit} // Fixed typo here
          className={styles.submitButton}
          onClick={handleSave}>
          {loading ? (
            <ClipLoader size={10} color="#fff" />
          ) : submit ? (
            "Submitted"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </>
  );
};

export default SectionService;

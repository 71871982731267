import React, { useState, useEffect } from "react";
import Table from "./Table";
import { OnSearch } from "./helper";
import Header from "../Header";
import { departmentList } from "../../../utils/constant";
import SkeletonTable from "../SkeltonTableSimple/SkeltonTable";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteDepartmentRequest,
  fetchDepartmentRequest,
} from "../../../Store/Department/actions";
import SideBar from "../../../SideNavBar";

import NewList from "./NewList";
import '../../../Assets/components/meetspace/events.scss'
import NewListMobile from "./NewListMobile";
import AppLayout from "../../Layout/AppLayout";
import { isBusinessRolePermissionAllowed } from "../../../utils";

const MangeDeaprtment = ({ setActiveTab }) => {
  const flag = false;
  const location = useLocation();
  const dispatch = useDispatch();
  const busienssRecord = location;
  const queryParams = new URLSearchParams(location.search);
  const businessId = queryParams.get("id");
  const [rowData, setRowData] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const data = useSelector((state) => state.Department?.data);
  const loading = useSelector((state) => state.Department?.loading);
  const removeBusiness = (id) => {
    dispatch(
      deleteDepartmentRequest({
        id,
        setRowData,
        rowData,
      })
    );
  };
  //Delete busienss Confirmation Dialouge Box handler
  const handleBusinessDeleteConfirmation = (id) => {
    setDialog({
      visible: true,
      key: Math.random().toString(36).substring(7),
      ok: () => removeBusiness(id),
    });
  };
  const business = JSON.parse(localStorage.getItem("businessRecord"))
  useEffect(() => {

    if (business?.id) {

      dispatch(fetchDepartmentRequest(business?.id));
    }
  }, []);
  useEffect(() => {
    setRowData(data || []);
  }, [data]);
  const [viewPermssion, setViewPermissions] = useState(null);

  const permissionsToCheck = [
    "canViewBusinessDepartmentTeam",
    "canEditBusinessDepartments",
    "canDeleteBusinessDepartments"
   
  ];
  
  let permissions = useSelector((state) => state?.Auth?.permissionsModule);
  useEffect(() => {
    const fetchPermissions = async () => {
      const fetchedPermissions = {};
      for (const permission of permissionsToCheck) {
        const result = await isBusinessRolePermissionAllowed(
          permissions,
          "Business Roles & Permissions",
          permission
        );
        fetchedPermissions[permission] = result;
      }
      setViewPermissions(fetchedPermissions);
    };

    if (permissions) {
      fetchPermissions();
    } else {
    }
  }, [permissions]);
  return (
    <>
      <AppLayout
      link="/add-department"
      name="Mange Departments"
      isShow={true}
      allSpace={
        <>
         <div className="hide_scrollbar min-h-[250px] table-container">
          {loading ? (
            <SkeletonTable />
          ) : (
            <Table
            permission={viewPermssion}
              loading={loading}
              setRecord={setRowData}
              data={rowData}
              dialog={dialog}
              deleteDepartment={handleBusinessDeleteConfirmation}
            />
           
          )}
        </div>
        </>
      }
      />
       
    
    </>
  );
};

export default MangeDeaprtment;

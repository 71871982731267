  export default  Object.freeze([
    {
      path: "/dashboard",
      text: "Sales Dashboard",
    //   icon: DashboardIconSvg,
      active: 120,
    },
    {
      path: "/calendar",
      text: "Calendar",
    //   icon: SideBarCalendar,
      active: 1,
    },
  
  

  ]);
  export const FormCreatorSvg = ({ isActiveLink }) => {
    return (
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M9.33333 4H4.88889C4.39797 4 4 4.39797 4 4.88889V9.33333C4 9.82425 4.39797 10.2222 4.88889 10.2222H9.33333C9.82425 10.2222 10.2222 9.82425 10.2222 9.33333V4.88889C10.2222 4.39797 9.82425 4 9.33333 4Z"
          stroke={isActiveLink === 106 ? "#FFFFFF" : "#FFD705"}
          // fill={isActiveLink === 2 ? "white" : "#FFD705"}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M9.33333 13.7778H4.88889C4.39797 13.7778 4 14.1758 4 14.6667V19.1112C4 19.6021 4.39797 20.0001 4.88889 20.0001H9.33333C9.82425 20.0001 10.2222 19.6021 10.2222 19.1112V14.6667C10.2222 14.1758 9.82425 13.7778 9.33333 13.7778Z"
          stroke={isActiveLink === 106 ? "#FFFFFF" : "#FFD705"}
          // fill={isActiveLink === 2 ? "white" : "#FFD705"}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.7773 4.88867H19.9996"
          stroke={isActiveLink === 106 ? "#FFFFFF" : "#FFD705"}
          // fill={isActiveLink === 2 ? "white" : "#FFD705"}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.7773 9.3335H19.9996"
          stroke={isActiveLink === 106 ? "#FFFFFF" : "#FFD705"}
          // fill={isActiveLink === 2 ? "white" : "#FFD705"}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.7773 14.6665H19.9996"
          stroke={isActiveLink === 106 ? "#FFFFFF" : "#FFD705"}
          // fill={isActiveLink === 2 ? "white" : "#FFD705"}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.7773 19.1113H19.9996"
          stroke={isActiveLink === 106 ? "#FFFFFF" : "#FFD705"}
          // fill={isActiveLink === 2 ? "white" : "#FFD705"}
          stroke-width="1.25"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    );
  };
  
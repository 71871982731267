import calendexLogo from "../../../Assets/Images/calendax.svg";
import styles from "../AppLayout.module.css";
import { Dropdown, Tooltip } from "antd";
import { IoAddCircle, IoAddCircleOutline } from "react-icons/io5";
import { useSelector } from "react-redux";

import { CiSearch } from "react-icons/ci";
import { useEffect, useRef, useState } from "react";

import { FaWpforms } from "react-icons/fa";

import {
  customSnackBar,
  isBusinessRolePermissionAllowed,
} from "../../../utils";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";

const DynamicListHeader = ({
  name,
  data,
  handlePreferences,
  OnSearch,
  rowData,
  setRowData,
}) => {
  const location = useLocation();
  const [isBorder, setIsBorder] = useState(false);
  const borderRef = useRef(null);
  const history = useHistory();
  const date = new Date();
  const selectedBusines = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const [isTab, setIsTab] = useState("");
  const { push } = useHistory();
  const { pathname } = useLocation();

  const [isSearchVisible, setIsSearchVisible] = useState(true);

  const [viewPermssion, setViewPermissions] = useState(null);

  const permissionsToCheck = [
    "canCreateBusiness",
    "canAddBusinessDepartments",
    "canAddDepartmentTeam",
    "canAddBusinessDepartments",
    "canAddBusinessUser",
  ];

  let permissions = useSelector((state) => state?.Auth?.permissionsModule);
  useEffect(() => {
    const fetchPermissions = async () => {
      const fetchedPermissions = {};
      for (const permission of permissionsToCheck) {
        const result = await isBusinessRolePermissionAllowed(
          permissions,
          "Business Roles & Permissions",
          permission
        );
        fetchedPermissions[permission] = result;
      }
      setViewPermissions(fetchedPermissions);
    };

    if (permissions) {
      fetchPermissions();
    } else {
    }
  }, [permissions]);
  console.log("kfhjsdhkjhsf",viewPermssion)

  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!borderRef?.current?.contains(e.target)) {
        setIsBorder(false);
      }
    };
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);
  const serviceMenu = [
    {
      label: <div onClick={() => push("/create-new-service")}>Private</div>,
      key: "0",
    },
    {
      label: <div onClick={() => push("/assign-service")}>Expert</div>,
      key: "1",
    },
  ];

  const allowedRoutes = [
    "/business",
    "/business/",
    "/users",
    "/department",
    "/team",
    "/content-page",
  ];

  const handleFormAdd = () => {
    if (pathname === "/business/" || pathname === "/business") {
      if (
        viewPermssion === null ||
        viewPermssion?.canCreateBusiness ||
        viewPermssion?.canCreateBusiness === null ||
        viewPermssion?.canCreateBusiness === undefined
      ) {
        push("/");
      } else {
        customSnackBar("You havn't permission to Add Business");
      }
    } else if (pathname === "/users") {
      if (
        viewPermssion === null ||
        viewPermssion?.canAddBusinessUser ||
        viewPermssion?.canAddBusinessUser === null ||
        viewPermssion?.canAddBusinessUser === undefined
      ) {
        push(`/add-user`);
      } else {
        customSnackBar("You havn't permission to Add user");
      }
    } else if (pathname === "/department") {
      if (
        viewPermssion === null ||
        viewPermssion?.canAddBusinessDepartments ||
        viewPermssion?.canAddBusinessDepartments === null ||
        viewPermssion?.canAddBusinessDepartments === undefined
      ) {
        push(`/add-department`);
      } else {
        customSnackBar("You havn't permission to Add Department");
      }
    } else if (pathname === "/content-page") {
      push(`/content-page-creation`);
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsTab(window.innerWidth <= 991.9);
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleSearchIconClick = () => {
    setIsSearchVisible((prev) => !prev);
  };

  return (
    <>
      <div className={` ${styles.bottomHeader}`}>
        <div className="row h-100 ps-2 pe-4 d-flex align-items-center justify-content-between">
          <div className="d-flex col-6 col-sm-7 pe-0 align-items-center gap-4">
            <div className="d-none d-lg-flex align-items-center gap-4">
              <div className={styles.calendexLogo}>
                <img className="w-100" src={calendexLogo} alt="calendex logo" />
              </div>
              <span className={`${styles.pageName} text-nowrap`}>{name}</span>
            </div>

            {pathname==="/business" ||pathname==="/business/"? (
              <div className="d-flex w-100">
                <div className="position-relative w-100">
                  <input
                    className="dynamicSearchInputMain w-100"
                    // id="input-with-icon-textfield-search"
                    placeholder="Search Here.."
                    onChange={(e) => OnSearch(e.target.value, data, setRowData)}
                  />

                  <span className={"dynamicSearchMain"}>
                    <CiSearch color="#969696" size={20} />
                  </span>

                  <span className={`${styles.dynamicSearchRight}`}>
                    <img
                      src="/images/Vector (2).svg"
                      alt="search"
                      onClick={() => handleSearchIconClick()}
                      style={{ cursor: "pointer" }}
                    />
                  </span>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>

          <div className="col-5 pe-0 d-flex align-items-center justify-content-end gap-sm-3 gap-2">
            <>
              {/* <Dropdown
                menu={{
                  items: sortMenu,
                }}
                trigger={["click"]}
              > */}
              {/* <Tooltip title="Sort" placement="bottom"> */}
              <svg
                width="21"
                height="22"
                viewBox="0 0 21 22"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M14.755 9.06792L12.095 6.28125V15.7229M6.25 12.9362L8.91 15.7229V6.28125"
                  stroke="#989898"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M10.5 20.1654C15.3326 20.1654 19.25 16.0614 19.25 10.9987C19.25 5.93595 15.3326 1.83203 10.5 1.83203C5.66738 1.83203 1.75 5.93595 1.75 10.9987C1.75 16.0614 5.66738 20.1654 10.5 20.1654Z"
                  stroke="#989898"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
              {/* </Tooltip> */}
              {/* </Dropdown> */}
              {/* <Tooltip title="Filter" placement="bottom"> */}
              <svg
                width="17"
                height="20"
                viewBox="0 0 17 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.58875 1L3.275 8.24167M2.75 1H14.3C15.2625 1 16.05 1.825 16.05 2.83333V4.85C16.05 5.58333 15.6125 6.5 15.175 6.95833L11.4125 10.4417C10.8875 10.9 10.5375 11.8167 10.5375 12.55V16.4917C10.5375 17.0417 10.1875 17.775 9.75 18.05L8.525 18.875C7.3875 19.6083 5.8125 18.7833 5.8125 17.3167V12.4583C5.8125 11.8167 5.4625 10.9917 5.1125 10.5333L1.7875 6.86667C1.35 6.40833 1 5.58333 1 5.03333V2.925C1 1.825 1.7875 1 2.75 1Z"
                  stroke="#989898"
                  stroke-width="1.5"
                  stroke-miterlimit="10"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>{" "}
              {/* </Tooltip> */}
              {allowedRoutes?.includes(pathname) ? (
                <div className="d-flex align-items-center gap-lg-2 gap-sm-3">
                  {/* Mobile Version (d-lg-none) */}
                  <div className="d-lg-none">
                    <FaWpforms
                      color="#989898"
                      size={21}
                      //   onClick={handleCreateForm}
                    />
                  </div>

                  {/* Desktop Version (d-lg-flex) */}
                  {location.pathname !== "/providers" ? (
                    <div
                      className={`${styles.addBtn} d-none d-lg-flex`}
                      //   onClick={handleCreateForm}
                    >
                      <div
                        className={`${styles.addBtn}`}
                        onClick={handleFormAdd}>
                        <span>
                          <IoAddCircle color="white" size={20} />
                        </span>
                        <span className={`${styles.addBtnAdd}`}>Add</span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}

                  {/* Conditionally Render Add Button for "/all-customer", "/staff-list", and "/services-list" */}
                  {["/all-customer", "/staff-list", "/services-list"].includes(
                    pathname
                  ) && (
                    <>
                      {pathname !== "/services-list" ? (
                        <div
                          className="d-lg-none ms-2 ms-sm-0"
                          onClick={handleFormAdd}>
                          <IoAddCircleOutline color="#989898" size={24} />
                        </div>
                      ) : (
                        <div className="d-lg-none ms-2 ms-sm-0">
                          <Dropdown
                            overlayClassName="add-service-dropdown"
                            menu={{
                              items: serviceMenu,
                            }}
                            trigger={["click"]}>
                            <IoAddCircleOutline color="#989898" size={24} />
                          </Dropdown>
                        </div>
                      )}

                      <div className="d-none d-lg-block">
                        {pathname === "/business" ? (
                          <div
                            className={`${styles.addBtn}`}
                            onClick={handleFormAdd}>
                            <span>
                              <IoAddCircle color="white" size={20} />
                            </span>
                            <span className={`${styles.addBtnAdd}`}>Add</span>
                          </div>
                        ) : null}
                      </div>
                    </>
                  )}
                </div>
              ) : null}
            </>
          </div>
        </div>
      </div>
    </>
  );
};
export default DynamicListHeader;

import React, { useEffect, useState } from "react";
import { BsNewspaper } from "react-icons/bs";
import InputTextField from "../TextField";
import { MdAdd } from "react-icons/md";
import { AiOutlineUser } from "react-icons/ai";
import { TbFileSettings } from "react-icons/tb";
import { useHistory } from "react-router-dom";
import { customSnackBar, DataNotFound } from "../../../utils";
import { useLocation } from "react-router-dom";
import { EditSvg } from "../../../Assets/svgs";
import instance from "../../../utils/utils";

const UserInfo = ({
  handleChange,
  handleFormChange,
  showNewRole,
  values,
  roles,
  setRole,
  setSelectedPermissions,
  selectedPermissions,
  touched,
  errors,
  handleAddNewRole,
  role,
  action,
  userRoleID,
  setUserRoleID,
  handleRoles,
  selectedRolese,
  selectedRole,
  setSelectedRole,
  permission,
  roleType,
  loader,
  setLoader,
}) => {
  const [showPermissions, setShowPermissions] = useState(true);
  const history = useHistory();
  const callBackHandler = () => {
    history.goBack("/add-user");
  };

  const location = useLocation();
  useEffect(() => {
    // Scroll to the top of the page when the component is rendered
    window.scrollTo(0, 0);
  }, [location]);
  useEffect(() => {
    handleFormChange(values);
  }, [values]);
  const matchedRole = role.find(role => role.id === selectedRole);

const newRoleObject = matchedRole
    ? { id: matchedRole.id, name: matchedRole.name || "Unnamed Role" }
    : null;

console.log(newRoleObject,matchedRole,"ROLELELELE")
  const handleEditClick = async (row) => {
    try {
      setLoader(true);
  
      const response = await instance.get(`/business_svc/pv/role/${row}`);
  
      if (response.data.code === 0) {
        const result = response?.data?.result;
  
        // Parse permissions and modules directly
        const preselectedPermissions = [];
        const preexpandedModules = [];
  
        result?.role_modules_permissions?.forEach((module) => {
          module.module_permissions.forEach((perm) => {
            preselectedPermissions.push({
              permissionKey: perm.identifier,
              module_id: module.id,
              id: perm.id,
            });
          });
  
          if (module.module_permissions.length > 0) {
            preexpandedModules.push(module.id);
          }
        });
  
        // Update states directly
        setSelectedPermissions(preselectedPermissions);
        // setExpandedPermissions(preexpandedModules);
        setRole(result.name || "");
  
        // Store data in localStorage
        localStorage.setItem("RoleInfo", JSON.stringify(result));
        localStorage.setItem("action", "edit");
  
        // Show the edit role modal/component
        setLoader(false);
        showNewRole(true);
      } else {
        setLoader(false);
        customSnackBar("Something went wrong. Please try again.");
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching role data:", error);
      customSnackBar("An error occurred while fetching data. Please try again.");
    }
  };
 
  
  return (
    <>
      <div className="position-relative">
        <div
          style={{
            position: "absolute",
            top: "7px",
            left: "-35px",
          }}
          onClick={callBackHandler}
          role="button">
          <i id="icon_left_color" className="fas fa-chevron-left"></i>
        </div>
        <div>
          <div className="col-md-12 mb-2">
            <div className="input-group">
              <span className="input-label text-light-grey">First Name</span>
              <input
                className="input custom-border"
                id="firstName"
                label="First Name"
                name="firstName"
                placeholder="Enter First Name"
                variant="filled"
                value={values.firstName}
                touched={touched.firstName}
                error={touched.firstName && errors.firstName}
                onChange={handleChange}
              />
            </div>
          </div>

          {errors.firstName && (
            <span className="text-danger f-12">{errors.firstName}</span>
          )}

          <div className="col-md-12 mb-2">
            <div className="input-group">
              <span className="input-label text-light-grey">Last Name</span>
              <input
                className="input custom-border"
                label="Last Name"
                name="lastName"
                placeholder="Enter Last Name"
                variant="filled"
                value={values.lastName}
                touched={touched.lastName}
                error={touched.lastName && errors.lastName}
                onChange={handleChange}
              />
            </div>
          </div>
          {errors.lastName && (
            <span className="text-danger f-12">{errors.lastName}</span>
          )}

          <div className="col-md-12 mb-2  ">
            <div className="input-group">
              <span className="input-label text-light-grey">User Email</span>
              <input
                className="input custom-border"
                label="User Email"
                name="email"
                placeholder="Enter User Email"
                variant="filled"
                value={values.email}
                touched={touched.email}
                error={touched.email && errors.email}
                onChange={handleChange}
              />
            </div>
          </div>
          {errors.email && (
            <span className="text-danger f-12">{errors.email}</span>
          )}

          {/* <span className=" pt-3 text_heading_owner">Busines Roles</span> */}
          <div>
            <span className=" sub_text_color_account_alltext1 ps-1">
              Select Role for business{" "}
            </span>
          </div>

          <div className="w-100 pb-2 d-sm-flex flex-column  !m-n2 justify-content-between">
            <div className="d-flex justify-content-between pt-3 pb-0 mb-2">
              <div className="d-flex pb-0">
                <AiOutlineUser style={{ color: "#b2b2b2", fontSize: 25 }} />
                <span className="span ps-1 pt-1">Role</span>
              </div>
              {permission === null ||
              permission?.canAddNewRole  ||
              permission?.canAddNewRole === null ||
              permission?.canAddNewRole === undefined ? (
                <div className="d-flex  pb-0">
                  <MdAdd
                    style={{ color: "#FFD705", fontSize: 20 }}
                    onClick={handleAddNewRole}
                  />
                  <span
                    className="span ps-1"
                    style={{
                      color: "#FFD705",
                      fontSize: "12px",
                      fontWeight: 500,
                      cursor:loader?"progress": "pointer",
                    }}
                    onClick={handleAddNewRole}>
                    Add New Role
                  </span>
                </div>
              ) : null}
            </div>

            <div>
              <div
                className="d-flex flex-wrap px-2 align-items-center"
                // style={{ backgroundColor: "#FBFCFF" }}
              >
                {role && role.length > 0 ? (
                  role.map((option, index) => (
                    <div
                      className="d-flex flex-column flex-wrap pe-4 col-3"
                      key={option.value}>
                      <label
                        style={{
                          fontSize: "13px",
                          display: "flex",
                          flexDirection: "row",
                          // marginTop: "20px",
                        }}>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          value={option.id}
                          checked={selectedRolese.includes(option.id)}
                          onChange={() =>
                            handleRoles(
                              option.id,
                              option?.business,
                              setSelectedRole,
                              "Business"
                            )
                          }
                          // className="lager"
                          style={{
                            marginBottom: "20px",
                            marginRight: "10px",
                            marginTop: "3px",
                          }}
                        />
                        {option.name}
                      </label>
                    </div>
                  ))
                ) : (
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <p className="text-black-50">Roles not Found</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
            {selectedRole !== null &&roleType==="Business"&&
              (permission?.canViewUserPermissions  ||
              permission?.canViewUserPermissions === null ||
              permission?.canViewUserPermissions === undefined ? (
                <div className="d-flex flex-column flex-wrap px-2">
                  <div className="d-flex justify-content-between">
                    <span
                      className={`${
                        !showPermissions ? "permission2" : "permission"
                      }`}
                      onClick={() => setShowPermissions((prev) => !prev)}>
                      {`${showPermissions ? "See" : "Hide"} Permissions`}
                    </span>
                    {!showPermissions &&
                      (permission?.canUpdateUserPermissions  ||
                      permission?.canUpdateUserPermissions === null ||
                      permission?.canUpdateUserPermissions === undefined ? (
                        <div
                          onClick={() =>{
                            if(newRoleObject.name==="OwnerRole"){
                              customSnackBar("The owner role is not editable")
                            }else{
                           handleEditClick(selectedRole)
                            }}
                           }
                          className="d-flex cursor-pointer">
                          <EditSvg />
                        </div>
                      ) : null)}
                  </div>
                  <div
                    style={{
                      height: !showPermissions ? "max-content" : "0vh",
                      overflow: "auto",
                    }}
                    className="d-flex flex-column">
                    {!showPermissions &&
                      role
                        .filter((role) => role.id === selectedRole) // Filter by selected role
                        .map((selectedRole) => {
                          const modules =
                            selectedRole.role_modules_permissions || []; // Ensure modules exist

                          return modules.map((module) => {
                            const permissions = module.module_permissions || []; // Extract permissions

                            // Filter active permissions
                            const activePermissions = permissions.filter(
                              (permission) => permission.status === "active"
                            );

                            return (
                              <ul
                                className="d-flex flex-column orderlist"
                                key={module.id}>
                                <div>
                                  <TbFileSettings
                                    style={{ color: "#b2b2b2" }}
                                  />{" "}
                                  <span className="span pt-3">
                                    {module.name}
                                  </span>
                                  <div className="ps-3">
                                    {activePermissions.map((permission) => (
                                      <li
                                        className="text-capitalize"
                                        key={permission.id}>
                                        {permission.title}
                                      </li>
                                    ))}
                                  </div>
                                </div>
                              </ul>
                            );
                          });
                        })}
                  </div>
                </div>
              ) : null)}
          </div>
        </div>
      </div>
    </>
  );
};
export default UserInfo;

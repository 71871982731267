import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ClipLoader } from "react-spinners";
import {
  updateBusinessRequest,
  updateBusinessSuccess,
} from "../../../../Store/Business/actions";
import { Button } from "@mui/material";
import SideNavBar from "../../../../SideNavBar";

import { useHistory } from "react-router-dom/cjs/react-router-dom";

const EditWebsite = ({  handleWebsiteClose,setShowWebsite }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const businessData = useSelector((state) => state.Business.businesProfile);
  const userID = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const id = businessData.id;

  const [businessWebsite, setBusinessWebsite] = useState("");
  const [loading, setLoading] = useState(false);
  const [nameError, setNameError] = useState("");
  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(name)) {
      return "Name should only contain letters and spaces.";
    }
    return "";
  };
  const handleWebsiteChange = (event) => {
    const value = event.target.value;
    setBusinessWebsite(value);
    // setNameError(validateName(value));
  };

  const handleSubmit = () => {
    if (businessWebsite.trim() === "") {
        setNameError("Website URL cannot be empty.");
    } else {
      const data = {
        websiteUrl: businessWebsite,
      };
      const action = "editWebsite";

      dispatch(
        updateBusinessSuccess({ data, setLoading, id, userID, setShowWebsite, action })
      );
    }
  };
  useEffect(() => {
    setBusinessWebsite(businessData.websiteUrl);
  }, []);
  return (
    <>
    <SideNavBar>
    <div
      className="padding_left_right hide_scrollbar  mt-2 "
      style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
    >
      <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">Website</label>
        <input
          className="form-control1 input_style_fix"
          type="text"
          id="website"
          label="Website"
          name="website"
          value={businessWebsite}
          onChange={handleWebsiteChange}
          placeholder="Enter URL"
          // //size={20}
          // error={touched.website && errors.website}
          // onChange={(event) => setWebsiteurl(event.target.value)}
        />
      </div>
      {nameError && (
          <span className="text-danger f-12">
            {nameError}
          </span>
        )}
      <div className="w-100 d-flex mt-5 pt-5 flex-wrap justify-content-end ">
        <div className="pe-2">
          <Button
            sx={{
              width: "150px",
              backgroundColor: "#edf0f5",
              height: "40px",
              color: "#b6bec8",
              borderRadius: "10px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#c0c0c0",
                color: "#ffffff",
              },
            }}
            onClick={()=>{
                history.goBack()
            }}
            variant="contained"
            className=" btn btn-secondary padding_next_secondary_button"
          >
            {"Cancel"}
          </Button>
        </div>
        <div>
          <Button
            sx={{
              width: "150px",
              backgroundColor: "#FFD705",
              height: "40px",
              borderRadius: "10px",
              textTransform: "capitalize",
            }}
            className="padding_next_primary_button"
            onClick={handleSubmit}
            variant="contained"
          >
            {loading ? <ClipLoader color="#fffff" size={30} /> : "Update"}
          </Button>
        </div>
      </div>
    </div>
    </SideNavBar>
    </>
  );
};

export default EditWebsite;

import React from "react";
import { useHistory } from "react-router-dom";
import AppLayout from "./Layout/AppLayout";
import { useSelector } from "react-redux";
// import "./PageNotFound.css";

const PageNotFound = () => {
  const history = useHistory();
  const url = useSelector((state) => state.Business?.url);
  const handleBack = () => {
    // let url = localStorage.getItem("url");
    window.location.href = url; // Redirect to the desired URL
    //
    localStorage.clear();
    // Move to the desired domain URL
  };
    return (
    <AppLayout
    name="Not Found"
isShow={true}
    allSpace={
      <div className="wrapper">
    <div className="container ">
      <div className="d-flex align-items-center">
        <div>
          <img src="/images/404.png" alt="left" />
          <h1 className="px-spc-b-20">We can't find the page you are looking for.</h1>
          <span className="px-spc-b-20">This page has been relocated or removed.</span>
          <button className="btn btn-warning" onClick={handleBack}><i className="fa fa-home"></i> Go Home</button>
        </div>
        <div className="ms-5">
          <img src="/images/notfound.png" alt="right-shape" />
        </div>
      </div>
    </div>
  </div>
    }
 

    />
  );

}

export default PageNotFound;
// Sections/ImageLogoSummary.js
import React, { useEffect, useState } from "react";
import { GoogleMap, LoadScript, Marker } from "@react-google-maps/api";
import styles from "../style.module.css";
import uploadIcon from "../../../../Assets/svgs/uplaod.svg";
import instance from "../../../../utils/utils";
import { ClipLoader } from "react-spinners";
import { customSnackBar } from "../../../../utils";

const containerStyle = {
  width: "100%",
  height: "300px",
  borderRadius: "10px",
};

const center = {
  lat: -6.917464, // Default latitude (Bandung)
  lng: 107.619123, // Default longitude (Bandung)
};

const AddressSection = ({
  pageId,
  sectionId,
  fetchPageSection,
  businessRecrord,
  setSectionLength,
  sectionOrder,
  userID
}) => {
  const [selectedPosition, setSelectedPosition] = useState(center);
  const [address, setAddress] = useState("Select a location on the map");
  const [formData, setFormData] = useState({
    uploadedImage: null,
    uploadedImage2: null,
    uploadedImage3: null,
    titleText: "Text",
    subText: "Text",
    address: address,
    email: "john@hotmail.com",
    number: "Text",
  });
  const [errors, setErrors] = useState({});
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingImage2, setIsLoadingImage2] = useState(false);
  const [isLoadingImage3, setIsLoadingImage3] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);

  const GOOGLE_MAPS_API_KEY = "AIzaSyDKDVVNL5FeC6oEKMdVMascV7WDuglvu8c"; // Replace with your API key
  const handleMapClick = async (e) => {
    const lat = e.latLng.lat();
    const lng = e.latLng.lng();
    setSelectedPosition({ lat, lng });

    await fetchAddress(lat, lng);
  };
  const [currentPosition, setCurrentPosition] = useState(center);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false });
  };
  console.log(address,"currentPositioncurrentPosition")
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCurrentPosition({
            lat: latitude,
            lng: longitude,
          });
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  }, []);
  const fetchAddress = async (lat, lng) => {
    try {
      const response = await fetch(
        `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=${GOOGLE_MAPS_API_KEY}`
      );
      const data = await response.json();
      if (data.results.length > 0) {
        setAddress({
          address: data.results[0].formatted_address,
          lat,
          lng,
        });
        setFormData({
          ...formData,
          address: {
            address: data.results[0].formatted_address,
            lat,
            lng,
          },
        });
      } else {
        setAddress({
          address: "Address not found",
          lat,
          lng,
        });
      }
    } catch (error) {
      console.error("Error fetching address:", error);
      setAddress({
        address: "Error fetching address",
        lat,
        lng,
      });
    }
  };


  const handleFileUpload = async (event, imageType) => {
    const selectedFile = event.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      const uploadData = new FormData();
      uploadData.append("Id", 16);
      uploadData.append("ServiceName", "serviceinventory");
      uploadData.append("Source", "Plexaar");
      uploadData.append("Files", selectedFile);
      uploadData.append("Category", "main");
      uploadData.append("FileType", "i");
      uploadData.append("CreatedBy", userID);
      if (imageType === "uploadedImage") {
        setIsLoadingImage(true);
      } else if (imageType === "uploadedImage2") {
        setIsLoadingImage2(true);
      } else {
        setIsLoadingImage3(true);
      }
      try {
        const response = await instance.post(
          `${process.env.REACT_APP_BASE_URL}/serviceinventory_svc/pv/ServiceAttachment/UploadAttachment`,
          uploadData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.code === 0) {
          setFormData({
            ...formData,
            [imageType]: response.data.paths[0],
          });
          setErrors({ ...errors, [imageType]: false });
        } else {
          customSnackBar("Error uploading image.");
          if (imageType === "uploadedImage") {
            setIsLoadingImage(false);
          } else if (imageType === "uploadedImage2") {
            setIsLoadingImage2(false);
          } else {
            setIsLoadingImage3(false);
          }
        }
      } catch (error) {
        customSnackBar("Error uploading image. Please try again.");
      } finally {
        if (imageType === "uploadedImage") {
          setIsLoadingImage(false);
        } else if (imageType === "uploadedImage2") {
          setIsLoadingImage2(false);
        } else {
          setIsLoadingImage3(false);
        }
      }
    } else {
      customSnackBar(
        "Invalid file type. Please upload a jpg, jpeg, or png file."
      );
    }
  };
  const handleSubmit = async () => {
    const positions = {
      titleText: 1,
      number: 4,
      subText: 2,
      uploadedImage: 3,
      uploadedImage2: 5,
      uploadedImage3: 7,
      email: 6,
      address: 8,
    };
    const variation = {
      titleText: 1,
      number: 2,
      subText: 2,
      uploadedImage: 4,
      uploadedImage2: 4,
      uploadedImage3: 4,
      email: 2,
      address: 2,
    };

    // Validate fields

    const newErrors = {
      titleText: !formData.titleText || formData.titleText === "Text",
      subText: !formData.subText || formData.subText === "Text",
      email: !formData.email || formData.email === "john@hotmail.com",
      number: !formData.number || formData.number === "Text ",
      uploadedImage: !formData.uploadedImage,
      uploadedImage2: !formData.uploadedImage2,
      uploadedImage3: !formData.uploadedImage3,
      address: !formData.address?.address || formData.address?.address === "Select a location on the map",

    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);

    if (!hasErrors) {
      // Build dynamic submission array
      const submissionData = Object.keys(formData).map((key) => {
        let contentType = 1; // Default: 1 for text
        let contentVariation = 1; // Default: 1 for text
        let attachmentUrl = null;

        // If it's an image field
        if (
          key === "uploadedImage" ||
          key === "uploadedImage2" ||
          key === "uploadedImage3"
        ) {
          contentType = 2; // Images have content_type = 2
          contentVariation = 4; // Images have content_variation = 4
          attachmentUrl = formData[key];
        }

        return {
          service_sku: "",
          section_id: sectionId,
          page_id: pageId?.id,
          content: contentType === 1 ? formData[key] : null, // Set text content
          attachment_url: contentType === 2 ? attachmentUrl : null, // Set image URL
          alignment: null,
          content_Width: 2147483647,
          content_Height: 2147483647,
          content_style: null,
          entity_id: businessRecrord?.id,
          entity_type: "BUSINESS",
          position: positions[key], // Dynamic position
          font: null,
          font_color: null,
          font_family: null,
          font_style: null,
          font_size: null,
          content_type: contentType,
          content_variation: variation[key],
        };
      });

      // Attach API call
      try {
        setLoading(true);
        const response = await instance.post(
          `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/content/`,
          submissionData
        );

        if (response.data.code === 0) {
          console.log("API Response:", response.data);
          fetchPageSection();
          setSectionLength(sectionOrder - 1);
          setLoading(false);
          setSubmit(true)
          customSnackBar("Form Submitted Successfully!");
        } else {

          setLoading(false);
          customSnackBar("Failed to submit form. Please try again.");
        }
      } catch (error) {
        setLoading(false);
        customSnackBar(
          "Error submitting form. Please check your network or try again."
        );
      }
    } else {
      customSnackBar("Please fill all the required fields!");
    }
  };

  return (
    <div className="row">
      {/* Left Section */}
      <div className="col-md-6 ">
        <div className={styles.container}>
          {/* Left Section */}
          <div className={styles.leftSection}>
            {/* Large Text Section */}
            <div className={styles.largeBox}>
              <input
                type="text"
                name="titleText"
                placeholder="Text"
                value={formData.titleText}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "transparent",
                  color: "#000",
                  fontSize: "20px",
                  fontWeight: 500,
                }}
                className={`${styles.inputField} ${
                  errors.titleText ? styles.errorBorder : ""
                }`}
              />
              {errors.titleText && (
                <span className={styles.errorText}>Title is required</span>
              )}
            </div>
            <div className={styles.largeBox}>
              <textarea
                type="text"
                name="subText"
                placeholder="Enter Title"
                value={formData.subText}
                onChange={handleInputChange}
                style={{
                  height: 45,
                }}
                className={`${styles.textareaField} ${
                  errors.subText ? styles.errorBorder : ""
                }`}
              />
              {errors.subText && (
                <span className={styles.errorText}>Title is required</span>
              )}{" "}
            </div>
            {/* Smaller Text Boxes */}
            <div className={`${styles.smallBoxContainer} pt-2`}>
              <label className={styles.uploadLabel}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "uploadedImage")}
                  style={{ display: "none" }}
                />
                {isLoadingImage ? (
                  <ClipLoader size={10} />
                ) : (
                  <img
                    alt="none"
                    style={{ borderRadius: "50px" }}
                    height={40}
                    width={40}
                    src={formData.uploadedImage || uploadIcon}
                  />
                )}
              </label>
              {errors.uploadedImage && (
                <span className={styles.errorText}>
                  Image upload is required
                </span>
              )}
              <div className={styles.smallBox}>
                <input
                  type="tel"
                  pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"
                  name="number"
                  placeholder="Number"
                  value={formData.number}
                  onChange={handleInputChange}
                  style={{
                    backgroundColor: "transparent",
                    color: "#000",
                    padding: "0px",
                    // fontWeight: 500,
                  }}
                  className={`${styles.inputField} ${
                    errors.number ? styles.errorBorder : ""
                  }`}
                />
                {errors.number && (
                  <span className={styles.errorText}>Title is required</span>
                )}{" "}
              </div>
              <label className={styles.uploadLabel}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "uploadedImage2")}
                  style={{ display: "none" }}
                />
                {isLoadingImage2 ? (
                  <ClipLoader size={10} />
                ) : (
                  <img
                    alt="none"
                    style={{ borderRadius: "50px" }}
                    height={40}
                    width={40}
                    src={formData.uploadedImage2 || uploadIcon}
                  />
                )}
              </label>
              {errors.uploadedImage2 && (
                <span className={styles.errorText}>
                  Image upload is required
                </span>
              )}
              <div className={styles.smallBox}>
                <input
                  type="email"
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleInputChange}
                  style={{
                    backgroundColor: "transparent",
                    color: "#000",
                    padding: "0px",
                    // fontWeight: 500,
                  }}
                  className={`${styles.inputField} ${
                    errors.email ? styles.errorBorder : ""
                  }`}
                />
                {errors.email && (
                  <span className={styles.errorText}>Title is required</span>
                )}{" "}
              </div>
            </div>

            <div className={styles.smallBoxContainer}>
              <label className={styles.uploadLabel}>
                <input
                  type="file"
                  accept="image/*"
                  onChange={(e) => handleFileUpload(e, "uploadedImage3")}
                  style={{ display: "none" }}
                />
                {isLoadingImage3 ? (
                  <ClipLoader size={10} />
                ) : (
                  <img
                    alt="none"
                    style={{ borderRadius: "50px" }}
                    height={40}
                    width={40}
                    src={formData.uploadedImage3 || uploadIcon}
                  />
                )}
              </label>
              {errors.uploadedImage3 && (
                <span className={styles.errorText}>
                  Image upload is required
                </span>
              )}
              <div className={styles.smallBox}>
                <p className={styles.text}>{address?.address}</p>
              </div>
            </div>
          </div>

          {/* Right Section - Map */}
        </div>
      </div>
      <div className="col-md-6 pt-2">
        <div style={{ flex: 1.5 }}>
          <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={currentPosition}
              zoom={14}
              onClick={handleMapClick}>
              <Marker position={selectedPosition} />
            </GoogleMap>
          </LoadScript>
        </div>
        {/* <div className={styles.rightSection}>
        <iframe
          title="map"
          src="https://maps.google.com/maps?q=Bandung&t=&z=13&ie=UTF8&iwloc=&output=embed"
          className={styles.map}
        ></iframe>
      </div> */}
      </div>
      <div className={styles.saveContainer}>
        <button
          disabled={loading||submit}
          className={styles.submitButton}
          onClick={handleSubmit}>
          {loading ? <ClipLoader size={10} color="#fff" /> :
          submit?"Submitted": "Submit"}
        </button>
      </div>
    </div>
  );
};

export default AddressSection;

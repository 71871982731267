import React, { useCallback, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { BsThreeDotsVertical } from "react-icons/bs";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

const Actions = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const {
    index,
    row,
    actionLoading,
    handleProfile,
    handleAddDepartment,
    handleAddUser,
    handleContentPage,
    action,
    permission, // Permission object passed as a prop
  } = props;

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, []);
  return (
    <>
      <div className="">
        <BsThreeDotsVertical
          key={row.id}
          id={"action-button-" + index}
          style={{ color: "#9a9999", fontSize: 16 }}
          onClick={handleClick}
        />
        <Menu
          key={row.id}
          id={"action-menu-" + index}
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}>
          {!row.verified && (
            <>
             
                <MenuItem
                  id={"profile-" + index}
                  className="pr-5 px-3"
                  onClick={() => {
                    handleClose();
                    handleProfile(row);
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FFD705",
                      color:"white"
                    },
                  }}>
                  {/* <img
                    src="/images/user-follow-line 1.png"
                    alt=""
                    className="text-primary"
                  /> */}
                  <span className="ps-2 dx-menu">Profile</span>
                </MenuItem>
            

             
                <MenuItem
                  id={"add-department-" + index}
                  className="pr-5 px-3"
                  onClick={() => {
                    handleClose();
                    handleAddDepartment(row);
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FFD705",
                      color:"white"

                    },
                  }}>
                  {/* <img
                    src="/images/Group 515743.svg"
                    alt=""
                    className="text-primary"
                  /> */}
                  <span className="dx-menu ps-2">Departments</span>
                </MenuItem>

              
                <MenuItem
                  id={"add-user-" + index}
                  className="pr-5 px-3"
                  onClick={() => {
                    handleClose();
                    handleAddUser(row);
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FFD705",
                      color:"white"

                    },
                  }}>
                  <span className="dx-menu ps-2" style={{ fontSize: "12px" }}>
                    User
                  </span>
                </MenuItem>

                {/* <MenuItem
                  id={"add-user-" + index}
                  className="pr-5 px-3"
                  onClick={() => {
                    handleClose();
                    handleContentPage(row);
                  }}
                  sx={{
                    "&:hover": {
                      backgroundColor: "#FFD705",
                      color:"white"

                    },
                  }}>
                  <span className="dx-menu ps-2" style={{ fontSize: "12px" }}>
                    Content Page
                  </span>
                </MenuItem> */}
           
            </>
          )}
        </Menu>
      </div>
    </>
  );
};

export default Actions;

import React, { useState, useEffect } from "react";
import Table from "./Table";
import { OnSearch } from "./helper";
import Header from "../Header";
import { userList } from "../../../utils/constant";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteUserRequest,
  fetchUserRequest,
} from "../../../Store/User/actions";
import SkeletonTable from "../SkeltonTableSimple/SkeltonTable";
import SideNavone from "../../../SideNavBar";
import AppLayout from "../../Layout/AppLayout";
import { customSnackBar, isBusinessRolePermissionAllowed } from "../../../utils";
import { Spin } from "antd";
const MangeUser = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const businessId = queryParams.get("id");
  const teamRecord = location;
  const flag = false;
  const [rowData, setRowData] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const data = useSelector((state) => state.User?.data);
  const loading = useSelector((state) => state.User?.loading);
  const business = JSON.parse(localStorage.getItem("businessRecord"));
  useEffect(() => {
    dispatch(fetchUserRequest(business.id));
  }, []);

  const [viewPermssion, setViewPermissions] = useState(null);

  const permissionsToCheck = [
    
    "canEditBusinessUser",
    "canDeleteBusinessUser"
   
  ];
  
  let permissions = useSelector((state) => state?.Auth?.permissionsModule);
  useEffect(() => {
    const fetchPermissions = async () => {
      const fetchedPermissions = {};
      for (const permission of permissionsToCheck) {
        const result = await isBusinessRolePermissionAllowed(
          permissions,
          "Business Roles & Permissions",
          permission
        );
        fetchedPermissions[permission] = result;
      }
      setViewPermissions(fetchedPermissions);
    };

    if (permissions) {
      fetchPermissions();
    } else {
      // console.log("user doesn'y have  permsiion");
    }
  }, [permissions]);
  useEffect(() => {
    setRowData(data || []);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [data]);
  const removeBusiness = (id) => {
    dispatch(
      deleteUserRequest({
        id,
        setRowData,
        rowData,
      })
    );
  };
  //Delete busienss Confirmation Dialouge Box handler
  const handleBusinessDeleteConfirmation = (id) => {
   if( viewPermssion === null ||
            viewPermssion?.canDeleteBusinessUser ||
            viewPermssion?.canDeleteBusinessUser === null ||
            viewPermssion?.canDeleteBusinessUser === undefined){
    setDialog({
      visible: true,
      key: Math.random().toString(36).substring(7),
      ok: () => removeBusiness(id),
    });
  }else{
    customSnackBar("You haven't permission to Delete the user")
  }
  };
  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [/* dependencies go here */]);
  return (
    <>
      <AppLayout
        name="Users"
        isShow={true}
        allSpace={
          <>
            <div style={{ height: "100vh", overflow: "auto", }}
              className=" hide_scrollbar "  >
              {loading ? (
                <div className="center-loader">
                  <Spin/>
                </div>              ) : (
                <Table
            permission={viewPermssion}

                  loading={loading}
                  setRecord={setRowData}
                  data={rowData}
                  business={business}
                  dialog={dialog}
                  deleteUser={handleBusinessDeleteConfirmation}
                />
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default MangeUser;

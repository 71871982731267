import React, { useState } from "react";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
// import { customSnackBar } from "../../../../../utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import "../../../../../Calendar/scheduler/AppointmentDetails/appointmentDetails.css";
import { useSelector } from "react-redux";
// import DialogBox from "../../../../../Pages/BusinessProfile/DialogBox";
import { ClipLoader } from "react-spinners";
import { RightAngle } from "../../../../../Assets/svgs";
// import instance from "../../../../../utils/utils";
// import { RightAngle } from "Calendar/scheduler/AppointmentDetails/Svgs/svgs";

const RightSection = ({ scheduleData, handleOpen }) => {
  const history = useHistory();
  const { id } = useParams();
  var userInfo = useSelector((state) => state.Auth.userloginsuccessyasir);
  const [dialog, setDialog] = useState({ visible: false });
  const [value, setValue] = useState(2);

  const handleChange = (e) => setValue(e.target.value);
  const handleBack = () => {
    let url = localStorage.getItem("url");
    window.location.href = url; // Redirect to the desired URL
    //
    localStorage.clear();
    // Move to the desired domain URL
  };
  return (
    <>
      <div className="left-section">
        <div className="d-flex justify-content-center">
          <Button
            // disabled={bookingData?.appointments[0]?.status === "no_show"}
            sx={{
              textTransform: "capitalize",
              width: "100%",
              backgroundColor: "#FFD705",
              boxShadow: "none",

              height: "40px",
              borderRadius: "5px",
              "&:hover": {
                backgroundColor: "#FFD705",
                boxShadow: "none",
              },
            }}
            onClick={()=>{
              handleBack()
            }}
            // onClick={() => {
            //   if (bookingData?.appointments[0]?.status === "created") {
            //     handleCheckIn();
            //   } else if (
            //     bookingData?.appointments[0]?.status === "is_job_started"
            //   ) {
            //     handleCompleteJob();
            //   } else if (
            //     bookingData?.appointments[0]?.status === "isCheckedIn"
            //   ) {
            //     handleStartJob();
            //   } else if (bookingData?.appointments[0]?.status === "no_show") {
            //     customSnackBar("The Provider is not Available");
            //   } else {
            //     ComplteteBooking();
            //   }
            // }}
            variant="contained">
            Next
          </Button>
        </div>

        <div className="order-details-section-container mt-4">
          <div className="Order-details_order-num">
            <div className="Order-details_quantity">
              {/* <label className="appointment_heading pb-1">Status</label> */}
              <span className="quantity_number">
                <select
                  value={value}
                  onChange={handleChange}
                  className="w-100 appointment-status-dropdown">
                  <option value={1}>Publish</option>
                  <option value={2}>Unpublish</option>
                </select>
              </span>
            </div>
          </div>
        </div>
        {/* {Object.keys(scheduleData).length > 0 && ( */}
          <div className="order-details-section-container mt-4">
            <div className="Order-details_order-num">
              <div className="d-flex justify-content-between align-items-baseline">
                <span
                  onClick={handleOpen}
                  //   bookingData?.appointments[0]?.status === "isCompleted"
                  //     ? handleUpdateAlert()
                  //     : handleUpdateBooking();
                  // }}
                  className="appointment-action-btns py-1">
                  Modify
                </span>
                <RightAngle />
              </div>
            </div>
            <hr className="left-section-container-lines " />
            <div className="Order-details_order-num">
              <div className="d-flex justify-content-between align-items-baseline">
                <span
                  // onClick={(e) => {
                  //   bookingData?.appointments[0]?.status === "isCompleted"
                  //     ? handleUpdateAlert()
                  //     : handleBookingCancelConfirmation();
                  // }}
                  className="appointment-action-btns py-1">
                  Delete
                </span>
                <RightAngle />
              </div>
            </div>
          </div>
        {/* )} */}
        {/* <div className="order-details-section-container mt-4">
          <div className="Order-details_order-num">
            <div className="d-flex justify-content-between align-items-baseline">
              <span
                // onClick={(e) => {
                //   bookingData?.appointments[0]?.status === "isCompleted"
                //     ? handleUpdateAlert()
                //     : handleUpdateBooking();
                // }}
                className="appointment-action-btns py-1">
                Specific Date Schedule
              </span>
              <RightAngle />
            </div>
          </div>
        </div> */}

        {/* <div className="order-details-section-container mt-4">
          <div className="Order-details_order-num">
            <div className="Order-details_quantity">
              <span className="quantity_number">
                {bookingData.plexaarBookingId}
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};

export default RightSection;

import React, { useState, useEffect } from "react";
import axios from "axios";

import SearchBar from "./Map/SearchBar";
import BusinessLocation from "./Map/BusinessLocation";
import { defaultLocation } from "./helper"; // Assuming defaultLocation is exported from a separate file
import SideNavBar from "../../../SideNavBar/SideNavBar";
import { useDispatch } from "react-redux";
import { addedBusinessAddress } from "../../../Store/Business/actions";
import { ClipLoader } from "react-spinners";
import { Button } from "@mui/material";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const BusinessMap = ({ result, setAddress, address, action, editData, handleNext, loading }) => {
  const history = useHistory()
  const APIKEY = "1a12e15218234ec6838a401380c55b08";
  const [searchTerm, setSearchTerm] = useState(address || "");
  const [searchResults, setSearchResults] = useState([]);
  const [selectedLocation, setSelectedLocation] = useState(defaultLocation);
  const [message, setMessage] = useState(""); // Define the message state
  const [isSearchFieldValid, setIsSearchFieldValid] = useState(false);
  const dispatch = useDispatch()
  const handleInputChange = async (e) => {
    const value = e.target.value;

    setSearchTerm(value);
    setAddress(value); // Update address directly with the value
    if (value.trim() === "") {
      setIsSearchFieldValid(true);
      setMessage("Please Search the Address"); // Clear the message when the search field is valid
    } else {
      setIsSearchFieldValid(false);
      setMessage(""); // Clear the message when the search field is valid

    }

    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          value
        )}&key=${APIKEY}`
      );
      setSearchResults(response.data.results);
    } catch (error) {
      console.error("Search error: ", error);
    }
  };
  useEffect(() => {
    if (editData && editData.id) {
      setSearchTerm(editData.businessAddress.addressName);
      setIsSearchFieldValid(true);

    }
  }, [action, editData]);
  const handleResultClick = (result) => {
    setSelectedLocation(result);
    setSearchTerm(result.formatted);
    setSearchResults([]);
    setIsSearchFieldValid(true);

    dispatch(addedBusinessAddress(result));

  };

  const selectedLocationHandlers = async (location) => {
    try {
      const response = await axios.get(
        `https://api.opencagedata.com/geocode/v1/json?q=${encodeURIComponent(
          location.lat
        )}+${encodeURIComponent(location.lng)}&key=${APIKEY}`
      );
      setSearchTerm(response.data.results[0].formatted);
      setSelectedLocation(response.data.results[0]);
      setIsSearchFieldValid(true);

      dispatch(addedBusinessAddress(response.data.results[0]));

    } catch (error) { }
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', }} >
      {/* <BusinessName action={action} result={result} editData={editData} /> */}
      <SearchBar
        address={address}
        // setAddress={setAddress}
        location={selectedLocation}
        searchTerm={searchTerm}
        editData={editData}
        handleInputChange={handleInputChange}
        searchResults={searchResults}
        handleResultClick={handleResultClick}
        isSearchFieldValid={isSearchFieldValid}
        message={message}
      />
      <BusinessLocation
        action={action}
        center={[
          selectedLocation?.geometry?.lat || defaultLocation?.geometry?.lat,
          selectedLocation?.geometry?.lng || defaultLocation?.geometry?.lng,
        ]}
        defaultGeometry={{
          lat: 33.6938882232111,
          lng: 73.0651304125786,
        }}
        zoom={17}
        address={address}
        selectedLocationHandler={selectedLocationHandlers}
        isSearchFieldValid={isSearchFieldValid}
      />
      <div className="w-100 d-flex mt-3 flex-wrap justify-content-end ">
        <div className="pe-2">
          <Button
            sx={{
              width: "150px",
              backgroundColor: "#edf0f5",
              height: "40px",
              color: "#b6bec8",
              borderRadius: "10px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#c0c0c0",
                color: "#ffffff",
              },
            }}
            onClick={() => {
              history.goBack();
            }}
            variant="contained"
            className=" btn btn-secondary padding_next_secondary_button"
          >
            {"Cancel"}
          </Button>
        </div>
        <div>
          <Button
            sx={{
              width: "150px",
              backgroundColor: "#FFD705",
              height: "40px",
              borderRadius: "10px",
              textTransform: "capitalize",
              boxShadow: "none",

"&:hover": {
  backgroundColor: "#FFD705",
  color: "white",
  boxShadow: "none",
},
            }}
            className="padding_next_primary_button"
            onClick={handleNext}
            disabled={!isSearchFieldValid}

            variant="contained"
          >
            {loading ? <ClipLoader color="#fffff" size={30} /> : "Next"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BusinessMap;

import React from "react";
import { Button } from "@mui/material";
import { ClipLoader } from "react-spinners";

const EditBusinessAddressForm = (props) => {
  const {
    values,
    errors,
    touched,
    handleChange,
    handleSubmit,
    loading,
    handleNextClose,
  } = props;

  return (
    <div>
      <div className="d-flex justify-content-between flex-wrap">
        <div className="border_rad_new px-2 pb-2 mt-3 w-100 mr-3">
          
          <label className="label_text_business">Address Name</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="addressName"
            name="addressName"
            value={values.addressName}
            onChange={handleChange}
            placeholder="Enter Address"
          />
         
        </div>
        {errors.addressName && (
            <span className="text-danger f-12">
              {errors.addressName}
            </span>
          )}
        <div className="border_rad_new px-2 pb-2 mt-3 w-100 mr-3">
          <label className="label_text_business">Line 1</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="line1"
            name="line1"
            value={values.line1}
            onChange={handleChange}
            placeholder="Enter line1 Address"
          />
         
        </div>
        {errors.line1 && (
            <span className="text-danger f-12">
              {errors.line1}
            </span>
          )}
      </div>

      <div className="d-flex justify-content-between flex-wrap">
        <div
          style={{ width: "100%", maxWidth: "490px" }}
          >
        <div
          className="border_rad_new px-2 pb-2 mt-3  mr-3"
        >
          <label className="label_text_business">Flat & Building</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="flat"
            name="flat"
            value={values.flat}
            onChange={handleChange}
            placeholder="Enter Flat & Building"
          />
         
        </div>
        {errors.flat && (
            <span className="text-danger f-12">
              {errors.flat}
            </span>
          )}
          </div>
          <div
          style={{ width: "100%", maxWidth: "490px" }}
          >
        <div
          className="border_rad_new px-2 pb-2 mt-3  mr-3"
        >
          <label className="label_text_business">Line 2</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="line2"
            name="line2"
            value={values.line2}
            onChange={handleChange}
            placeholder="Enter Line2 Address"
          />
         
        </div>
        {errors.streetAddress && (
            <span className="text-danger f-12">
              {errors.streetAddress}
            </span>
          )}
      </div>
      </div>

      <div className="d-flex justify-content-between flex-wrap">
        <div
          style={{ width: "100%", maxWidth: "490px" }}
          >
        <div
          className="border_rad_new px-2 pb-2 mt-3  mr-3"
        >
            <label className="label_text_business">City</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="city"
            name="city"
            value={values.city}
            onChange={handleChange}
            placeholder="Enter City"
          />
         
        </div>
        {errors.city && (
            <span className="text-danger f-12">
              {errors.city}
            </span>
          )}
          </div>
          <div
          style={{ width: "100%", maxWidth: "490px" }}
          >
        <div
          className="border_rad_new px-2 pb-2 mt-3  mr-3"
        >
         <label className="label_text_business">Post Code</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="code"
            name="code"
            value={values.code}
            onChange={handleChange}
            placeholder="Enter Post Code"
          />
         
        </div>
        {errors.code && (
            <span className="text-danger f-12">
              {errors.code}
            </span>
          )}
      </div>
      </div>
      {/* <div className="d-flex justify-content-between flex-wrap">
        <div
          style={{ width: "100%", maxWidth: "490px" }}
          >
        <div
          className="border_rad_new px-2 pb-2 mt-3 w-50 mr-3"
        >
          <label className="label_text_business">Post Code</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="code"
            name="code"
            value={values.code}
            onChange={handleChange}
            placeholder="Enter Post Code"
          />
         
        </div>
        {errors.code && (
            <span className="text-danger f-12">
              {errors.code}
            </span>
          )}
          </div>
          <div 
          style={{ width: "100%", maxWidth: "490px" }}
          >
        <div
          className="border_rad_new px-2 pb-2 mt-3 w-50 mr-3"
        >
          <label className="label_text_business">City</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="city"
            name="city"
            value={values.city}
            onChange={handleChange}
            placeholder="Enter City"
          />
         
        </div>
        {errors.city && (
            <span className="text-danger f-12">
              {errors.city}
            </span>
          )}
          </div>
      </div> */}

      <div className="border_rad_new px-2 pb-2 mt-3">
        <label className="label_text_business">Address Note</label>
        <textarea
          className="form-control1 input_style_fix"
          rows={3}
          type="text"
          id="note"
          name="note"
          onChange={handleChange}
          placeholder="Type note Here..."
          value={values.note}
          error={touched.note && errors.note}
        ></textarea>
      </div>

      <div className="d-flex justify-content-end mt-4">
        <div className="pe-2">
          <Button
            sx={{
              width: "150px",
              backgroundColor: "#edf0f5",
              height: "40px",
              color: "#b6bec8",
              borderRadius: "10px",
              textTransform: "capitalize",
              "&:hover": {
                backgroundColor: "#c0c0c0",
                color: "#ffffff",
              },
            }}
            onClick={handleNextClose}
            variant="contained"
            className=" btn btn-secondary padding_next_secondary_button"
          >
            Cancel
          </Button>
        </div>
        <div>
          <Button
            sx={{
              width: "150px",
              backgroundColor: "#FFD705",
              height: "40px",
              borderRadius: "10px",
              textTransform: "capitalize",
              boxShadow: "none",

"&:hover": {
  backgroundColor: "#FFD705",
  color: "white",
  boxShadow: "none",
},
            }}
            className="padding_next_primary_button"
            onClick={handleSubmit}
            variant="contained"
          >
            {loading ? <ClipLoader color="#ffffff" size={30} /> : "Update"}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default EditBusinessAddressForm;

// import React, { useState } from "react";
// import { Table, Input, Button, TimePicker } from "antd";
// import styles from "../style.module.css";
// import moment from "moment";
// import uploadIconImage from "../../../../Assets/svgs/ovaluploader.svg";
// import BusinessContentImage from "../../../../Assets/svgs/ovalUplaod.svg"
// const { TextArea } = Input;

// const OpeningHour = () => {
//   const daysOfWeek = [
//     "Monday",
//     "Tuesday",
//     "Wednesday",
//     "Thursday",
//     "Friday",
//     "Saturday",
//     "Sunday",
//   ];

//   const [data, setData] = useState(
//     daysOfWeek.map((day, index) => ({
//       key: index,
//       day: day,
//       startTime: null,
//       endTime: null,
//     }))
//   );

//   const handleInputChange = (key, field, value) => {
//     const updatedData = data.map((item) =>
//       item.key === key ? { ...item, [field]: value } : item
//     );
//     setData(updatedData);
//   };

//   const handleAddRow = () => {
//     const newRow = {
//       key: Date.now(),
//       day: "",
//       startTime: null,
//       endTime: null,
//     };
//     setData([...data, newRow]);
//   };

//   const handleDeleteRow = (key) => {
//     const updatedData = data.filter((item) => item.key !== key);
//     setData(updatedData);
//   };

//   const columns = [
//     {
//       title: "Day",
//       dataIndex: "day",
//       key: "day",
//       render: (text, record) => (
//         <Input
//           className={styles.input}
//           value={text}
//           placeholder="Enter Day"
//           onChange={(e) => handleInputChange(record.key, "day", e.target.value)}
//         />
//       ),
//     },
//     {
//       title: "Start Time",
//       dataIndex: "startTime",
//       key: "startTime",
//       render: (text, record) => (
//         <TimePicker
//           className={styles.timePicker}
//           value={record.startTime ? moment(record.startTime, "HH:mm") : null}
//           format="HH:mm"
//           onChange={(time, timeString) =>
//             handleInputChange(record.key, "startTime", timeString)
//           }
//         />
//       ),
//     },
//     {
//       title: "End Time",
//       dataIndex: "endTime",
//       key: "endTime",
//       render: (text, record) => (
//         <TimePicker
//           className={styles.timePicker}
//           value={record.endTime ? moment(record.endTime, "HH:mm") : null}
//           format="HH:mm"
//           onChange={(time, timeString) =>
//             handleInputChange(record.key, "endTime", timeString)
//           }
//         />
//       ),
//     },
//     // {
//     //   title: "Actions",
//     //   key: "actions",
//     //   render: (_, record) => (
//     //     <span
//     //       className={styles.actionButton}
//     //       onClick={() => handleDeleteRow(record.key)}
//     //     >
//     //       Delete
//     //     </span>
//     //   ),
//     // },
//   ];

//   return (
//     <div className="row">
//  <div className=" col-md-6 pt-4">
//           <div style={{ height: "60px" }} className={styles.largeBox}>
//             <h1 className={styles.text2}>{"Text"}</h1>
//           </div>
//           <div style={{ height: "60px" }} className={`${styles.largeBox} mt-2`}>
//             <h1 className={styles.subText}>{"Text"}</h1>
//           </div>
//           <div className="pt-3">
//           <Table
//         className={styles.table}
//         dataSource={data}
//         columns={columns}
//         pagination={false}
//         bordered
//       />
//       </div>
//         </div>
//         <div className={`col-md-6 p-0 text-end ${styles.imageContainer}`}>
//       {/* Background Image */}
//       <img
//         alt="Business Content"
//         src={BusinessContentImage}
//         className={styles.backgroundImage}
//       />

//       {/* Overlay Image */}
//       {/* <img
//         alt="Upload Icon"
//         src={uploadIconImage}
//         className={styles.overlayImage}
//       /> */}
//     </div>

//       {/* <div className={styles.addButton}>
//         <Button onClick={handleAddRow}>Add Row</Button>
//       </div> */}
//     </div>
//   );
// };

// export default OpeningHour;
import React, { useState } from "react";
import { Table, TimePicker, Button, Input } from "antd";
import moment from "moment";
import styles from "../style.module.css";
import BusinessContentImage from "../../../../Assets/svgs/ovalUplaod.svg";
import { ClipLoader } from "react-spinners";
import instance from "../../../../utils/utils";
import { customSnackBar } from "../../../../utils";

const OpeningHour = ({
  sectionId,
  pageId,
  fetchPageSection,
  businessRecrord,
  setSectionLength,
  sectionOrder,
  userID
}) => {
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  const [data, setData] = useState(
    daysOfWeek.map((day, index) => ({
      key: index,
      day,
      startTime: "06:00",
      endTime: "22:00",
    }))
  );

  const [formData, setFormData] = useState({
    titleText: "Text",
    subText: "Text",
    uploadedImage: null, // For Icon Image
    // buttonTitle: "Submit",
  });

  const [errors, setErrors] = useState({});
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false });
  };

  const handleScheduleChange = (key, field, value) => {
    const updatedData = data.map((item) =>
      item.key === key ? { ...item, [field]: value } : item
    );
    setData(updatedData);
  };

  const validateFields = () => {
    const validationErrors = {};

    // Validate schedule
    data.forEach((item) => {
      if (!item.day) validationErrors[item.key] = "Day is required";
      if (!item.startTime)
        validationErrors[item.key] = "Start time is required";
      if (!item.endTime) validationErrors[item.key] = "End time is required";
    });

    // Validate form fields
    if (!formData.titleText || formData.titleText === "Text")
      validationErrors.titleText = "Title is required";
    if (!formData.subText || formData.titleText === "Text")
      validationErrors.subText = "Subtitle is required";
    if (!formData.uploadedImage)
      validationErrors.uploadedImage = "Image is required";

    setErrors(validationErrors);
    return Object.keys(validationErrors).length === 0;
  };

 

  const handleSubmit = async () => {
    const positions = {
      titleText: 1,
      subText: 2,
      uploadedImage: 8,
      Monday: 3,
      Tuesday: 4,
      Wednesday: 5,
      Thursday: 6,
      Friday: 7,
      Saturday: 8,
      Sunday: 9,
    };

    if (!validateFields()) {
      customSnackBar("Please fill all the required fields!");
      return;
    }
    const variation = {
      titleText: 1,
      subText: 2,
      uploadedImage: 4,
    };
    // Build dynamic submission array
    const openingHours = data.flatMap((dayItem) => {
      const dayPosition = positions[dayItem.day];
      return [
        {
          service_sku: "",
          section_id: sectionId,
          page_id: pageId?.id,
          content: dayItem.day, // Name of the day
          attachment_url: null,
          alignment: null,
          content_Width: 2147483647,
          content_Height: 2147483647,
          content_style: null,
          entity_id: businessRecrord?.id,
          entity_type: "BUSINESS",
          position: dayPosition, // Position for the day name
          font: null,
          font_color: null,
          font_family: null,
          font_style: null,
          font_size: null,
          content_type: 1, // Text type
          content_variation: 2, // Default for text
          day: dayItem.day,
          start_time: null,
          end_time: null,
        },
        {
          service_sku: "",
          section_id: sectionId,
          page_id: pageId?.id,
          content: dayItem.startTime, // Label for start time
          attachment_url: null,
          alignment: null,
          entity_id: businessRecrord?.id,
          entity_type: "BUSINESS",
          content_Width: 2147483647,
          content_Height: 2147483647,
          content_style: null,
          position: dayPosition + 1, // Slight offset for start time
          font: null,
          font_color: null,
          font_family: null,
          font_style: null,
          font_size: null,
          content_type: 1, // Text type
          content_variation: 2, // Default for text
          day: dayItem.day,
          start_time: dayItem.startTime,
          end_time: null,
        },
        {
          service_sku: "",
          section_id: sectionId,
          page_id: pageId?.id,
          content: dayItem.endTime, // Label for end time
          attachment_url: null,
          alignment: null,
          content_Width: 2147483647,
          content_Height: 2147483647,
          entity_id: businessRecrord?.id,
          entity_type: "BUSINESS",

          content_style: null,
          position: dayPosition + 1, // Slight offset for end time
          font: null,
          font_color: null,
          font_family: null,
          font_style: null,
          font_size: null,
          content_type: 1, // Text type
          content_variation: 2, // Default for text
          day: dayItem.day,
          start_time: null,
          end_time: dayItem.endTime,
        },
      ];
    });

    const submissionData = [
      ...Object.keys(formData).map((key) => {
        let contentType = 1; // Default: 1 for text
        let contentVariation = formData.titleText ? 1 : 2; // Default: 1 for text
        let attachmentUrl = null;

        if (key === "uploadedImage") {
          contentType = 2; // Images have content_type = 2
          contentVariation = 4; // Images have content_variation = 4
          attachmentUrl = formData[key];
        }

        return {
          service_sku: "",
          section_id: sectionId,
          page_id: pageId?.id,
          content: contentType === 1 ? formData[key] : null,
          attachment_url: contentType === 2 ? attachmentUrl : null,
          alignment: null,
          content_Width: 2147483647,
          content_Height: 2147483647,
          content_style: null,
          position: positions[key] || 0, // Dynamic position
          font: null,
          entity_id: businessRecrord?.id,
          entity_type: "BUSINESS",
          font_color: null,
          font_family: null,
          font_style: null,
          font_size: null,
          content_type: contentType,
          content_variation: variation[key],
        };
      }),
      ...openingHours, // Add opening hours data
    ];

    // Attach API call
    try {
      setLoading(true)
      const response = await instance.post(
        `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/content/`,
        submissionData
      );

      if (response.data.code === 0) {
        console.log("API Response:", response.data);
        fetchPageSection();
        setSectionLength(sectionOrder - 1);
        setLoading(false);
        setSubmit(true)
        customSnackBar("Form Submitted Successfully!");
      } else {
        setLoading(false);

        console.error("Error:", response.data.message);
        customSnackBar("Failed to submit form. Please try again.");
      }
    } catch (error) {
      setLoading(false);

      console.error("API Error:", error);
      customSnackBar("Error submitting form. Please check your network or try again.");
    }
  };

  const handleFileUpload = async (event) => {
    const selectedFile = event.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      const uploadData = new FormData();
      uploadData.append("Id", 16);
      uploadData.append("ServiceName", "serviceinventory");
      uploadData.append("Source", "Plexaar");
      uploadData.append("Files", selectedFile);
      uploadData.append("Category", "main");
      uploadData.append("FileType", "i");
      uploadData.append("CreatedBy", userID);

      setIsLoadingImage(true);

      try {
        const response = await instance.post(
          `${process.env.REACT_APP_BASE_URL}/serviceinventory_svc/pv/ServiceAttachment/UploadAttachment`,
          uploadData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.code === 0) {
          setFormData({
            ...formData,
            uploadedImage: response.data.paths[0],
          });
          setErrors({ ...errors, uploadedImage: false });
        } else {
          customSnackBar("Error uploading image.");
        }
      } catch (error) {
        customSnackBar("Error uploading image. Please try again.");
      } finally {
        setIsLoadingImage(false);
      }
    } else {
      customSnackBar("Invalid file type. Please upload a jpg, jpeg, or png file.");
    }
  };

  const columns = [
    {
      title: "Day",
      dataIndex: "day",
      key: "day",
      render: (text, record) => (
        <Input
          className={styles.input}
          value={text}
          placeholder="Enter Day"
          onChange={(e) =>
            handleScheduleChange(record.key, "day", e.target.value)
          }
        />
      ),
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      render: (text, record) => (
        <TimePicker
          className={styles.timePicker}
          value={record.startTime ? moment(record.startTime, "HH:mm") : null}
          format="HH:mm"
          onChange={(time, timeString) =>
            handleScheduleChange(record.key, "startTime", timeString)
          }
        />
      ),
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      render: (text, record) => (
        <TimePicker
          className={styles.timePicker}
          value={record.endTime ? moment(record.endTime, "HH:mm") : null}
          format="HH:mm"
          onChange={(time, timeString) =>
            handleScheduleChange(record.key, "endTime", timeString)
          }
        />
      ),
    },
  ];

  return (
    <div className="row">
      <div className="col-md-6 pt-4">
        <div className={styles.largeBox}>
          <input
            type="text"
            name="titleText"
            placeholder="Text"
            value={formData.titleText}
            onChange={handleInputChange}
            style={{
              backgroundColor: "transparent",
              color: "#000",
              fontSize: "20px",
              fontWeight: 500,
            }}
            className={`${styles.inputField} p-0${
              errors.titleText ? styles.errorBorder : ""
            }`}
          />
          {errors.titleText && (
            <span className={styles.errorText}>Title is required</span>
          )}
        </div>
        <div className={`${styles.largeBox} mt-2`}>
          <textarea
            type="text"
            name="subText"
            placeholder="Enter Title"
            value={formData.subText}
            onChange={handleInputChange}
            style={{
              height: 40,
            }}
            className={`${styles.textareaField} mt-2 ${
              errors.subText ? styles.errorBorder : ""
            }`}
          />
          {errors.subText && (
            <span className={styles.errorText}>Title is required</span>
          )}{" "}
        </div>
        <div className="pt-3">
          <Table
            className={styles.table}
            dataSource={data}
            columns={columns}
            pagination={false}
            bordered
          />
        </div>
      
      </div>
      <div className="col-md-6 p-5 text-end">
        <label className={styles.uploadLabel}>
          <input
            type="file"
            accept="image/*"
            onChange={handleFileUpload}
            style={{ display: "none" }}
          />
          {isLoadingImage ? (
            <ClipLoader size={20} />
          ) : (
            <img
              alt="none"
              height="500px"
              width="400px"
              style={{ borderRadius: formData.uploadedImage ? "50%" : "" }}
              src={formData.uploadedImage || BusinessContentImage}
              className={styles.uploadedImage}
            />
          )}
        </label>
        {errors.uploadedImage && (
          <span className={styles.errorText}>Image upload is required</span>
        )}
      </div>
      <div className={styles.saveContainer}>
      <button
  disabled={loading || submit} // Fixed typo here
  className={styles.submitButton}
          onClick={handleSubmit}>
          {loading ? <ClipLoader size={10} color="#fff" /> :
          submit?"Submitted": "Submit"}
        </button>
      </div>
    </div>
  );
};

export default OpeningHour;

import React, { useState, useEffect } from "react";
import Table from "./Table";

import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
// import {
//   deleteUserRequest,
//   fetchUserRequest,
// } from "../../../Store/User/actions";
import AppLayout from "../Layout/AppLayout";
import { Spin } from "antd";
import { fetchContentPageRequest } from "../../Store/User/actions";
const ManageConetentPages = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const queryParams = new URLSearchParams(location.search);
  const businessId = queryParams.get("id");
  const teamRecord = location;
  const flag = false;
  const [rowData, setRowData] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const data = useSelector((state) => state.User?.contentPage);
  const loading = useSelector((state) => state.User?.loading);
  const business = JSON.parse(localStorage.getItem("businessRecord"));
  useEffect(() => {
    dispatch(fetchContentPageRequest(business.id));
  }, []);


 
  useEffect(() => {
    setRowData(data || []);
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [data]);

  //Delete busienss Confirmation Dialouge Box handler

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [/* dependencies go here */]);
  return (
    <>
      <AppLayout
        name="Business Content Page"
        isShow={true}
        allSpace={
          <>
            <div style={{ height: "100vh", overflow: "auto", }}
              className=" hide_scrollbar "  >
              {loading ? (
                <div className="center-loader">
                  <Spin/>
                </div>              ) : (
                <Table
            // permission={viewPermssion}

                  loading={loading}
                  setRecord={setRowData}
                  data={rowData}
                  business={business}
                  dialog={dialog}
                  // deleteUser={handleBusinessDeleteConfirmation}
                />
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default ManageConetentPages;

import React, { useState } from "react";
import PropTypes from "prop-types";
import TableSortLabel from "@mui/material/TableSortLabel";
import { TableRow, TableCell, TableHead } from "@mui/material";
import { BsThreeDotsVertical } from "react-icons/bs";
import { BiPlusCircle } from "react-icons/bi";

const TableHeader = (props) => {
  const {
    order,
    orderBy,
    onRequestSort,
    column,
    selectedCheckboxes,
    handleHeaderCheckboxChange,
    rowCount,
  } = props;

  const [frozenColumns, setFrozenColumns] = useState([]);
  const [deletedColumns, setDeletedColumns] = useState([]);

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  const handleColumnToggle = (columnId) => {
    if (frozenColumns.includes(columnId)) {
      setFrozenColumns(frozenColumns.filter((id) => id !== columnId));
    } else {
      setFrozenColumns([...frozenColumns, columnId]);
    }
  };

  const handleColumnDelete = (columnId) => {
    if (!deletedColumns.includes(columnId)) {
      setDeletedColumns([...deletedColumns, columnId]);
    }
  };

  return (
    <TableHead className="thead-light text-capitalize font-size-sm font-weight-bold">
      <TableRow>
        <TableCell
          style={{
            border: "1px solid #ededed",
            borderTop: " 4px solid #FFD705",
          }}
          padding="checkbox"
        >
          {/* <input
            type="checkbox"
            value=""
            id="flexCheckDefault"
            className="form-check-input2 checkbox_btn2"
            indeterminate={
              selectedCheckboxes.length > 0 &&
              selectedCheckboxes.length < rowCount
            }
            checked={selectedCheckboxes.length === rowCount}
            onChange={handleHeaderCheckboxChange}
          /> */}
          <img src="/images/columns 1.svg" alt="coumn"/>
        </TableCell>
        {column?.map((headCell, index) => {
          if (deletedColumns.includes(headCell.id)) {
            return null;
          }
          const isFrozen = frozenColumns.includes(headCell.id);

          return (
            <TableCell
              className={headCell.class}
              key={headCell.id}
              align={headCell.align}
              style={{
                minWidth: headCell.minWidth,
                color: "#9F9E9E",
                border: headCell.border,
                borderTop: index === 0 ? "4px solid #FFD705" : headCell.borderTop,
                position: isFrozen ? "sticky" : "initial",
                left: isFrozen ? 0 : "auto",
                backgroundColor: isFrozen ? "#f7f7f7" : "#FBFBFB",
              }}
            >
              <div className="d-flex justify-content-between">
                {headCell.sort ? (
                  <TableSortLabel
                    id={"sort-table" + index}
                    active={orderBy === headCell.id}
                    direction={orderBy === headCell.id ? order : "asc"}
                    onClick={createSortHandler(headCell.id)}
                  >
                    <span style={{ fontSize: "12px", fontWeight: 400 }}>
                      {headCell.label}
                    </span>
                  </TableSortLabel>
                ) : (
                  <span style={{ fontSize: "12px", fontWeight: 400 }}>
                    {headCell.label}
                  </span>
                )}
                <div className="">
                  <BsThreeDotsVertical
                    style={{ fontSize: "14px", cursor: "pointer" }}
                    id={`dropdownMenu${index}`}
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  />
                  <ul
                    className="dropdown-menu"
                    aria-labelledby={`dropdownMenu${index}`}
                  >
                    <li
                      className="dropdown-item"
                      onClick={() => handleColumnToggle(headCell.id)}
                    >
                      {isFrozen ? "Unfreeze" : "Freeze"}
                    </li>
                    <li
                      className="dropdown-item"
                      onClick={() => handleColumnDelete(headCell.id)}
                    >
                      Delete
                    </li>
                  </ul>
                </div>
              </div>
            </TableCell>
          );
        })}
        <TableCell>
          <BiPlusCircle style={{ fontSize: "18px", color: "#969696" }} />
        </TableCell>
      </TableRow>
    </TableHead>
  );
};

TableHeader.propTypes = {
  onRequestSort: PropTypes.func,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

export default TableHeader;

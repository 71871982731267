import React, { useState, useEffect } from "react";
import { Formik, Form, Field } from "formik";
import InputTextField from "../TextField";
import DescriptionField from "../TextField/descriptionField";

import Button from "@mui/material/Button";
import Header from "../Header";
import { departmentList } from "../../../utils/constant";
import {
  departmentInitialValue,
  formateData,
  validationSchema,
} from "./helper";
import { useLocation, useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { useDispatch, useSelector } from "react-redux";
import {
  addDepartmentRequest,
  updateDepartmentRequest,
} from "../../../Store/Department/actions";
import SideNavone from "../../../SideNavBar";
import AppLayout from "../../Layout/AppLayout";
const AddDepartment = () => {
  const location = useLocation();

  const history = useHistory();
  const dispatch = useDispatch();
  const action =
    location &&
    location?.state &&
    location?.state?.data &&
    location?.state?.data?.action;
  const editData =
    location &&
    location?.state &&
    location?.state?.data &&
    location?.state?.data?.data;
  const busienssId =
    location &&
    location?.state &&
    location?.state?.data &&
    location?.state?.data?.data;
  const queryParams = new URLSearchParams(location.search);
  const businessId = queryParams.get("id");
  const flag = true;
  const [loading, setLoading] = useState(false);
  const department = JSON.parse(localStorage.getItem("businessRecord"));
  const creatDepartment = (values) => {
    // setLoading(true);
    const data = formateData(values, department.id, userID);

    const jsonData = JSON.stringify(data);
    dispatch(
      addDepartmentRequest({
        data,
        setLoading,
        department,
      })
    ); //
    // addDepartment(jsonData).then((res) => {
    //   setLoading(false);
    //   if (res.code === 0) {
    //     success(departmentList.DEPARTMENT_CREATE);
    //     window.location = "/department";
    //   } else {
    //     error(res.message);
    //   }
    // });
  };

  const updateDepartment = (values) => {
    let id = editData.id;
    // setLoading(true);
    const data = formateData(values);
    // const jsonData = JSON.stringify(data);
    // editDepartment(jsonData, id).then((res) => {
    //   setLoading(false);
    //   if (res.code === 0) {
    //     success(departmentList.DEPARTMENT_UPDATED);
    //     window.location = "/department";
    //   } else {
    //     error(res.message);
    //   }
    // });
    dispatch(
      updateDepartmentRequest({
        id,
        data,
        setLoading,
      })
    );
  };
  var userID = useSelector(
    (state) => state.Auth.userloginsuccessyasir.id
  );
  const submitForm = (values) => {
    if (action === "edit") {
      updateDepartment(values);
    } else {
      creatDepartment(values);
    }
  };
  const callBackHandler = () => {
    history.goBack('/department');
  };

  return (
    <>
      <AppLayout
        name="Update Department"
        middle={
          <>
            <div
              className="position-relative ">
              <div
                style={{
                  position: "absolute",
                  top: "7px",
                  left: "-35px",
                }}
                onClick={callBackHandler}
                role="button"
              >
                <i id="icon_left_color" className="fas fa-chevron-left"></i>
              </div>
              <div className="">
                <Formik
                  initialValues={departmentInitialValue(action, editData, userID)}
                  onSubmit={(values) => {
                    submitForm(values);
                  }}
                  validationSchema={validationSchema}
                >
                  {(options) => {
                    const { handleSubmit, values, touched, errors, handleChange } =
                      options;
                    return (
                      <Form onSubmit={handleSubmit}>
                        <div className="col-md-12  px-2 pb-2 mt-1 ">
                          <label className="label_text_department">Business</label>
                          {action === "edit" ? (
                            <div>
                              <span className="business_name">
                                {editData?.business?.name}
                              </span>
                            </div>
                          ) : (

                            <div>
                              <span className="business_name">
                                {department?.name}
                              </span>
                            </div>
                          )}
                        </div>
                        <div className="col-md-12 mb-2 ">

                          <div className="input-group">
                            <span className="input-label text-light-grey">
                              Department Name
                            </span>
                            <input
                              className="input custom-border"
                              label="Department Name"
                              name="name"
                              placeholder="Department"
                              //size={20}
                              value={values.name}
                              touched={touched.name}
                              error={touched.name && errors.name}
                              onChange={handleChange}
                              variant="filled"
                            />
                          </div>
                        </div>
                        {errors.name && (
                          <span className="text-danger f-12">
                            {errors.name}
                          </span>
                        )}

                        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
                          <label className="label_text_business">
                            Department Description
                          </label>
                          <textarea
                            className="form-control1 input_style_fix"
                            id="description"
                            label="Description"
                            name="description"
                            placeholder="Type Description Here..."
                            //size={20}
                            variant="filled"
                            value={values.description}
                            onChange={handleChange}
                            autoFocused
                            touched={touched.description}
                            rows={3}
                            error={touched.description && errors.description}
                          // onChange={(event) => setBusinessDes(event.target.value)}
                          ></textarea>
                        </div>
                        {errors.description && (
                          <span className="text-danger f-12">
                            {errors.description}
                          </span>
                        )}
                        <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
                          <div className="p-2">
                            <Button
                              sx={{
                                width: "150px",
                                backgroundColor: "#edf0f5",
                                height: "40px",
                                color: "#b6bec8",
                                borderRadius: "10px",
                                boxShadow: "none",
                                textTransform: "capitalize",
                                "&:hover": {
                                  backgroundColor: "#c0c0c0",
                                  color: "#ffffff",
                                },
                              }}
                              onClick={() => {
                                window.location = "/department";
                              }}
                              variant="contained"
                            >
                              Cancel
                            </Button>
                          </div>
                          <div className="p-2">
                            <Button
                              type="submit"
                              sx={{
                                width: "150px",
                                backgroundColor: "#FFD705",
                                height: "40px",
                                borderRadius: "10px",
                                boxShadow: "none",
                                textTransform: "capitalize",
                              }}
                              onClick={handleSubmit}
                              variant="contained"
                              disabled={loading}

                            >
                              {loading ? (
                                <ClipLoader color="#fffff" size={30} />
                              ) : action === "edit" ? (
                                "Update"
                              ) : (
                                "Create"
                              )}{" "}
                            </Button>
                          </div>
                        </div>
                      </Form>
                    );
                  }}
                </Formik>
              </div>
            </div>
          </>
        }
      />

      {/* <Header
          flag={flag}
          heading={
            action === "edit"
              ? "Update Department"
              : departmentList.CREATE_DEPARTMENT
          }
        /> */}




    </>
  );
};

export default AddDepartment;

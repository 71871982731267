import React, { useEffect, useState } from "react";
import SideNavone from "../../../../SideNavBar";
// import "./AddBusiness.scss";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import { validateForm } from "../helper";
import { useDispatch, useSelector } from "react-redux";
import Dropzone from "../../Uploader";
import { validationSchema } from "../../Business/helper";
import OwnerDetail from "../OwnerDetail/OwnerDetail";
import { Button, IconButton } from "@mui/material";
import {
  fetchCountryRequest,
  fetchIndustryRequest,
  fetchTypeRequest,
} from "../../../../Store/Business/actions";
import { fetchBusinessTypeBinder } from "../../../../Store/Business/saga";
import { ClipLoader } from "react-spinners";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import useCheckPermission, { customSnackBar, isBusinessRolePermissionAllowed } from "../../../../utils";
import AppLayout from "../../../Layout/AppLayout";

const AddBusinessInfo = (props) => {
  const {
    values,
    errors,
    onSubmit,
    touched,
    setFieldValue,
    handleSubmit,
    handleChange,
    action,
    loading,
    loader,
    isMobile,
    userInfo,
  } = props;
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  var countryList = useSelector((state) => state?.Business?.countryList);
  var typeList = useSelector((state) => state?.Business?.businessTypeList);
  var industryList = useSelector((state) => state?.Business?.industryList);
  const [isChecked, setIsChecked] = useState(false);
  const [permission, setPermissions] = useState(null);
  
  useEffect(() => {
    dispatch(fetchCountryRequest());
    dispatch(fetchIndustryRequest());
    dispatch(fetchTypeRequest());
  }, []);
  const result = useCheckPermission("Business", "create").then((res) => {
    setPermissions(res);
  });
  useEffect(() => {
    if (
      permission === false &&
      location.pathname.includes("/add-new-business")
    ) {
      customSnackBar("You have not a Permssion To Add Business");
    }
  }, [permission]);

  return (
    <AppLayout
      name="Create New Business"
      middle={
        <div className="">
          <div className="row">
            {isMobile ? (
              <div className={`${!isMobile ? "d-none " : " col-4"}`}>
                <IconButton
                  onClick={() => {
                    history.goBack();
                  }}
                  size="small"
                  sx={{ mr: 5 }}
                  // className="mb-3"
                >
                  <KeyboardBackspaceIcon
                    sx={{ fontSize: 24, color: "#AAAAAA" }}
                  />
                </IconButton>
              </div>
            ) : null}
            <div
              className={`${
                !isMobile ? "col-md-12  px-2 py-1 text-center " : " col-4 "
              }`}>
              <Dropzone
                name="image"
                upload={values.image}
                setFieldValue={setFieldValue}
                actionState={action}
                touched={touched.image}
                errors={errors.image}
                validationSchema={validationSchema}
              />
            </div>
          </div>
          <div className="d-flex mb-2 mt-3 ">
            <span className=" sub_text_color_account_alltext1 ps-1">
              Organization Details
            </span>
          </div>

          <div className="row mb-2">
            <div className="col-md-6 pe-2">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "10px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Organization Name
                </label>
                <input
                  className="profile_input_fields p-0"
                  type="text"
                  id="business-name"
                  label="Organization Name"
                  name="businessName"
                  value={values.businessName}
                  onChange={handleChange}
                  error={touched.businessName && errors.businessName}
                  placeholder="Enter Organization Name"
                />
              </div>
              {errors?.businessName && (
                <span className="text-danger f-12">{errors.businessName}</span>
              )}
            </div>

            <div className="col-md-6 ps-2">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  // gap: "15px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Organization Type
                </label>
                <select
                  type="text"
                  className="form-control1 changecolor  m-0  p-0 "
                  id="businessType"
                  name="businessType"
                  value={values.businessType}
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "0",
                    margin: "0",
                    height: "31px",
                  }}
                  onChange={(e) =>
                    setFieldValue("businessType", e.target.value)
                  }>
                  <option disabled>Select Organization Type</option>
                  {typeList &&
                    typeList?.map((v, i) => {
                      return (
                        <option key={i} value={v?.id}>
                          {v?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {errors.businessType && (
              <span className="text-danger f-12">{errors.businessType}</span>
            )}
          </div>
          <div className="row mb-2">
            <div className="col-md-6 ">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  // gap: "15px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Industry Type
                </label>
                <select
                  type="text"
                  className="custom-dropdown changecolor m-0  p-0 "
                  id="industry"
                  name="industry"
                  value={values.industry}
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "0",
                    margin: "0",
                    height: "31px",
                  }}
                  onChange={(e) => setFieldValue("industry", e.target.value)}>
                  <option className="text-black-50" disabled>
                    Select Industry Type
                  </option>
                  {industryList &&
                    industryList?.map((v, i) => {
                      return (
                        <option key={i} value={v?.id}>
                          {v?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
            {errors.industry && (
              <span className="text-danger f-12">{errors.industry}</span>
            )}

            <div className="col-md-6 ps-2">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  // gap: "15px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Counrty ID
                </label>
                <select
                  type="text"
                  name="countryId"
                  className="form-control1 changecolor m-0  p-0 "
                  id="countryId"
                  value={values.countryId}
                  style={{
                    border: "none",
                    outline: "none",
                    padding: "0",
                    margin: "0",
                    height: "31px",
                  }}
                  onChange={(e) => setFieldValue("countryId", e.target.value)}>
                  <option value={""}>Select Country ID</option>
                  {countryList &&
                    countryList?.map((v, i) => {
                      return (
                        <option key={i} value={v?.id}>
                          {v?.name}
                        </option>
                      );
                    })}
                </select>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-12 ">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "10px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Website
                </label>
                <input
                  className="form-control1 input_style_fix"
                  type="text"
                  id="website"
                  label="Website"
                  name="website"
                  value={values.website}
                  onChange={handleChange}
                  placeholder="Enter URL"
                  error={touched.website && errors.website}
                />
              </div>
              {errors.website && (
                <span className="text-danger f-12">{errors.website}</span>
              )}
            </div>
          </div>
          {/* <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
        <label className="label_text_business">Organization Description</label>
        <textarea
          className="form-control1 input_style_fix"
          rows={3}
          type="text"
          id="description"
          label="Description"
          name="description"
          onChange={handleChange}
          placeholder="Type Description Here..."
          // //size={20}
          value={values.description}
          error={touched.description && errors.description}
          // onChange={(event) => setBusinessDes(event.target.value)}
        ></textarea>
      </div> */}

          {loader ? (
            <ClipLoader color="#b2b2b2" size={30} />
          ) : (
            <OwnerDetail
              userInfo={userInfo}
              values={values}
              isMobile={isMobile}
              errors={errors}
              handleChange={handleChange}
              setFieldValue={setFieldValue}
              action={action}
              onSubmit={onSubmit}
            />
          )}
          <div className="d-flex pt-1 flex-wrap">
            <label
              style={{
                fontSize: "13px",
                display: "flex",
                flexDirection: "row",
              }}>
              <input
                className="form-check-input"

                type="checkbox"
                // value={{}}
                checked={isChecked}
                // className="lager"
                onChange={() => setIsChecked(!isChecked)}
                // disabled={weekday === "Sunday"}
                style={{
                  marginBottom: "20px",
                  marginRight: "10px",
                  marginTop: "3px",
                }}
              />
              I agree to the{" "}
              {/* <span className="bold"> Term of the Service</span> */}
              <p
                className="bold"
                role="button"
                onClick={() => history.push("/terms")}>
                Terms & Conditions
              </p>
            </label>
          </div>
          <div
            className={`${
              !isMobile
                ? " w-100 d-flex mt-1 flex-wrap justify-content-end "
                : " w-100 mt-3 "
            }`}>
            <div className={`${!isMobile ? "pe-2 " : "d-none"}`}>
              <Button
                sx={{
                  width: "150px",
                  backgroundColor: "#edf0f5",
                  height: "40px",
                  color: "#b6bec8",
                  borderRadius: "10px",
                boxShadow:"none",

                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#c0c0c0",
                    color: "#ffffff",
                boxShadow:"none",

                  },
                }}
                onClick={() => {
                  history.push("/business/");
                }}
                variant="contained"
                className=" btn btn-secondary padding_next_secondary_button">
                {"Cancel"}
              </Button>
            </div>

            <div>
              <Button
                sx={{
                  width: isMobile ? "100%" : "150px",
                  backgroundColor: "#FFD705",
                  height: "40px",
                  borderRadius: "10px",
                  boxShadow:"none",
                  textTransform: "capitalize",
                  "&:hover": {
                    backgroundColor: "#FFD705",
                    color: "white",
                  boxShadow:"none",

                  },

                }}
                className="padding_next_primary_button"
                onClick={() => {
                  handleSubmit();
                }}
                variant="contained"
                disabled={loading || !isChecked}>
                {loading ? <ClipLoader color="#fffff" size={30} /> : "Next"}
              </Button>
            </div>
          </div>
        </div>
      }
    />
  );
};

export default AddBusinessInfo;

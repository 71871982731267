import React, { useState, useCallback, useEffect } from "react";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import { businessMange } from "./helper";
import TableHeader from "../TableHeader";
import TableRows from "./TableRow";
import Skeleton from "@mui/material/Skeleton";
import { businessList } from "../../../utils/constant";
import DialogBox from "../DialogBox";
import { useDispatch } from "react-redux";
import { deleteBusinessRequest } from "../../../Store/Business/actions";
import { Spin } from "antd";
import { permissions } from "../User/helper";

const TableData = (props) => {
  const { data, loading, record, setRecord,permission } = props;
  const dispatch = useDispatch();
  const [rowData, setRowData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("CreatedAt");
  const [selectedCheckboxes, setSelectedCheckboxes] = useState([]);
  const [dialog, setDialog] = useState({ visible: false });
  const action = "edit";
  const [frozenColumns, setFrozenColumns] = useState([]); // Track frozen columns
  const [deletedColumns, setDeletedColumns] = useState([]); // Track deleted columns
  const [column,setColumn]=useState([])
  useEffect(() => {
    setRowData(data);
    // searchBinding(setSearchBar, setRowData, blogs);
  }, [data]);
  //Use Effect To set RowData on searching
  useEffect(() => {
    setPage(0);
  }, [data]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value, 10);
    setPage(0);
  };
  const [drawerToggle, setDrawerToggle] = useState(false);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [activeColumnId, setActiveColumnId] = useState(null); // New state to track active column

  const handleClick = (event, columnId) => {
    setActiveColumnId(columnId); // Set the active column for which the dropdown was clicked
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const toggleColumnVisibility = (columnId) => {
    setHiddenColumns((prevHiddenColumns) =>
      prevHiddenColumns.includes(columnId)
        ? prevHiddenColumns.filter((col) => col !== columnId)
        : [...prevHiddenColumns, columnId]
    );
    handleClose();
  };

  const toggleColumnFreeze = (columnId) => {
    setFrozenColumns((prevFrozenColumns) =>
      prevFrozenColumns.includes(columnId)
        ? prevFrozenColumns.filter((col) => col !== columnId)
        : [...prevFrozenColumns, columnId]
    );
    handleClose();
  };

  const hideAllColumns = () => {
    setHiddenColumns(businessMange.map((column) => column.id));
    handleClose();
  };

  const showAllColumns = () => {
    setHiddenColumns([]);
    handleClose();
  };
  const toggleDrawerHandler = useCallback(() => {
    setDrawerToggle(!drawerToggle);
  }, [drawerToggle]);
  const handleHeaderCheckboxChange = (event) => {
    if (event.target.checked) {
      const newSelectedCheckboxes = data.map((item) => item.name);
      setSelectedCheckboxes(newSelectedCheckboxes);
    } else {
      setSelectedCheckboxes([]);
    }
  };

 
  const handleRowCheckboxChange = (event, id) => {
    if (event.target.checked) {
      setSelectedCheckboxes((prevSelectedCheckboxes) => [
        ...prevSelectedCheckboxes,
        id,
      ]);
    } else {
      setSelectedCheckboxes((prevSelectedCheckboxes) =>
        prevSelectedCheckboxes.filter((checkboxId) => checkboxId !== id)
      );
    }
  };
  const handleRequestSort = useCallback(
    (event, property) => {
      const isAsc = orderBy === property && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(property);
    },
    [order, orderBy]
  );
 
  const removeBusiness = (id) => {
    dispatch(
      deleteBusinessRequest({
        id,
        setRecord,
        data,
      })
    );
  };
  //Delete busienss Confirmation Dialouge Box handler
  const handleBusinessDeleteConfirmation = (id) => {
    // setDialog({
    //   visible: true,
    //   key: Math.random().toString(36).substring(7),
    //   ok: () => removeBusiness(id),
    // });
  };
  return (
    // <Paper sx={{ width: "100%", overflow: "hidden" }}>
    <>
      <DialogBox
        {...dialog}
        header={businessList.DELETE_BUSINESS}
        message={businessList.WANT_TO_DELETE_BUSINESS}
      />
      <TableContainer
        className="hide_scrollbar"
        sx={{
          overflow:'auto',
          maxHeight: 1000,
          borderRadius: "8px",
          border: "1px solid #DCDCDC",
          backgroundColor: "#fff",
          // boxShadow: "3px 5px 0px #c6c5c5",
        }}
      >
        <Table size="small" stickyHeader aria-label="sticky table">
          {loading ? (
            <Skeleton
              animation="wave"
              height={66}
              width="100%"
              style={{ marginTop: "-7px" }}
            />
          ) : (
            <TableHeader
            column={businessMange}
            onRequestSort={handleRequestSort}
            orderBy={orderBy}
            order={order}
            selectedCheckboxes={selectedCheckboxes}
            handleHeaderCheckboxChange={handleHeaderCheckboxChange}
            rowCount={data.length}  
            handleClick={handleClick}  
            toggleColumnFreeze={toggleColumnFreeze}    
            toggleColumnVisibility={toggleColumnVisibility} 
          />
          )}
          {loading ? (
            <div className="center-loader">
                  <Spin/>
                </div>
          ) : (
            <TableBody>
              {loading ? (
                <div className="center-loader">
                  <Spin/>
                </div>
             
              ) : (
                <TableRows
                permission={permission}
                  rowData={rowData}
                  action={action}
                  record={record}
                  page={page}
                  rowsPerPage={rowsPerPage}
                  loading={loading}
                  selectedCheckboxes={selectedCheckboxes}
                  handleRowCheckboxChange={handleRowCheckboxChange}
                  toggleDrawerHandler={toggleDrawerHandler}
                  deleteBusiness={handleBusinessDeleteConfirmation}
                />
              )}
            </TableBody>
          )}
        </Table>
      </TableContainer>
      {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25, 100]}
          component="div"
          count={rowData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
    </>
  );
};
export default TableData;

export const FETCH_BUSINESS_REQUEST = 'FETCH_BUSINESS_REQUEST';
export const FETCH_BUSINESS_SUCCESS = 'FETCH_BUSINESS_SUCCESS';
export const FETCH_BUSINESS_FAILURE = 'FETCH_BUSINESS_FAILURE';
export const ADD_SELECTED_BUSINESS = 'ADD_SELECTED_BUSINESS';
export const ADD_SELECTED_BUSINESS_SUCCESS = 'ADD_SELECTED_BUSINESS_SUCCESS';
export const SET_URL = "SET_URL";

export const ADD_ADDRESS_BUSINESS = 'ADD_ADDRESS_BUSINESS';
export const ADD_BUSINESS_REQUEST = 'ADD_BUSINESS_REQUEST';
export const ADD_BUSINESS_SUCCESS = 'ADD_BUSINESS_SUCCESS';
export const ADD_BUSINESS_FAILURE = 'ADD_BUSINESS_FAILURE';
export const UPDATE_BUSINESS_REQUEST = 'UPDATE_BUSINESS_REQUEST';
export const UPDATE_BUSINESS_SUCCESS = 'UPDATE_BUSINESS_SUCCESS';
export const UPDATE_BUSINESS_FAILURE = 'UPDATE_BUSINESS_FAILURE';
export const DELETE_BUSINESS_REQUEST = 'DELETE_BUSINESS_REQUEST';
export const DELETE_BUSINESS_SUCCESS = 'DELETE_BUSINESS_SUCCESS';
export const DELETE_BUSINESS_FAILURE = 'DELETE_BUSINESS_FAILURE';
export const FETCH_BUSINESSTYPE_REQUEST = 'FETCH_BUSINESSTYPE_REQUEST';
export const FETCH_BUSINESSTYPE_SUCCESS = 'FETCH_BUSINESSTYPE_SUCCESS';
export const FETCH_BUSINESSTYPE_FAILURE = 'FETCH_BUSINESSTYPE_FAILURE';
export const FETCH_INDUSTRY_REQUEST = 'FETCH_INDUSTRY_REQUEST';
export const FETCH_INDUSTRY_SUCCESS = 'FETCH_INDUSTRY_SUCCESS';
export const FETCH_INDUSTRY_FAILURE = 'FETCH_INDUSTRY_FAILURE';
export const FETCH_COUNTRY_REQUEST= 'FETCH_COUNTRY_REQUEST';
export const FETCH_COUNTRY_SUCCESS= 'FETCH_COUNTRY_SUCCESS';
export const ADD_BUSINESS_ADDRESS_REQUEST = 'ADD_BUSINESS_ADDRESS_REQUEST';
export const ADD_BUSINESS_ADDRESS_SUCCESS = 'ADD_BUSINESS_ADDRESS_SUCCESS';
export const ADD_BUSINESS_ADDRESS_FAILURE = 'ADD_BUSINESS_ADDRESS_FAILURE';
export const GET_BUSINESS_REQUEST = 'GET_BUSINESS_REQUEST';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_FAILURE = 'GET_BUSINESS_FAILURE';
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/bootstrap.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { getBusinessRequest } from "../../../../Store/Business/actions";
import { ClipLoader } from "react-spinners";
import CardLink from "./cardsLink";
import AppLayout from "../../../Layout/AppLayout";
import {
  customSnackBar,
  isBusinessRolePermissionAllowed,
} from "../../../../utils";
import { updateBusinessSuccess } from "../../../../Store/Business/actions";
import bottom from "../../../../Assets/Images/arrorbottommm.png";
import CustomPhoneInput from "./CustomPhoneInput";
import upload from "../../../../Assets/Images/uploadImg.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Avatar } from "@mui/material";
import instance from "../../../../utils/utils";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import UserDetail from "./userDetail";
import { Spin } from "antd";

const BusinessProfile = () => {
  const { id } = useParams();
  const userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const [viewPermssion, setViewPermissions] = useState(null);

  const permissionsToCheck = [
    "canEditBusinessSchedule",
    "canViewBusinessDepartment",
    "canUpdateBusinessProfile",
  ];

  let permissions = useSelector((state) => state?.Auth?.permissionsModule);
  useEffect(() => {
    const fetchPermissions = async () => {
      const fetchedPermissions = {};
      for (const permission of permissionsToCheck) {
        const result = await isBusinessRolePermissionAllowed(
          permissions,
          "Business Roles & Permissions",
          permission
        );
        fetchedPermissions[permission] = result;
      }
      setViewPermissions(fetchedPermissions);
    };

    if (permissions) {
      fetchPermissions();
    } else {
    }
  }, [permissions]);
  const dispatch = useDispatch();
  const businesProfile = useSelector((state) => state.Business.businesProfile);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (id) {
      dispatch(getBusinessRequest({ id, setLoading }));
    }
  }, [id]);

  return (
    <>
      <>
        <AppLayout
          name="Business Details"
          middle={
            <>
              {loading && businesProfile === null ? (
                <div
                  style={{
                    position: "fixed",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                  }}>
                  <Spin  />
                </div>
              ) : businesProfile ? (
                <UserDetail
                  permission={viewPermssion}
                  businesProfile={businesProfile}
                />
              ) : (
                <Spin  />
              )}
            </>
          }
        />
      </>
    </>
  );
};
export default BusinessProfile;

import React, { useCallback, useState } from "react";
import Actions from "./Actions";
import Avatar from "@mui/material/Avatar";
import { customSnackBar, DataNotFound } from "../../../utils";
import TableCell from "@mui/material/TableCell";
import { deepPurple } from "@mui/material/colors";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import { Link, Menu, MenuItem } from "@mui/material";
import { useHistory } from "react-router-dom";
import AddUserOrDepartmentDialog from "../DialogBox/dialoguebox";
import { BsThreeDotsVertical } from "react-icons/bs";
import ActionMenu from "./ActionMenu";
const TableRows = (props) => {
  const {
    rowData,
    action,
    toggleDrawerHandler,
    selectedCheckboxes,
    handleRowCheckboxChange,
    deleteBusiness,
    rowsPerPage,
    deletedColumns,
    frozenColumns,
    visibleColumns,
    page,
    permission
  } = props;
  const history = useHistory();
  const tableData = rowData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const handleClick = (row) => {
    localStorage.setItem("businessRecord", JSON.stringify(row));
    history.push("/department");
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const handleClicks = useCallback(
    (event) => {
      setAnchorEl(event.currentTarget);
    },
    [anchorEl]
  );
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null); // Add state for selected row

  const handleDialogOpen = (row) => {
    setSelectedRow(row); // Set the selected row
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedRow(null); // Reset selected row when closing the dialog
    setDialogOpen(false);
  };
  const handleClose = useCallback(() => {
    setAnchorEl(null);
  }, [anchorEl]);

  const handleAddUser = (row) => {
    if(permission === null ||
      permission?.canViewBusinessUsers ||
      permission?.canViewBusinessUsers === null ||
      permission?.canViewBusinessUsers === undefined){
    localStorage.setItem("businessRecord", JSON.stringify(row));
    
    history.push(`/users`,row);
    // handleDialogClose();
      }
      else{
        customSnackBar("You haven't permission to view the Users")
      }
  };
  const handleProfile= (row) => {
    if(permission === null ||
      permission?.canUpdateBusinessProfile ||
      permission?.canUpdateBusinessProfile === null ||
      permission?.canUpdateBusinessProfile === undefined ){
    localStorage.setItem("businessRecord", JSON.stringify(row));
    
    history.push(`/business-profile/${row.id}`);
      }else{
        customSnackBar("You haven't permission to view the Profile")
      }
    // handleDialogClose();
  };

  const handleAddDepartment = (row) => {
    if(permission === null ||
      permission?.canViewBusinessDepartment ||
      permission?.canViewBusinessDepartment === null ||
      permission?.canViewBusinessDepartment === undefined ){
    localStorage.setItem("businessRecord", JSON.stringify(row));

    history.push(`/department`,row);
      }
      else{
        customSnackBar("You haven't permission to view Department")
      }
    // handleDialogClose();
  };

  const handleContentPage = (row) => {
    // if(permission === null ||
    //   permission?.canViewBusinessDepartment ||
    //   permission?.canViewBusinessDepartment === null ||
    //   permission?.canViewBusinessDepartment === undefined ){
    localStorage.setItem("businessRecord", JSON.stringify(row));

    history.push(`/content-page`,row);
      // }
      // else{
      //   customSnackBar("You haven't permission to view Department")
      // }
    // handleDialogClose();
  };

  return (
    <>
      {rowData.length > 0 ? (
        rowData?.map((row, index) => {
          return (
            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
              <TableCell
                style={{
                  border: "1px solid #ededed",
                  position: "sticky",
                  left: "auto",
                }}
                padding="checkbox"
              >
                <ActionMenu
                permission={permission}
                handleAddUser={handleAddUser}
                handleAddDepartment={handleAddDepartment}
                handleProfile={handleProfile}
                handleContentPage={handleContentPage}
                 row={row}
                index={index}
                action={action}
                toggleDrawerHandler={toggleDrawerHandler}
                />
               
                {/* <input
                  type="checkbox"
                  value=""
                  id="flexCheckDefault"
                  className="form-check-input2 checkbox_btn2"
                  checked={selectedCheckboxes.includes(row.name)}
                  onChange={(event) => handleRowCheckboxChange(event, row.name)}
                /> */}
              </TableCell>{" "}
              <TableCell style={{ border: "1px solid #ededed" }}>
                <div className="">
                  <div
                    style={{ cursor: "pointer" }}
                    className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple"
                  >
                    {row && row.imageUrl ? (
                      <Avatar
                        // src="/images/profile.png"
                        src={row.imageUrl}
                  // src={`${process.env.REACT_APP_IMAGE_URL}/Business/${row.id}_0.jpeg`}
                  // src={row.imageUrl}
                        alt={"not-found"}
                        variant="rounded"
                        className=""
                        style={{
                          width: "35px",
                          height: "35px",
                          borderRadius: "100px",
                          // marginRight: 0,
                        }}
                      />
                    ) : (
                      <div style={{ cursor: "pointer" }} className="">
                        <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                          <Avatar
                            src="/images/profile.png"
                            sx={{
                              bgcolor: deepPurple[500],
                              width: "40px",
                              height: "40px",
                              borderRadius: "100px",
                              marginRight: 2,
                            }}
                          >
                            <span>NP</span>
                          </Avatar>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell style={{ border: "1px solid #ededed" }}>
                <Link
                  underline="none"
                  // onClick={() => {
                  //   handleDialogOpen(row);
                  // }}
                  // onClick={() => {
                  //   handleClick(row);
                  // }}
                >
                  <div className="d-flex align-items-center">
                    <div>
                      {row.name ? (
                        <div
                          style={{ cursor: "pointer" }}
                          className=" text-capitalize "
                        >
                          <span className="bolds">{row.name}</span>
                        </div>
                      ) : (
                        "UserName"
                      )}
                    </div>
                  </div>
                </Link>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-left"
              >
                <span className="bolds">{row.id}</span>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-left"
              >
                <span className="bolds">{row.designation}</span>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-left"
              >
                <span style={{ textTransform: "capitalize" }} className="bolds">
                  {row.businessAddress.addressName}
                </span>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor:
                    row.status &&
                    (row.status.toLowerCase() === "inactive"
                      ? "#e9b67a"
                      : row.status.toLowerCase() === "active"
                      ? "#b8d693"
                      : row.status.toLowerCase() === "suspended"
                      ? "#cc7474"
                      : "transparent"),

                  border: "1px solid #ededed",
                }}
                className="text-center"
              >
                <span className="statusText">
                  {" "}
                  {/* {row.status} */}
                  {row.status &&
                    row.status.charAt(0).toUpperCase() + row.status.slice(1)}
                </span>
              </TableCell>
              {/* <div className="d-flex justify-content-between"> */}
              <TableCell
                style={{ border: "1px solid #ededed" }}
                // className="text-right"
                id={"action-" + index}
              >
                <div className="d-flex justify-content-between">
                  <span style={{ fontSize: "14px" }} className="switch">
                    Switch
                  </span>
                  <div className="text-end">
                    {" "}
                    <Actions
                      row={row}
                      index={index}
                      action={action}
                      toggleDrawerHandler={toggleDrawerHandler}
                      deleteBusiness={deleteBusiness}
                    />
                  </div>
                </div>
              </TableCell>
              {/* </div> */}
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <DataNotFound />{" "}
          </TableCell>
        </TableRow>
      )}
      {/* <AddUserOrDepartmentDialog
        open={dialogOpen}
        handleClose={handleDialogClose}
        handleAddUser={handleAddUser}
        handleAddDepartment={handleAddDepartment}
      /> */}
    </>
  );
};
export default TableRows;

// import React, { useEffect, useState } from "react";
// import AppLayout from "../../Layout/AppLayout";
// import instance from "../../../utils/utils";
// import { useParams } from "react-router-dom/cjs/react-router-dom.min";

// const ViewTempalte = () => {
//   const [pageSections, setpageSections] = useState([]); // State to store fetched sections
//   const [loader, setLoader] = useState(false); // Loader state
//   const businessRecrord = JSON.parse(localStorage.getItem("businessRecord"));
//   const { id } = useParams();
//   const fetchCreatedPages = async () => {
//     try {
//       setLoader(true); // Set loader to true before API call
//       const response = await instance.get(
//         `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page-service/page-content/by/service/?service_name=Business&entity_id=${businessRecrord?.id}&page_id=${id}&is_mobile=false&entity_type=BUSINESS&is_admin=true`
//       );

//       console.log(response, "response");

//       // Check for successful response
//       if (response?.data?.code === 0) {
//         const result = response?.data?.result || [];
//         setpageSections(result); // Save the result to state
//       } else {
//         console.error("Unexpected response code:", response?.data?.code);
//       }
//     } catch (error) {
//       console.error("Error fetching section data:", error);
//     } finally {
//       setLoader(false); // Set loader to false after API call completes
//     }
//   };
//   useEffect(() => {
//     fetchCreatedPages();
//   }, []);
//   return <AppLayout name="Template View" allSpace={<div>ViewTempalte</div>} />;
// };

// export default ViewTempalte;
import React, { useEffect, useState } from "react";
import AppLayout from "../../Layout/AppLayout";
import instance from "../../../utils/utils";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Spin } from "antd";

const ViewTempalte = () => {
  const [pageSections, setPageSections] = useState([]); // State to store fetched sections
  const [loader, setLoader] = useState(false); // Loader state
  const businessRecord = JSON.parse(localStorage.getItem("businessRecord"));
  const { id } = useParams();

console.log(
    pageSections,"pageSections"
) 
 const fetchCreatedPages = async () => {
    try {
      setLoader(true); // Set loader to true before API call
      const response = await instance.get(
        `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page-service/page-content/by/service/?service_name=Business&entity_id=${businessRecord?.id}&page_id=${id}&is_mobile=false&entity_type=BUSINESS&is_admin=true`
      );

      console.log(response, "response");

      if (response?.data?.code === 0) {
        const result = response?.data?.result?.sections || [];
        setPageSections(result); // Save the sections to state
      } else {
        console.error("Unexpected response code:", response?.data?.code);
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
    } finally {
      setLoader(false); // Set loader to false after API call completes
    }
  };

  // Fetch sections on component mount
  useEffect(() => {
    if(id){
    fetchCreatedPages();
    }
  }, []);

  // Render sections based on response
  const renderSection = (section) => {
    console.log(section, "section");
  
    switch (section.section_name) {
      case "Image-Logo-Business Name-Summary":
        return (
          <div key={section.section_id} className="hero-section">
            {section?.data?.map((content) => (
              <div key={content.content_id}>
                {content.content_variation_name === "Heading" && (
                  <h1>{content.content}</h1>
                )}
                {content.content_type_name === "Image" && (
                  <img
                    src={content.content_attachment_url}
                    alt="Business Logo"
                  />
                )}
              </div>
            ))}
          </div>
        );
  
      case "Opening-Hours":
        return (
          <div key={section.section_id} className="opening-hours">
            <h3>Opening Hours</h3>
            {section.data.length > 0 ? (
              <table>
                <thead>
                  <tr>
                    <th>Day</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                  </tr>
                </thead>
                <tbody>
                  {section.data.map((item, index) => (
                    <tr key={index}>
                      <td>{item.day}</td>
                      <td>{item.start_time}</td>
                      <td>{item.end_time}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <p>No opening hours available.</p>
            )}
          </div>
        );
  
      case "Business-Gallery":
        return (
          <div key={section.section_id} className="business-gallery">
            <h3>Our Treatment Gallery</h3>
            <div className="gallery-grid">
              {section.data.map((content) => (
                <img
                  key={content.content_id}
                  src={content.content_attachment_url}
                  alt="Gallery Item"
                />
              ))}
            </div>
          </div>
        );
  
      case "Address":
        console.log("Case4")
        return (
          <div key={section.section_id} className="business-address">
            <h3>{section.section_description}</h3>
            <div>
              {section?.data?.map((content) => {
                switch (content.content_variation_name) {
                  case "Heading":
                    return (
                      <h1 key={content.content_id} style={{ marginBottom: "10px" }}>
                        {content.content}
                      </h1>
                    );
                  case "Pragraph":
        console.log("Pragraph")

                    return (
                      <p key={content.content_id} style={{ marginBottom: "5px" }}>
                        {content.content}
                      </p>
                    );
                  case "Image":
                    return (
                      <img
                        key={content.content_id}
                        src={content.content_attachment_url}
                        alt={`Content ${content.content_id}`}
                        style={{ maxWidth: "100%", marginBottom: "10px" }}
                      />
                    );
                  default:
                    return null;
                }
              })}
            </div>
          </div>
        );
  
      default:
        return null;
    }
  };
  

  return (
    <AppLayout
    isShow={true}
      name="Template View"
      allSpace={
        <div className="template-view">
          {loader ? (
           <div className="center-loader">
<Spin/>
           </div>
          ) : (
            pageSections.map((section) => renderSection(section))
          )}
        </div>
      }
    />
  );
};

export default ViewTempalte;

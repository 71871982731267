import React, { useCallback, useEffect, useRef, useState } from "react";
import InputTextField from "../../TextField";
import DescriptionField from "../../TextField/descriptionField";
import SideNavone from "../../../../SideNavBar";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  businessAddressInitialValue,
  formateUpdateBusinessAddress,
  formateUpdateData,
  validationSchemaOfBusinessAddress,
} from "../../Business/helper";
// import "../AddBusiness/AddBusiness.scss";
import {
  addBusinessAddressesRequest,
  updateBusinessRequest,
} from "../../../../Store/Business/actions";

import { formateData, formateDataUpdate, initialValues } from "../helper";
import { businessDatas } from "../../User/helper";
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"; // Import the date adapter for localization
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, IconButton, TextField } from "@mui/material";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Times from "./Times";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Info from "./info";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { customSnackBar } from "../../../../utils";
import instance from "../../../../utils/utils";
import { CrossSvdSchedule } from "../../../../Assets/svgs";
import InfoOpen from "./InfoOpen";

const Schdular = ({
  loader,
  loading,
  open,
  handleOpen,
  handleClose,
  actionState,
  editData,
  submitData,
  actions,
  isMobile,
}) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formRef = useRef();

  const history = useHistory();
  const location = useLocation();
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );


  return (
    <>
      {loader ? (
        <div
          style={{
            position: "fixed",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}>
          <ClipLoader color="#FFD705" />{" "}
        </div>
      ) : (
        <>
        <div
          className=" hide_scrollbar">
          <div className="row">
            {isMobile ? (
              <div className={`${!isMobile ? "d-none " : " col-4"}`}>
                <IconButton
                  onClick={() => {
                    history.push("/business/");
                  }}
                  size="small"
                  sx={{ mr: 5 }}
                  // className="mb-3"
                >
                  <KeyboardBackspaceIcon
                    sx={{ fontSize: 24, color: "#AAAAAA" }}
                  />
                </IconButton>
              </div>
            ) : null}
          </div>
          <Formik
            enableReinitialize
            innerRef={(f) => (formRef.current = f)}
            initialValues={initialValues(id, actions, actionState, editData)}
            // validationSchema={{}}
            onSubmit={(values) => {
              submitData(values);
            }}>
            {(options) => {
              const {
                touched,
                values,
                errors,
                setFieldValue,
                handleChange,
                handleSubmit,
                prevScheduleItems,
                validateForm,
              } = options;
              return (
                <Form onSubmit={handleSubmit}>
                  <InfoOpen
                  open={open}
                    // slotStates={slotStates}
                    // handleClickActive={handleClickActive}
                    {...options}
                    actionState={actionState}
                    isMobile={isMobile}
                    scheduleData={editData || []}
                    // scheduleData={scheduleData && scheduleData.data || []}
                  />{" "}
                  {/* <div
                    className={`${
                      !isMobile
                        ? " w-100 d-flex mt-4 flex-wrap justify-content-end "
                        : " w-100 mt-4"
                    }`}>
                    <div className={`${!isMobile ? "pe-2 " : "d-none"}`}>
                      <Button
                        sx={{
                          width: "150px",
                          backgroundColor: "#edf0f5",
                          height: "40px",
                          color: "#b6bec8",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#c0c0c0",
                            color: "#ffffff",
                          },
                        }}
                        onClick={() => {
                          if (actions === "new") {
                            history.push("/business/");
                          } else if (actions === "add-schdule") {
                            history.push(`/calendar`);
                          } else {
                            history.push(
                              `/business-profile/${selectedBusiness.id}`
                            );
                          }
                        }}
                        variant="contained"
                        className=" btn btn-secondary padding_next_secondary_button">
                        {"Cancel"}
                      </Button>
                    </div>
                   
                  </div> */}
                </Form>
              );
            }}
          </Formik>
        </div>

        <div className={`fade-background ${open ? 'active' : ''}`}></div>

      <div
        className={`card-grey-provider slide-in active
          `}
        style={{
          right: open ? "36%" : "-50%",
        }}>
        <div
          className="mt-3 cursor-pointer"
          style={{
            position: "absolute",
            left: "-68px",
            backgroundColor: "#F8FAFC",
            width: "50px",
            height: "50px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            // border: "1px sold #878787",

            boxShadow: "rgba(0, 0, 0, 0.58) 0px 4px 16px",
          }}>
          <div onClick={handleClose}>
            <CrossSvdSchedule />
          </div>
        </div>
        <div className="mt-4 ms-3">
            <span className="staff-text">Business Working Hours</span>
          </div>
        <div
          className=" hide_scrollbar  mt-2 "
          style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}>
          <div className="row">
            {isMobile ? (
              <div className={`${!isMobile ? "d-none " : " col-4"}`}>
                <IconButton
                  onClick={() => {
                    history.push("/business/");
                  }}
                  size="small"
                  sx={{ mr: 5 }}
                  // className="mb-3"
                >
                  <KeyboardBackspaceIcon
                    sx={{ fontSize: 24, color: "#AAAAAA" }}
                  />
                </IconButton>
              </div>
            ) : null}
          </div>
          <Formik
            // enableReinitialize
            innerRef={(f) => (formRef.current = f)}
            initialValues={initialValues(id, actions, actionState, editData)}
            // validationSchema={{}}
            onSubmit={(values) => {
              submitData(values);
            }}>
            {(options) => {
              const {
                touched,
                values,
                errors,
                setFieldValue,
                handleChange,
                handleSubmit,
                prevScheduleItems,
                validateForm,
              } = options;
              return (
                <Form onSubmit={handleSubmit}>
                  <Info
                  open={open}

                    // slotStates={slotStates}
                    // handleClickActive={handleClickActive}
                    {...options}
                    actionState={actionState}
                    isMobile={isMobile}
                    scheduleData={editData || []}
                    // scheduleData={scheduleData && scheduleData.data || []}
                  />{" "}
                  <div
                    className={`${
                      !isMobile
                        ? " w-100 d-flex mt-4 flex-wrap justify-content-end "
                        : " w-100 mt-4"
                    }`}>
                    <div className={`${!isMobile ? "pe-2 " : "d-none"}`}>
                      <Button
                        sx={{
                          width: "150px",
                          backgroundColor: "#edf0f5",
                          height: "40px",
                          color: "#b6bec8",
                          borderRadius: "10px",
                          textTransform: "capitalize",
                          "&:hover": {
                            backgroundColor: "#c0c0c0",
                            color: "#ffffff",
                          },
                        }}
                        onClick={() => {
                          handleClose()
                        }}
                        variant="contained"
                        className=" btn btn-secondary padding_next_secondary_button">
                        {"Cancel"}
                      </Button>
                    </div>
                    <div>
                      <Button
                       sx={{
                            width: "150px",
                            backgroundColor: "#FFD705",
                            height: "40px",
                            borderRadius: "10px",
                            textTransform: "capitalize",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "#FFD705",
                              boxShadow: "none",
                            },
                          }}
                        // sx={{
                        //   width: isMobile ? "100%" : "150px",
                        //   backgroundColor: "#FFD705",
                        //   height: "40px",
                        //   borderRadius: "10px",
                        //   textTransform: "capitalize",
                        // }}
                        className=""
                        onClick={() => {
                          handleSubmit();
                        }}
                        variant="contained"
                        disabled={loading}>
                        {loading ? (
                          <ClipLoader color="#fffff" size={30} />
                        ) : actionState === "new" ? (
                          !isMobile ? (
                            "Create"
                          ) : (
                            "Save & Continue"
                          )
                        ) : (
                          "Update"
                        )}{" "}
                      </Button>
                    </div>
                  </div>
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
      </>
      )}
    </>
  );
};

export default Schdular;

import React, { useRef } from "react";
import { Button, InputAdornment, Menu, MenuItem, TextField } from "@mui/material";
// import "./style.scss";
import dayjs from "dayjs";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import AccessTimeIcon from "@mui/icons-material/AccessTime"; // Clock icon
import { ArrowDropDownIcon } from "@mui/x-date-pickers";
import { AddScheduleSvg, RemoveScheduleSvg } from "../../../../Assets/svgs";

const Times = (props) => {
  const {
    handleAddTimeSlot,
    isMenuOpen,
    handleRemoveTimeSlot,
    setMenuOpen,
    scheduleIndex,
    setFieldValue,
    startTimeValues,
    startTimeTouched,
    startTimeErrors,
    endTimeValues,
    endTimeTouched,
    endTimeErrors,
    slot,
    records,
    handleChange,
    options,
    index,
    active,
    isMobile,
  } = props;
  const inputRef = useRef();

  const handleIconClick = () => {
    if (inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  };
  return (
    <>
      <div>
        <div
          // className={` ${
          //   startTimeValues && startTimeValues.length > 1 ? "" : ""
          // }`}
          key={index}>
          <div className="row">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <div className={`${!isMobile ? "col-md-6  " : "col-5"}`}>
                <TimePicker
                  id={`businessStartTime`}
                  name={`businessStartTime`}
                  format="HH:mm a"
                  // value={options.businessStartTime}
                  disabled={!options.isAvailable || active}
                  value={active ? "" : options.businessStartTime}
                  sx={{
                    // border: "0.5px solid #DCDCDC",
                    width: "100%",
                    minWidth: isMobile ? "70px" : "127px",
                    borderRadius: isMobile ? "10px" : "8px",
                    color: "#575757",
                    backgroundColor: isMobile
                      ? !active
                        ? "#E0EBFF"
                        : "#DCDCDC"
                      : !active
                      ? "#F3F6FF"
                      : "#ffff",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: isMobile ? "10px" : "8px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#DCDCDC",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#DCDCDC",
                        border: "0.5px solid #DCDCDC",
                      },
                    },
                    "& .MuiInputAdornment-root": {
                      marginLeft:"0px"
                      // display: "none", // Hide the adornment
                    },
                  }}
                  // onChange={handleChange}
                  onChange={(newValue) => {
                    setFieldValue(`${index}.businessStartTime`, newValue);
                  }}
                  clearButton
                  // disabled={active}
                  className="format"
                  // format="HH:mm a"
                  // disabled={day === 0}
                  helperText={
                    startTimeTouched &&
                    startTimeTouched[index] &&
                    startTimeErrors &&
                    startTimeErrors[index]
                  }
                  textColor={!active ? "#575757" : "#fff"}
                  // viewRenderers={{
                  //   hours: renderTimeViewClock,
                  //   minutes: renderTimeViewClock,
                  //   seconds: renderTimeViewClock,
                  // }}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: false,
                      // InputProps: {
                      //   sx: {
                      //     color: textColor, // Set the text color here
                      //   },
                      // },
                    },
                  }}
                  textField={(params) => (
                    <TextField
                  sx={{"& .css-1d3z3hw-MuiOutlinedInput-notchedOutline":{
                    borderColor:"none",
                    border:"1px solid #DCDCDC",
                    "&:hover": {
                      border:"1px solid #DCDCDC",

                          },
                  }}}
                      {...params}
                      // name={name}
                      size={"small"}
                      variant={"standard"}
                      helperText={null}
                    />
                  )}
                />
                <p className="form-error">
                  {startTimeTouched &&
                  startTimeTouched[index] &&
                  startTimeErrors &&
                  startTimeErrors[index]
                    ? startTimeErrors[index]
                    : null}
                </p>
              </div>
              <div className={`${!isMobile ? "col-md-6" : "col-5"}`}>
                <TimePicker
                  // disableIgnoringDatePartForTimeValidation
                  id={`businessEndTime`}
                  name={`businessEndTime`}
                  format="HH:mm a"
                  value={active ? "" : options.businessEndTime}
                  sx={{
                    // border: "0.5px solid #DCDCDC",
                    width: "100%",
                    minWidth: isMobile ? "70px" : "127px",
                    borderRadius: isMobile ? "10px" : "8px",
                    color: "#575757",
                    backgroundColor: isMobile
                      ? !active
                        ? "#E0EBFF"
                        : "#DCDCDC"
                      : !active
                      ? "#F3F6FF"
                      : "#ffff",
                    "& .MuiOutlinedInput-root": {
                      borderRadius: isMobile ? "10px" : "8px",
                      "&:hover .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#DCDCDC",
                      },
                      "& .MuiOutlinedInput-notchedOutline": {
                        borderColor: "#DCDCDC",
                        border: "0.5px solid #DCDCDC",
                      },
                    },
                    "& .MuiInputAdornment-root": {
                      marginLeft:"0px"
                      // display: "none", // Hide the adornment
                    },
                  }}
                  disabled={!options.isAvailable || active}
                  minTime={dayjs(startTimeValues && startTimeValues[index])}
                  onChange={(newValue) => {
                    setFieldValue(`${index}.businessEndTime`, newValue);
                  }}
                  textColor={!active ? "#575757" : "#fff"}
                  slotProps={{
                    textField: {
                      size: "small",
                      error: false,
                      // InputProps: {
                      //   sx: {
                      //     color: textColor, // Set the text color here
                      //   },
                      // },
                    },
                  }}
                  textField={{
                      size: "small",
                      error: false,
                      InputProps: {
                        endAdornment: (
                          <InputAdornment
                            position="end"
                            onClick={handleIconClick}
                            style={{ cursor: "pointer" }}>
                            <ArrowDropDownIcon />
                          </InputAdornment>
                        ),
                        inputRef: inputRef,
                      },
                    }}
                  // textField={(params) => (
                  //   <TextField
                  //     {...params}
                  //     size="small"
                  //     variant="standard"
                  //     helperText={null}
                  //     sx={{
                  //       borderRadius: "10px",
                  //     }}
                  //   />
                  // )}
                />
                <p className="form-error">
                  {startTimeTouched &&
                  startTimeTouched[index] &&
                  startTimeErrors &&
                  startTimeErrors[index]
                    ? startTimeErrors[index]
                    : null}
                </p>
              </div>
            </LocalizationProvider>
          </div>
          
        </div>
      </div>
    </>
  );
};

export default Times;

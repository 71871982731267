import { combineReducers } from "redux";
import { connectRouter } from "connected-react-router";
// import { persistReducer } from 'redux-persist';
// import storage from 'redux-persist/lib/storage';
// Front
// import Layout from './layout/reducer';

// Authentication Module
import Department from "./Department/reducers";
import Team from "./Team/reducers";
import User from "./User/reducers";
import Auth from "./Auth/reducer";
import Business from "./Business/reducers";
import { roleReducer } from "../Pages/BusinessProfile/User/store/reducer";


import { LOGOUT } from "./Auth/actionTypes";
import { removeAllCookies, removeAllLocalStorage } from "../utils/helper";


// import persistReducer from 'redux-persist/es/persistReducer';
// import AuthReducer

// const authPersistConfig = {
//     key: 'auth',
//     storage: storage,
//     keyPrefix: 'redux-',
//     whitelist: ['auth']
//   };

const resettable = (reducer) => (state, action) => {
  if (action.type === LOGOUT) {
    removeAllLocalStorage();
    removeAllCookies();
    return reducer(undefined, action);
  }
  return reducer(state, action);
};

const rootReducer = (history) =>
  combineReducers({
    router: connectRouter(history),
    Auth: resettable(Auth),
    Business: resettable(Business),
    Department: resettable(Department),
    
    Team: resettable(Team),
    User: resettable(User),
    roleReducer: resettable(roleReducer),

    
  });

export default rootReducer;


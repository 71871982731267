import { all } from "redux-saga/effects";
import authSaga from "./Auth/saga";
import businessSaga from "./Business/saga";
import departmentSaga from "./Department/saga";
import teamSaga from "./Team/saga";
import userSaga from "./User/saga";


export default function* rootSaga() {
  yield all([authSaga(),departmentSaga(), businessSaga(), teamSaga(), userSaga()]);
}


import { BusinessRoute } from "./BusinessRoutes";
import ManageUser from "../Pages/BusinessProfile/User/MangeUser";

import PageNotFound from "../Pages/PageNotFound";
import { TeamAndDepartmentRoutes } from "./TeamandDeptRoutes";
import AddUser from "../Pages/BusinessProfile/User/AddUser";
import Terms from "../Pages/PublicPages/Terms/Terms"
const routes = [
  ...BusinessRoute,
  ...TeamAndDepartmentRoutes,
  {
    path: "/users",
    component: ManageUser,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-user",
    component: AddUser,
    ispublic: false,
    exact: true,
  },
  


 
  {
    path: "/terms",
    component: Terms,
    ispublic: false,
    exact: true,
  },
 
  {
    path: "*",
    component: PageNotFound,
    ispublic: false,
  },
];

export default routes;

import React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import shareicon from "../../../../Assets/Images/shareicoon.png";
import Department from "../../../../Assets/svgs/department.svg";
import Timings from "../../../../Assets/svgs/alarmtime.svg";
import Address from "../../../../Assets/svgs/mapaddress.svg";
import { customSnackBar } from "../../../../utils";

const CardLink = ({ isMobile, permission }) => {
  const businesProfile = useSelector((state) => state.Business.businesProfile);

  const history = useHistory();
  // const handleAddUser = () => {

  //     history.push(`/users`, businesProfile);

  //   };
  //   const handleAddDepartment = () => {
  //     localStorage.setItem("businessRecord", JSON.stringify(businesProfile));

  //     history.push(`/department`, businesProfile);

  //   };
  //   const handleStaffAdd = () => {
  //     localStorage.setItem("businessRecord", JSON.stringify(businesProfile));

  //     history.push(`/edit-business-address`, );

  //   };
  const handleSchulaer = () => {
    localStorage.setItem("businessRecord", JSON.stringify(businesProfile));
    let action = "businessProfile";
    const data = {
      action: "businessProfile", // data: response?.data?.result?.id,
    };
    if (
      permission === null ||
      permission?.canEditBusinessSchedule ||
      permission?.canEditBusinessSchedule === null ||
      permission?.canEditBusinessSchedule === undefined
    ) {
      history.push(`/add-business-schedular/${businesProfile.id}`, data);
    } else {
      customSnackBar("You have no permission to Edit the Business Schdule");
    }
  };
  const portalcontent = [
    {
      title: "Department",
      icon: <departmentSvg />,
    },
    {
      title: "timings",
      icon: <alaramTimeSvg />,
    },
    {
      title: "Address",
      icon: <mapSvg />,
    },
  ];
  const manageDepartment = () => {
    if (
      permission === null ||
      permission?.canViewBusinessDepartment ||
      permission?.canViewBusinessDepartment === null ||
      permission?.canViewBusinessDepartment === undefined
    ) {
      history.push(`/department`, businesProfile);
    } else {
      customSnackBar("You have no permission to View to the Department");
    }
  };
  const handleMapAddress = () => {
    if (
      permission === null ||
      permission?.canUpdateBusinessProfile ||
      permission?.canUpdateBusinessProfile === null ||
      permission?.canUpdateBusinessProfile === undefined
    ) {
      history.push(`/edit-business-address`, businesProfile);
    } else {
      customSnackBar("You have no permission to View to the Department");
    }
  };
  return (
    //   <div
    //   className={`${!isMobile ? "d-flex mb-3 justify-content-center flex-wrap":"d-flex mb-3 justify-content-center "}`}>
    //   {" "}
    //   <div className={`${!isMobile ? "":"pe-2 mt-3"}`}>

    //     <div className="card-link ">
    //       <div  style={{cursor:"pointer"}} className="p-3 ms-4 me-4 align-items-center d-flex flex-column justify-content-between" onClick={handleAddDepartment}>
    //         <img
    //         className={`${!isMobile ? "ms-1":"ms-1 mt-2"}`}
    //           src="../images/git-fork 2.svg"
    //           alt="nodata"
    //         />{" "}
    //         <p style={{fontSize:isMobile?"12px":""}} className={`${isMobile?"pt-2":"pt-0"}" sub_text_color_account_alltext_show_actual"`}>
    //           Department
    //         </p>
    //       </div>
    //     </div>
    //   </div>
    //   <div className={`${!isMobile ? "":"pe-2 mt-3"}`}>

    //       <div className="card-link ">
    //         <div style={{cursor:"pointer"}} className="p p-3 ms-4 me-4 align-items-center d-flex flex-column justify-content-between" onClick={handleSchulaer}>
    //           <img
    //             className="ms-1"
    //             src="../images/alarm-check 1 (1).svg"
    //             alt="nodata"
    //           />{" "}
    //           <p style={{fontSize:isMobile?"12px":""}} className="pt-2 sub_text_color_account_alltext_show_actual">
    //             Timing
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //     <div className={`${!isMobile ? "":"pe-2 mt-3"}`}>
    //       <div className="card-link ">
    //         <div style={{cursor:"pointer"}} className="p p-3 ms-4 me-4 align-items-center d-flex flex-column justify-content-between" onClick={handleStaffAdd}>
    //           <img
    //             className="ms-1"
    //             src="../images/map-pin 1.svg"
    //             alt="nodata"
    //           />{" "}
    //           <p style={{fontSize:isMobile?"12px":""}} className="pt-2 sub_text_color_account_alltext_show_actual">
    //             Address
    //           </p>
    //         </div>
    //       </div>
    //     </div>
    //     <div className={`${!isMobile ? "":"pe-2 mt-3"}`}>
    //       <div className="card-link ">
    //         <div style={{cursor:"pointer"}} className="p p-3 ms-4 me-4 align-items-center d-flex flex-column justify-content-between" onClick={handleAddUser}>
    //           <img
    //             className="ms-1"
    //             src="../images/tv-2 1.svg"
    //             alt="nodata"
    //           />{" "}
    //           <p style={{fontSize:isMobile?"12px":""}} className="pt-2 sub_text_color_account_alltext_show_actual">
    //             Portal
    //           </p>
    //         </div>
    //       </div>
    //   </div>
    // </div>
    <div className="mt-3">
      <div className="profile_border p-2">
        <div className="d-flex justify-content-between pb-2">
          <span className="customer_portal_heading">Portal</span>
          <span>
            <img alt="img" className="img-fluid  " src={shareicon} />
          </span>
        </div>
        <div className="d-flex flex-wrap justify-content-start">
          <div
            className="portal_cards_shadow p-3 me-2 mb-2 text-center"
            onClick={manageDepartment}
            style={{ cursor: "pointer" }}>
            <div className="pb-4">
              <img src={Department} alt="img" />
            </div>
            <p className="mb-0 pt-1 portal_content">Department</p>
          </div>
          <div
            className="portal_cards_shadow p-3 me-2 mb-2 text-center"
            style={{ cursor: "pointer" }}
            onClick={handleSchulaer}>
            <div className="pb-4">
              <img src={Timings} alt="img" />
            </div>

            <p className="mb-0 pt-1 portal_content">Timings</p>
          </div>
          <div
            className="portal_cards_shadow p-3 me-2 mb-2 text-center"
            onClick={handleMapAddress}
            style={{ cursor: "pointer" }}>
            <div className="pb-4">
              <img src={Address} alt="img" />
            </div>

            <p className="mb-0 pt-1 portal_content">Address</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLink;

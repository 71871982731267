import React, { useState } from "react";
import uploadIcon from "../../../../Assets/svgs/uplaod.svg";
import uploadIconImage from "../../../../Assets/svgs/uploadImage.svg";
import BusinessContentImage from "../../../../Assets/svgs/Frame.svg";
import styles from "../style.module.css";
import instance from "../../../../utils/utils";
import { ClipLoader } from "react-spinners";
import { customSnackBar } from "../../../../utils";

const BusinessNameSection = ({
  pageId,
  businessRecrord,
  pageSections,
  sectionId,
  fetchPageSection,
  setSectionLength,
  sectionOrder,
  userID

}) => {
  const [formData, setFormData] = useState({
    sectionTitle: "Text",
    titleText: "Text",
    subText: "Text",
    uploadedImage: null, // For Icon Image
    uploadBannerImage: null, // For Banner Image
    // buttonTitle: "Text",
  });
  const [errors, setErrors] = useState({
    sectionTitle: false,
    titleText: false,
    subText: false,
    uploadedImage: false,
    uploadBannerImage: false,
    // buttonTitle: false,
  });
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isLoadingImage2, setIsLoadingImage2] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false }); // Clear error on change
  };

  // Handle image upload
  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({ ...formData, uploadedImage: URL.createObjectURL(file) });
      setErrors({ ...errors, uploadedImage: false });
    }
  };

  const handleFileUpload = async (event, imageType) => {
    const selectedFile = event.target.files[0];
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      const uploadData = new FormData();
      uploadData.append("Id", 16);
      uploadData.append("ServiceName", "serviceinventory");
      uploadData.append("Source", "Plexaar");
      uploadData.append("Files", selectedFile);
      uploadData.append("Category", "main");
      uploadData.append("FileType", "i");
      uploadData.append("CreatedBy", userID);
      if (imageType === "uploadedImage") {
        setIsLoadingImage(true);
      } else setIsLoadingImage2(true);

      try {
        const response = await instance.post(
          `${process.env.REACT_APP_BASE_URL}/serviceinventory_svc/pv/ServiceAttachment/UploadAttachment`,
          uploadData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.code === 0) {
          setFormData({
            ...formData,
            [imageType]: response.data.paths[0],
          });
          setErrors({ ...errors, [imageType]: false });
        } else {
          customSnackBar("Error uploading image.");
        }
      } catch (error) {
        customSnackBar("Error uploading image. Please try again.");
      } finally {
        if (imageType === "uploadedImage") {
          setIsLoadingImage(false);
        } else setIsLoadingImage2(false);      }
    } else {
      customSnackBar("Invalid file type. Please upload a jpg, jpeg, or png file.");
    }
  };

  const handleSubmit = async () => {
    const positions = {
      sectionTitle: 1,
      titleText: 2,
      subText: 3,
      uploadedImage: 4,
      uploadBannerImage: 5,
      // buttonTitle: 6,
    };
    const variation={
      titleText: 1,
      sectionTitle:1 ,
      subText: 2,
      uploadedImage: 4,
      uploadBannerImage: 4,
      // buttonTitle: 2,

    }    // Validate fields
    const newErrors = {
      // buttonTitle: !formData.buttonTitle || formData.buttonTitle === "Text",
      sectionTitle: !formData.sectionTitle || formData.sectionTitle === "Text",
      titleText: !formData.titleText || formData.titleText === "Text",
      subText: !formData.subText || formData.subText === "Text",
      uploadedImage: !formData.uploadedImage,
      uploadBannerImage: !formData.uploadBannerImage,
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);

    if (!hasErrors) {
      const submissionData = Object.keys(formData).map((key) => {
        let contentType = 1; // Default: 1 for text
        let contentVariation =formData.titleText||!formData.sectionTitle ? 1:2; // Default: 1 for text
        ; // Default: 1 for text
        let attachmentUrl = null;

        // If it's an image field
        if (key === "uploadedImage" || key === "uploadBannerImage") {
          contentType = 2; // Images have content_type = 2
          attachmentUrl = formData[key];
        }

        return {
          service_sku: "",
          section_id: sectionId,
          page_id: pageId?.id,
          content: contentType === 1 ? formData[key] : null, // Set text content
          attachment_url: contentType === 2 ? attachmentUrl : null, // Set image URL
          alignment: null,
          content_Width: 2147483647,
          content_Height: 2147483647,
          entity_id:businessRecrord?.id,
        entity_type:"BUSINESS",
          content_style: null,
          position: positions[key], // Dynamic position
          font: null,
          font_color: null,
          font_family: null,
          font_style: null,
          font_size: null,
          content_type: contentType,
          content_variation: variation[key],
        };
      });

      // Attach API call
      try {
        setLoading(true)
        const response = await instance.post(
          `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/content/`,
          submissionData
        );

        if (response.data.code === 0) {
          console.log("API Response:", response.data);
          fetchPageSection()
          setLoading(false);
          setSubmit(true)
          setSectionLength(sectionOrder - 1);

          customSnackBar("Form Submitted Successfully!");
        } else {
          setLoading(false);

          console.error("Error:", response.data.message);
          customSnackBar("Failed to submit form. Please try again.");
        }
      } catch (error) {
        setLoading(false);

        console.error("API Error:", error);
        customSnackBar("Error submitting form. Please check your network or try again.");
      }
    } else {
      customSnackBar("Please fill all the required fields!");
    }
  };

  return (
    <div className="row">
      {/* Editable Section Title */}

      {/* Left Section */}
      <div className="col-md-6">
        <div className="pt-2 d-flex align-items-center">
          <label className={styles.uploadLabel}>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => handleFileUpload(e, "uploadedImage")}
              style={{ display: "none" }}
            />
              {isLoadingImage ? (
                  <ClipLoader size={10} />
                ) : (
                  <img
              alt="none"
              style={{ borderRadius: "50px" }}
              height={80}
              width={80}
              src={formData.uploadedImage || uploadIcon}
            />
                )}
          
          </label>
          {errors.uploadedImage && (
            <span className={styles.errorText}>Image upload is required</span>
          )}
          <div className="">
            <input
              type="text"
              name="sectionTitle"
              placeholder="Text"
              value={formData.sectionTitle}
              onChange={handleInputChange}
              style={{
                backgroundColor: "transparent",
                color: "#000",
                fontSize: "20px",
                fontWeight: 500,
              }}
              className={`${styles.inputField} ${
                errors.sectionTitle ? styles.errorBorder : ""
              }`}
            />
            {errors.sectionTitle && (
              <span className={styles.errorText}>
                Section title is required
              </span>
            )}
          </div>{" "}
        </div>
        <div className="pt-4">
          {/* Editable Title */}
          <div style={{ height: "120px" }} className={styles.largeBox}>
            <textarea
              type="text"
              name="titleText"
              placeholder="Enter Title"
              value={formData.titleText}
              onChange={handleInputChange}
              className={`${styles.textareaFieldMajor} ${
                errors.titleText ? styles.errorBorder : ""
              }`}
            />
            {errors.titleText && (
              <span className={styles.errorText}>Title is required</span>
            )}
          </div>

          {/* Editable SubText */}
          <div
            style={{ height: "120px" }}
            className={`${styles.largeBox} mt-2`}>
            <textarea
              name="subText"
              placeholder="Enter Subtext"
              value={formData.subText}
              onChange={handleInputChange}
              className={`${styles.textareaField} ${
                errors.subText ? styles.errorBorder : ""
              }`}
            />
            {errors.subText && (
              <span className={styles.errorText}>Subtext is required</span>
            )}
          </div>
        </div>
        <div className="pt-5 mt-5">
        <button
        className={`${styles.submitButton}`}
         style={{
              backgroundColor: "#03A9F4",
            
            }}>
       BOOK NOW   
        </button>
          {/* <input
            type="text"
            name="buttonTitle"
            placeholder="Text"
            value={"BOOK NOW"}
            onChange={()=>{
              console.log("Book")
            }}
            style={{
              backgroundColor: "#03A9F4",
              color: "white",
              fontSize: "14px",
              maxWidth: "max-content",
              textAlign: "center",
            }}
            className={`${styles.inputField} ${
              errors.buttonTitle ? styles.errorBorder : ""
            }`}
          /> */}
          {errors.buttonTitle && (
            <span className={styles.errorText}>Button title is required</span>
          )}
        </div>{" "}
      </div>

      {/* Right Section */}
      <div className={`col-md-6 p-0 text-end ${styles.imageContainer}`}>
        {/* Background Image */}
        <img
          alt="Business Content"
          src={BusinessContentImage}
          className={styles.backgroundImage}
        />

        {/* Overlay Upload Image */}
        <label className={styles.uploadLabel}>
          <input
            type="file"
            accept="image/*"
            // className={styles.overlayImage}

            onChange={(e) => handleFileUpload(e, "uploadBannerImage")}
            // style={{ display: "none" }}
          />
          {isLoadingImage2 ? (
                  <ClipLoader size={10} />
                ) : (
                  <img
            alt="Upload Banner Icon"
            src={formData.uploadBannerImage || uploadIconImage}
            className={styles.overlayImage}
            style={{
              borderRadius: formData.uploadBannerImage ? "0 100% 100%" : "",
            }}
            width="400px"
            height="400px"
        
            />
                )}
          
         
        </label>
        {errors.uploadBannerImage && (
          <span className={styles.errorText}>Image upload is required</span>
        )}
      </div>

      {/* Submit Button */}
      <div className={styles.saveContainer}>
      <button
  disabled={loading || submit} 
  className={styles.submitButton}
          onClick={handleSubmit}>
          {loading ? <ClipLoader size={10} color="#fff" /> :
          submit?"Submitted": "Submit"}
        </button>
      </div>
    </div>
  );
};

export default BusinessNameSection;

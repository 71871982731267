import React, { useState, useEffect } from "react";

import Table from "./Table";
import { OnSearch } from "./helper";
// import Header from "../Header";
import { businessList } from "../../../utils/constant";
import SkeltonTable from "../SkeltonTableSimple";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchBusinessFailure,
  fetchBusinessRequest,
  // fetchBusinessSuccess,
} from "../../../Store/Business/actions";
import SideNavone from "../../../SideNavBar";
// import MenuTabList from "../CustomTabs/MenuTabList";
// import NewList from "./NewList";
import "../../../Assets/components/meetspace/events.scss";
import NewListMobile from "./NewListMobile";
import { getCookiesData } from "../../../utils/helper";
import AppLayout from "../../Layout/AppLayout";
import { isBusinessRolePermissionAllowed } from "../../../utils";
import { Spin } from "antd";

const ManageBusines = ({ setActiveTab }) => {
  const flag = false;
  const [rowData, setRowData] = useState([]);
  // const [data, setData] = useState([]);
  // const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const data = useSelector((state) => state.Business?.data);
  const error = useSelector((state) => state.Business?.error);
  const loading = useSelector((state) => state.Business?.loading);
  const userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const user = useSelector((state) => state.Auth.userloginsuccessyasir);
  useEffect(() => {
    // setLoading(true)
    dispatch(fetchBusinessRequest(userID));
    if (error) {
      dispatch(fetchBusinessFailure(error));
    }
  }, [userID, error, dispatch]);

  useEffect(() => {
    setRowData(data || []);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, [data]);

  const [viewPermssion, setViewPermissions] = useState(null);

  const permissionsToCheck = [
    "canViewBusinessList",
    "canCreateBusiness",
    "canUpdateBusinessProfile",
    "canEditBusinessSchedule",
    "canViewBusinessUsers",
    "canUpdateBusinessProfile",
    "canDeleteBusiness",
    "canViewBusinessDepartment",
    "canAddBusinessDepartments",
    "canEditBusinessDepartments",
    "canDeleteBusinessDepartments",
    "canDeleteBusinessTeam",
  ];

  let permissions = useSelector((state) => state?.Auth?.permissionsModule);
  useEffect(() => {
    const fetchPermissions = async () => {
      const fetchedPermissions = {};
      for (const permission of permissionsToCheck) {
        const result = await isBusinessRolePermissionAllowed(
          permissions,
          "Business Roles & Permissions",
          permission
        );
        fetchedPermissions[permission] = result;
      }
      setViewPermissions(fetchedPermissions);
    };

    if (permissions) {
      fetchPermissions();
    } else {
    }
  }, [permissions]);

  return (
    <>
      <AppLayout
        name="Manage Businesses"
        isShow={true}
        data={data}
        OnSearch={OnSearch}
        rowData={rowData}
        setRowData={setRowData}
        allSpace={
          <>
            <div
              style={{
                height: "100vh",
                overflow: "auto",
                paddingBottom: "10px",
              }}
              className="hide_scrollbar ">
              {loading ? (
                <div className="center-loader">
                  <Spin />
                </div>
              ) : (
                <Table
                  permission={viewPermssion}
                  loading={loading}
                  record={data}
                  setRecord={setRowData}
                  data={rowData}
                />
              )}
            </div>
          </>
        }
      />
    </>
  );
};

export default ManageBusines;

import React, { useEffect, useState } from "react";
import { Button, Dialog } from "@mui/material";

const DialogBox = (props) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setOpen(props.visible);
  }, [props.visible, props.message, props.header]);

  const handleClose = () => {
    setOpen(false);
  };

  const getButtonStyle = (color) => ({
    backgroundColor: color,
    height: "35px",
    width: "50px",
    minWidth: "60px",
    color: "white",
    borderRadius: "4px",
    textTransform: "capitalize",
    '&:hover': {
      backgroundColor: color,
      height: "35px",
    },
  });

  return (
    <Dialog
      id="dialog-component"
      open={open}
      onClose={handleClose}
      classes={{ paper: "shadow-sm-dark rounded-lg" }}
    >
      <div className="p-4" id="dbox">
        <h4 className="font-weight-bold mt-1 font-family-roobert">{props.header}</h4>
        <hr className="ms-0 mb-1" style={{ color: "#c4c4c4", margin: "0 0 21.4px" }} />
        <p className="mb-0 text-black-50 font-family-roobert">{props.message}</p>
        <div className="pt-4 text-end">
          <Button
            sx={getButtonStyle(props && props.action && props.action === "new-alert" ? "#d9534f" : "#FFD705")}
            onClick={handleClose}
            className="me-3"
            id="dbox-cancel"
          >
            <span >{props && props.action && props.action === "new-alert" ? "Cancel" : "No"}</span>
          </Button>
          <Button
            sx={getButtonStyle(props && props.action && props.action === "new-alert" ? "#FFD705" : "#d9534f")}
            onClick={() => {
              props.ok();
              handleClose();
            }}
            id="dbox-ok"
          >
            <span style={{
              fontSize:props && props.action && props.action === "new-alert"?"10px":"12px"
            }}>{props && props.action && props.action === "new-alert" ? "Force Continue" : "Yes"}</span>
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default DialogBox;

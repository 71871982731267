// import React, { Suspense } from "react";
// import { useSelector } from "react-redux";
// import { Redirect } from "react-router-dom";

// export function PublicRoute({ Component, props }) {

//   const userDetail = useSelector((state) => state.Auth);
// const token =localStorage.getItem("token")

//   if (userDetail.tokenn) {
//     return <Redirect to={"/Dashboard-Admin"} />;
//   } else {
//     return   <Component {...props} />;
//   }
// }

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import {
  encryptToken,
  getCookiesData,
  getStorageData,
  setCookiesData,
} from "../utils/helper";
import CryptoJs from "crypto-js";
import {
  addSelectedBusinessId,
  addUserId,
  fetchBusinessRequest,
  setUrl,
} from "../Store/Business/actions";
import {
  getuserdataafterlogin,
  getuserdataafterloginsuccess,
  permissionModule,
  userpayloadsuccess,
} from "../Store/Auth/actions";

export function PublicRoute({ Component, props }) {
  // const auth = getStorageData("auth");
  const auth = getCookiesData("auth");
  // let busienss= useSelector((state) => state.Business?.data)
  const authitcation = getCookiesData("authtication");
  const tokenExists = localStorage.getItem("token");
  const dispatch = useDispatch();
  const [token, setToken] = useState("");
  const [refreshToken, setRefreshToken] = useState("");
  const [userId, setUserId] = useState("");
  const [user, setUser] = useState("");
  const [loading, setLoading] = useState(true);
  // const decryptUrl = (encryptedUrl) => {
  //   return CryptoJs.AES.decrypt(encryptedUrl, "9GxbhX0FNMP7Y8MP").toString(
  //     CryptoJs.enc.Utf8
  //   );
  // };

  // useEffect(() => {
  //   const params = new URLSearchParams(window.location.search);
  //   console.log(params, "decryptedURLdecryptedURL");

  //   const redirectInfoParam = params.get("redirectInfo");
  //   console.log(redirectInfoParam, "decryptedURLdecryptedURL");

  //   const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
  //   console.log(decodedRedirectInfo, "decryptedURLdecryptedURL");

  //   // if (decodedRedirectInfo) {
  //   try {
  //     const redirectObject = JSON.parse(decodedRedirectInfo);
  //     console.log(redirectObject.user, "decryptedURLdecryptedURL");

  //     // const token = decryptUrl(redirectObject.token);
  //     const userId = decryptUrl(redirectObject.encryptedURL);
  //     const user = decryptUrl(redirectObject.user);
  //     // const refreshToken = decryptUrl(redirectObject.jwtRefreshToken);
  //     // setRedirectUrl(decryptedURL);

  //     // Extract other data as needed

  //     // Save token and userId into state
  //     console.log(userId, "useruser");

  //     setTimeout(() => {
  //       setUser(user);
  //       // setLoading(false);
  //     }, 900);
  //     localStorage.setItem("user", JSON.stringify(user));

  //     // if (token||refreshToken) {
  //     dispatch(fetchBusinessRequest(parseInt(userId)));
  //     dispatch(getuserdataafterlogin({ id: parseInt(userId) }));

  //     dispatch(userpayloadsuccess(JSON.parse(user)));
  //     // }
  //     console.log(
  //       "Decrypted URL:",
  //       JSON.stringify(token),
  //       parseInt(userId),
  //       JSON.parse(user)
  //     );
  //     console.log("Target URL:", redirectObject);
  //   } catch (e) {
  //     console.error("Error parsing redirectInfo:", e);
  //   }
  //   // }
  // }, []);

  //   console.log(authitcation, auth, 'authitcationauthitcationauthitcation');
  // const decryptUrl = (encryptedUrl) => {
  //   return CryptoJs.AES.decrypt(encryptedUrl, '9GxbhX0FNMP7Y8MP').toString(
  //     CryptoJs.enc.Utf8
  //   );
  // };
  const decryptUrl = (str) => {
    return CryptoJs.AES.decrypt(str, "9GxbhX0FNMP7Y8MP").toString(
      CryptoJs.enc.Utf8
    );
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);

    const redirectInfoParam = params.get("redirectInfo");

    const decodedRedirectInfo = decodeURIComponent(redirectInfoParam);
    // if (decodedRedirectInfo) {
    try {
      const redirectObject = JSON.parse(decodedRedirectInfo);

      const token = decryptUrl(redirectObject.token);
      const url = decryptUrl(redirectObject.encryptedURL);
      const user = decryptUrl(redirectObject.user);
      // const selectedBusiness= decryptUrl(redirectObject.selectdBusiness)
      // const users = redirectObject.user;
      const refreshToken = decryptUrl(redirectObject.jwtRefreshToken);
      // setRedirectUrl(decryptedURL);
      const newToken = token;
      const newRefreshToken = refreshToken;

      const encryptedTokenStorage = encryptToken(newToken);
      const encryptedRefreshTokenStorage = encryptToken(newRefreshToken);

      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", newRefreshToken);

      setCookiesData(
        "authtication",
        JSON.stringify({
          token: token,
          userId: userId,
          user: user,
          refreshToken: refreshToken,
        })
      );
      // localStorage.setItem('token', token);
      // localStorage.setItem('refreshToken', refreshToken);
      const newuser = JSON.parse(user);
      setTimeout(() => {
        // setToken(token);
        // setUserId(userId);
        setUser(user);
        // setLoading(false);
      }, 900);

      localStorage.setItem("user", user);
      localStorage.setItem("userId", newuser.id);
      dispatch(setUrl(url));

      localStorage.setItem("url", url);

      // if (token||refreshToken) {
        
      dispatch(fetchBusinessRequest(newuser.id));
      dispatch(getuserdataafterlogin({ id: newuser.id }));
      
      dispatch(userpayloadsuccess(newuser));
      if(redirectObject.selectdBusiness){
      dispatch(
        permissionModule({
          userID:newuser.id,
          businessID: redirectObject.selectdBusiness,
        })
      );
    }
      // }
    } catch (e) {
      console.error("Error parsing redirectInfo:", e);
    }
    // }
  }, []);

  // if (auth?.token && !location.pathname.includes("/verify-number/")) {
  //   return <Redirect to="/calendar" />;
  // } else {
  return <Component {...props} />;
  // }
}

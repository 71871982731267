import React, { useEffect, useState } from "react";
import Times from "./Times";
import convertToDateTime, { businessScheduleManage, convertToDateTimes } from "../helper";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
// import Times from "./Times"; // Ensure this import is correct
import { IconButton, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { AddScheduleSvg, RemoveScheduleSvg } from "../../../../Assets/svgs";
import moment from "moment";
const InfoOpen = (props) => {
  const {
    open,
    values,
    handleChange,
    options,
    index,
    active,
    setFieldValue,
    isMobile,
    scheduleData,
    actionState,
    startTimeValues,
    endTimeValues,
    scheduleIndex
  } = props;
  const [slotStates, setSlotStates] = useState({});
  const [activeState, setActiveStates] = useState({});
  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleClickActive = (index) => {
    // Create a copy of the slot states
    const newSlotStates = { ...slotStates };
    // Toggle the active state
    newSlotStates[index] = !newSlotStates[index];

    // Update the image source based on the active state

    // Update the slot states
    setSlotStates(newSlotStates);
    setFieldValue(`${index}.hourFormat`, newSlotStates[index]);
  };
  function convertToDateTime(inputTime) {
    // Check if the input time has the format "YYYY-MM-DDTHH:mm:ss"
    if (dayjs(inputTime, { strict: true }).isValid()) {
      return dayjs(inputTime);
    }

    // Parse input time as "HH:mm:ss"
    const currentTime = dayjs();
    const [hours, minutes, seconds] = inputTime.split(":");

    // Set the time components
    const formattedDateTime = currentTime
      .set("hour", parseInt(hours, 10))
      .set("minute", parseInt(minutes, 10))
      .set("second", parseInt(seconds, 10));

    return formattedDateTime;
  }
  const handleChanges = (index) => {
    const newSlotStates = { ...activeState };
    newSlotStates[index] = !newSlotStates[index];

    // Update the image source based on the active state

    // Update the slot states
    setActiveStates(newSlotStates);
    setFieldValue(`${index}.isAvailable`, newSlotStates[index]);
  };
  useEffect(() => {
    if (scheduleData && scheduleData.length > 0 && actionState === "edit") {
      // Map the scheduleData to match the initialValues structure
      const mappedData = scheduleData.map((item) => ({
        id: item.id,
        businessId: item.businessId,
        day: item.day,
        businessStartTime: convertToDateTime(`${item.businessStartTime}:00`),
        businessEndTime: convertToDateTime(`${item.businessEndTime}:00`),
        isAvailable: item.isAvailable,
        hourFormat:
          item.businessStartTime === "00:00" && item.businessEndTime === "23:59"
            ? true
            : false,
      }));

      // Set the mappedData as the initial values using setFieldValue
      setFieldValue("day", mappedData);
      localStorage.setItem("businessScularUpdatedRecord", mappedData);
    }
  }, [scheduleData, actionState, setFieldValue]);
  const handleAddTimeSlot = () => {
    // const newValues = [...values];
    // newValues.push({
    //   businessStartTime: "",
    //   businessEndTime: "",
    // });
    // setFieldValue("day", newValues);
  };

  const handleRemoveTimeSlot = (index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setFieldValue("day", newValues);
  };
  return (
    <TableContainer sx={{border:"1px solid #FFD705"}} component={Paper}>
    <Table aria-label="schedule table">
      <TableHead
        className="text-capitalize font-size-sm font-weight-bold"
        sx={{
          backgroundColor: "#FFD705",
          height: "45px",
        }}>
        <TableRow>
          <TableCell
            style={{
              minWidth: 390,
              color: "#fff",
              position: "sticky",
              left: "auto",
              backgroundColor: "#FFD705",
            }}>
            Day
          </TableCell>
          <TableCell
            colSpan={2}  // Merges the next two cells
            style={{
              minWidth: 194,
              color: "#fff",
              position: "sticky",
              left: "auto",
              backgroundColor: "#FFD705",
            }}>
             <div className="row">
            <div className="col-md-5">From</div>
            <div className="col-md-5">To</div>
          </div>

          </TableCell>
          {/* <TableCell
            style={{
              minWidth: 10,
              color: "#fff",
              position: "sticky",
              left: "auto",
              backgroundColor: "#FFD705",
            }}>
            
          </TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map((dayData, index) => (
          <TableRow key={index}>
            <TableCell className="p-0">
              <div className="">
                <div className="px-2">
                  <label
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "130px",
                      cursor: "pointer",
                    }}>
                    {/* {open&&( */}
                   
                    {/* )} */}
                    <span className="new-span">{dayData.day}</span>
                  </label>
                </div>
              </div>
            </TableCell>
            <TableCell 
            colSpan={4}  // Merges the next two cells
             className="pt-3 pb-3 ">
        
            
             <div className="row">
            <div className="col-md-5 new-span-time">{convertToDateTimes(dayData.businessStartTime)}</div>
            <div className=" col-md-5  new-span-time">{convertToDateTimes(dayData.businessEndTime)}</div>
          </div>

              
            </TableCell>

          </TableRow>
        ))}
        
      </TableBody>
    </Table>
  </TableContainer>
  
  
  );
};

export default InfoOpen;
 


import React, { useState, useEffect } from "react";
import { AiOutlineQuestionCircle } from "react-icons/ai";
import InputTextField from "../TextField";

import Button from "@mui/material/Button";
import Header from "../Header";
import { userList } from "../../../utils/constant";
import Avatar from "@mui/material/Avatar";
import { useDispatch, useSelector } from "react-redux";
import {
  addRoleRequest,
  editRoleRequest,
  fetchPermissionRequest,
  fetchProductRoleRequest,
  fetchRoleRequest,
} from "../../../Store/User/actions";
import { useHistory } from "react-router-dom";
import SideNavone from "../../../SideNavBar";
import { Switch } from "@mui/material";
import { permissionData, permissions } from "./helper";
import AppLayout from "../../Layout/AppLayout";
import arrowDown from "../../../../src/Assets/Images/arrorbottommm.png";
import { customSnackBar } from "../../../utils";
import { Spin } from "antd";

// import "../NewBusiness/AddBusiness/AddBusiness.scss";

const Role = ({
  onClose,
  selectedOptions,
  service,
  action,
  businessData,
  onRoleCreated,
  role,
  setRole,
  selectedRole,
  selectedPermissions,
  setSelectedPermissions,
  setLoader,
  loader
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const actions = localStorage.getItem("action");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  // const [selectedPermissions, setSelectedPermissions] = useState([]);
  const [expandedPermissions, setExpandedPermissions] = useState([]);
  const toggleChildrenVisibility = (permissionIndex) => {
    setExpandedPermissions((prev) =>
      prev.includes(permissionIndex)
        ? prev.filter((i) => i !== permissionIndex)
        : [...prev, permissionIndex]
    );
  };
  const permission = useSelector((state) => state.User?.permissionList);
  const validateName = (name) => {
   

    if (!role) {
      return "Role Tile is required.";
    } else if (role.length < 3 || role.length > 15) {
      return "Role should be between 3 and 15 characters long.";
    } else {
      return ""; // No validation error
    }
  };
  console.log(selectedOptions,"skfkjdshfkjshkjfh")

  const handleSubmit = async (e) => {
    e.preventDefault();
    const validationError = validateName(role);
    if (validationError) {
      setError(validationError);
      return; // Stop submission if there's a validation error
    }
  
    setError("");
    const permissionObjectsByModule = {};
    selectedPermissions.forEach((permission) => {
      const { permissionKey, module_id } = permission;
      if (!permissionObjectsByModule[module_id]) {
        permissionObjectsByModule[module_id] = {};
      }
      permissionObjectsByModule[module_id][permissionKey] = true;
    });

    // Convert the object into an array
    const selectedPermissionObjects = Object.keys(
      permissionObjectsByModule
    ).map((module_id) => ({
      module: parseInt(module_id),
      permissions: permissionObjectsByModule[module_id],
    }));
    const idArray = selectedPermissions.map((permission) => permission.id);
const id =selectedOptions.id

    const data = {
      name: role,
      roleType: "Backend",
      // service: 1,

      service: selectedOptions ? selectedOptions.id : null,
      business: action === "edit" ? businessData : businessData,
      permissions: idArray,
    };
    if (actions === "edit") {
      if(idArray.length===0){
        customSnackBar("Please Select Atleast one Permssion ")
      }else{
     await dispatch(
        editRoleRequest({
          data,
          id: selectedRole,
          setLoading,
          onClose,
          onRoleCreated,
        })
      );
      dispatch(fetchRoleRequest(businessData));
    dispatch(fetchProductRoleRequest({ id, businessData }));
    }

    } else {
      if(idArray.length===0){
        customSnackBar("Please Select Atleast one Permssion ")
      }else{
      dispatch(
        addRoleRequest({
          data,
          setLoading,
          onClose,
          onRoleCreated,
        })
      );
      dispatch(fetchRoleRequest(businessData));
      dispatch(fetchProductRoleRequest({ id, businessData }));
    }
  
  };
}
const allowedModules = ["Booking", "Notes", "Customer", "Break","Breaks" ,"Event", "TimeBlock", "Staff"];

  useEffect(() => {
    // setLoading(true)
    dispatch(fetchPermissionRequest({businessId:businessData,setLoader,serviceId:selectedOptions?.id}));
  }, [businessData.id]);
  const handlePermissionChange = (id,permissionKey, checked, module_id, index) => {
    if (checked) {
      setSelectedPermissions((prevSelectedPermissions) => [
        ...prevSelectedPermissions,
        { permissionKey, module_id,id },
      ]);
    } else {
      setSelectedPermissions((prevSelectedPermissions) =>
        prevSelectedPermissions.filter(
          (permission) =>
            permission.permissionKey !== permissionKey ||
            permission.module_id !== module_id
        )
      );
    }
  };
  const handleNameChange = (event) => {
    const value = event.target.value;
    setRole(value);
    setError(validateName(value));
  };  return (
    <>
       <div
        className="position-relative ">
        
        <div
        style={{
          position: "absolute",
          top: "7px",
          left: "-35px",
        }}
        onClick={onClose}
        role="button"
      >
        <i id="icon_left_color" className="fas fa-chevron-left"></i>
      </div>
        
        <div className="">
          <form onSubmit={handleSubmit}>
            
              <div className="card px-3 py-2  me-2 min-h-[250px] card-cont">
                <div className="d-flex align-items-center">
                  <div>
                    <Avatar
                      src={"/images/Group 29410.svg"}
                      alt={"not-found"}
                      variant="rounded"
                      style={{
                        width: "40px",
                        height: "40px",
                        marginRight: 10,
                      }}
                    />
                  </div>
                  <div className="font-size-sm text-capitalize">
                    <span className="bolds text-black">
                    {/* {"Calendex"} */}
                      {selectedOptions &&
                        selectedOptions?.name}
                    </span>
                  </div>
                </div>
              </div>
           
            

            <div className="w-100 pt-3">
              <InputTextField
                id="role"
                label="New Role"
                name="role"
                placeholder="Enter New Role"
                //size={20}
                variant="filled"
                value={role}
                onChange={handleNameChange}
                autoFocus
                sx={{
                  width: "100%",
                  backgroundColor: "#fff",
                }}
              />
            {error && <span className="text-danger f-12">{error}</span>}

            </div>

            <div className="d-flex p-2 pt-1">
              <AiOutlineQuestionCircle
                style={{ color: "#b2b2b2", fontSize: 18 }}
              />
              <span
                style={{ fontWeight: 400, fontSize: "14px" }}
                className="span ps-1 pb-1"
              >
                Permissions
              </span>
            </div>

            <>
            {loader ? (
              <div className="center-loader">
                <Spin />
              </div>
            ) : (
              <div className="accordion" id="permissionsAccordion">
    {permission &&
      permission
        .filter((data) => allowedModules.includes(data.name)) // Filter only allowed module names
        .map((data, index) => {
          const processPermissions = (permissions, level = 0) => {
            return permissions.map((permissionSet, permissionIndex) => (
              <div key={permissionIndex}>
                <label
                  style={{
                    fontSize: "12px",
                    display: "flex",
                    fontWeight: 400,
                    flexDirection: "row",
                    marginTop: "10px",
                    color: "#7B7B7B",
                  }}
                >
                  <div
                    className="custom-checkbox-wrapper"
                    onClick={() =>
                      handlePermissionChange(
                        permissionSet.id,
                        permissionSet.identifier,
                        !selectedPermissions.some(
                          (permission) =>
                            permission.permissionKey === permissionSet.identifier &&
                            permission.module_id === data.id
                        ),
                        data.id,
                        permissionIndex
                      )
                    }
                    style={{
                      marginBottom: "10px",
                      marginRight: "10px",
                      marginTop: "2px",
                      cursor: "pointer",
                    }}
                  >
                    <div
                      className={`custom-checkbox ${selectedPermissions.some(
                        (permission) =>
                          permission.permissionKey === permissionSet.identifier &&
                          permission.module_id === data.id
                      )
                        ? "checked"
                        : ""}`}
                      style={{
                        width: "15px",
                        height: "15px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        borderRadius: "4px",
                        backgroundColor: selectedPermissions.some(
                          (permission) =>
                            permission.permissionKey === permissionSet.identifier &&
                            permission.module_id === data.id
                        )
                          ? "#FFD705"
                          : "white",
                        border: selectedPermissions.some(
                          (permission) =>
                            permission.permissionKey === permissionSet.identifier &&
                            permission.module_id === data.id
                        )
                          ? "none"
                          : "1px solid #ccc",
                      }}
                    >
                      {selectedPermissions.some(
                        (permission) =>
                          permission.permissionKey === permissionSet.identifier &&
                          permission.module_id === data.id
                      ) && <span style={{ color: "white" }}>✔</span>}
                    </div>
                  </div>

                  {permissionSet?.children === null || permissionSet?.children?.length === 0
                    ? permissionSet.identifier
                    : permissionSet.title}

                  {permissionSet.has_child && (
                    <div
                      style={{
                        marginLeft: "10px",
                        cursor: "pointer",
                      }}
                      onClick={() => toggleChildrenVisibility(permissionIndex)}
                    >
                      <img src={arrowDown} alt="Toggle Children" />
                    </div>
                  )}
                </label>

                {permissionSet.has_child &&
                  expandedPermissions.includes(permissionIndex) &&
                  permissionSet.children && (
                    <div style={{ paddingLeft: "20px" }}>
                      {processPermissions(permissionSet.children, level + 1)}
                    </div>
                  )}
              </div>
            ));
          };

          return (
            <div className="accordion-item mb-3" key={index} style={{ borderRadius: "8px" }}>
              <h2 className="accordion-header" id={`heading-${index}`}>
                <button
                  className="accordion-button collapsed bg-white"
                  style={{
                    borderRadius: "8px",
                    padding: "10px",
                    fontSize: "14px",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target={`#collapse-${index}`}
                  aria-expanded="false"
                  aria-controls={`collapse-${index}`}
                >
                  <span className="text2 text-capitalize fw-normal text-d-flex">
                    {data.name}
                  </span>
                  <span>
                    <img src={arrowDown} alt="" />
                  </span>
                </button>
              </h2>

              <div
                id={`collapse-${index}`}
                className="accordion-collapse collapse"
                aria-labelledby={`heading-${index}`}
                data-bs-parent="#permissionsAccordion"
              >
                <div
                  className="accordion-body"
                  style={{
                    marginBottom: index !== permission.length - 1 ? "20px" : "0",
                    padding: "10px",
                  }}
                >
                  {processPermissions(data.module_permissions)}
                </div>
              </div>
            </div>
          );
        })}
  </div> 
            )}



          </>

          <div className="w-100 d-flex flex-wrap justify-content-center pt-5 !m-n2">
            <Button
              style={{
                textTransform: "capitalize",
                backgroundColor: "#FFD705",
                cursor: "pointer",
                color: "#FFFFFF",
                textAlign: "center",
                fontFamily: "Roboto",
                fontSize: "14px",
                fontStyle: "normal",
                borderRadius: "8px",
                fontWeight: 500,
                border: "none",
                boxShadow: "none",
                width: "40%",
              }}
              onClick={handleSubmit}
              variant="contained"

              disabled={loading}>
              {loading
                ? "Processing..."
                : actions === "edit"
                ? "Update"
                : "Save & Continue"}
            </Button>


            {/* <div className="p-2">
              <Buttonv
                type="submit"
                sx={{
                  width: "150px",
                  backgroundColor: "#FFD705",
                  boxShadow: "none",
                  height: "40px",
                  borderRadius: "10px",
                  textTransform: "capitalize",
                }}
                variant="contained"
                disabled={loading}>
                {loading ? "Creating..." : "Create Role"}
              </Buttonv>
            </div> */}
          </div>
          </form>
        </div>
      </div>
  
    
    </>
  );
};

export default Role;

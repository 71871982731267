import React, { useState, useEffect } from "react";
import InputTextField from "../TextField";
import DescriptionField from "../TextField/descriptionField";
import { Formik, Form, Field } from "formik";
import Button from "@mui/material/Button";
import { teamList } from "../../../utils/constant";
import { useLocation, useHistory } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import Header from "../Header";
import { validationSchema } from "../Department/helper";
import { formateData, teamInitialValue } from "./helper";
import { useDispatch, useSelector } from "react-redux";
import { addTeamRequest, updateTeamRequest } from "../../../Store/Team/actions";
import SideNavone from "../../../SideNavBar";
// import "./AddTeam.scss";
const AddTeam = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const action =
    location &&
    location.state &&
    location.state.data &&
    location.state.data.action;
  const editData =
    location &&
    location.state &&
    location.state.data &&
    location.state.data.data;
  const flag = true;
  const [loading, setLoading] = useState(false);
  const [department, setDepartment] = useState("");
  const [departmentType,setDepartmentType]= useState('')
  const departments = JSON.parse(localStorage.getItem("businessRecord"));
  const data = useSelector((state) => state.Department?.data);
  const [departmentOptions,setDepartmentOptions]=useState("")
  useEffect(() => {
    // Retrieve the data from local storage when the component mounts
    const data = localStorage.getItem("departmentRecord");
    if (data) {
      setDepartment(JSON.parse(data));
    }
  }, []);
  const createTeam = (values) => {
    const departmentid = departmentType;
    const data = formateData(values, departmentid);
    // const jsonData = JSON.stringify(data);
    // addTeam(jsonData).then((res) => {
    //   setLoading(false);
    //   if (res.code === 0) {
    //     success(teamList.ADD_TEAM);
    //     window.location = "/team";
    //   } else {
    //     error(res.message);
    //   }
    // });
    dispatch(
      addTeamRequest({
        data,
        setLoading,
        // history,
      })
    );
  };

  const updateTeam = (values) => {
    let id = editData.id;
    // setLoading(true);
    const data = formateData(values);
    // const jsonData = JSON.stringify(data);
    // editTeam(jsonData, id).then((res) => {
    //   setLoading(false);
    //   if (res.code === 0) {
    //     success(teamList.DEPARTMENT_UPDATED);
    //     window.location = "/team";
    //   } else {
    //     error(res.message);
    //   }
    // });
    dispatch(
      updateTeamRequest({
        id,
        data,
        setLoading,
        // history,
      })
    );
  };
  const submitForm = (values) => {
    if (action === "edit") {
      updateTeam(values);
    } else {
      createTeam(values);
    }
  };


  useEffect(() => {
    if (
      data &&
      data.length>0 && department&& department?.id
    ) {
     
        setDepartmentType(department?.id);
     
    }
}, [data,department?.id]);
useEffect(() => {
  if (data) {
  setDepartmentOptions(data);
  }
}, [departmentType]);

  return (
    <>
      <SideNavone>
        <Header
          flag={flag}
          heading={action === "edit" ? "Update Team" : teamList.CREATE_TEAM}
        />
        {/* <MenuTabList type="addTeam" /> */}

        <div
          className="padding_left_right pt-4 hide_scrollbar "
          style={{
            height: "100vh",
            overflow: "auto",
            paddingBottom: "180px",
          }}
        >
          {" "}
          <div className="w-100">
            <Formik
              initialValues={teamInitialValue(action, editData)}
              onSubmit={(values) => {
                submitForm(values);
              }}
              validationSchema={validationSchema}
            >
              {(options) => {
                const { handleSubmit, values, touched, errors, handleChange } =
                  options;
                return (
                  <Form onSubmit={handleSubmit}>
                
                    <div className="col-md-12  px-2 pb-2 mt-1 ">
                      <label className="label_text_department">Business</label>
                      {action === "edit" ? (
                        <div>
                          <span className="business_name">
                          {department?.business?.name}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <span className="business_name">
                          {department?.business?.name}
                          </span>
                        </div>
                      )}
                    </div>

                    <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
                      <label className="label_text_business">
                        Department Type
                      </label>

                      <select
                        type="text"
                        className="form-control1 changecolor m-0  p-0 "
                        id="department"
                        name="department"
                        value={departmentType}
                        onChange={(e) =>
                          {setDepartmentType(e.target.value);
                          options.setFieldValue("department", e.target.value)}
                        }
                      >
                        <option disabled value={""}>
                          Select Department
                        </option>
                        {departmentOptions &&
                          departmentOptions.map((v, i) => {
                            return <option value={v?.id}>{v?.name}</option>;
                          })}
                      </select>
                    </div>
                    {errors.department && (
                      <span className="text-danger f-12">
                        {errors.department}
                      </span>
                    )}
                    <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
                      <label className="label_text_business">Team Name</label>
                      <input
                        className="form-control1 input_style_fix"
                        label="Team Name"
                        name="name"
                        placeholder="Enter Team"
                        //size={20}
                        value={values.name}
                        touched={touched.name}
                        error={touched.name && errors.name}
                        onChange={handleChange}
                        variant="filled"
                      />
                    </div>
                    {errors.name && (
                      <span className="text-danger f-12">
                        {errors.name}
                      </span>
                    )}

                    <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
                      <label className="label_text_business">
                        Team Description
                      </label>
                      <textarea
                        className="form-control1 input_style_fix"
                        id="description"
                        label="Description"
                        name="description"
                        placeholder="Type Description Here..."
                        //size={20}
                        variant="filled"
                        value={values.description}
                        onChange={handleChange}
                        autoFocused
                        touched={touched.description}
                        rows={3}
                        error={touched.description && errors.description}
                        // onChange={(event) => setBusinessDes(event.target.value)}
                      ></textarea>
                    </div>
                    <div className="w-100 d-flex flex-wrap justify-content-end pt-3 !m-n2">
                      <div className="p-2">
                        <Button
                          sx={{
                            width: "150px",
                            backgroundColor: "#edf0f5",
                            height: "40px",
                            color: "#b6bec8",
                            borderRadius: "10px",
                            textTransform: "capitalize",
                            boxShadow: "none",
                            "&:hover": {
                              backgroundColor: "#c0c0c0",
                              color: "#ffffff",
                            },
                          }}
                          onClick={() => {
                            history.push("/team")
                          }}
                          variant="contained"
                        >
                          Cancel
                        </Button>
                      </div>
                      <div className="p-2">
                        <Button
                          type="submit"
                          sx={{
                            width: "150px",
                            backgroundColor: "#FFD705",
                            boxShadow: "none",
                            height: "40px",
                            borderRadius: "10px",
                            textTransform: "capitalize",
                            "&:hover": {
                    backgroundColor: "#FFD705",
                    color: "white",
                  boxShadow:"none",

                  },
                          }}
                          onClick={handleSubmit}
              disabled={loading}

                          variant="contained"
                        >
                          {loading ? (
                            <ClipLoader color="#fffff" size={30} />
                          ) : action === "edit" ? (
                            "Update"
                          ) : (
                            "Create"
                          )}{" "}
                        </Button>
                      </div>
                    </div>
                  </Form>
                );
              }}
            </Formik>
          </div>
        </div>
      </SideNavone>
    </>
  );
};

export default AddTeam;

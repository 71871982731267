import {
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
  ADD_USER_REQUEST,
  ADD_USER_SUCCESS,
  ADD_USER_FAILURE,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_FAILURE,
  DELETE_USER_REQUEST,
  DELETE_USER_SUCCESS,
  DELETE_USER_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  ADD_ROLE_REQUEST,
  ADD_ROLE_SUCCESS,
  ADD_ROLE_FAILURE,
  FETCH_ROLE_REQUEST,
  FETCH_ROLE_SUCCESS,
  FETCH_ROLE_FAILURE,
  ADD_PERMISSION_REQUEST,
  ADD_PERMISSION_SUCCESS,
  ADD_PERMISSION_FAILURE,
  FETCH_PERMISSION_REQUEST,
  FETCH_PERMISSION_SUCCESS,
  FETCH_PERMISSION_FAILURE,
  ALLOW_PERMISSION_REQUEST,
  ALLOW_PERMISSION_SUCCESS,
  ALLOW_PERMISSION_FAILURE,
  FETCH_SERVICE_ROLE,
  FETCH_SERVICE_ROLE_SUCCESS,
  FETCH_SERVICE_ROLE_FAILURE,
  FETCH_PROVIDERS_REQUEST,
  FETCH_PROVIDERS_REQUEST_FAILURE,
  FETCH_PROVIDERS_REQUEST_SUCCESS,
  EDIT_ROLE_REQUEST,
  EDIT_ROLE_SUCCESS,
  EDIT_ROLE_FAILURE,
  FETCH_CONTENT_PAGE_REQUEST,
  FETCH_CONTENT_PAGE_SUCCESS,
  FETCH_CONTENT_PAGE_FAILURE,
} from "./actionType";

// Action creators
export const fetchUserRequest = (id) => {
  return {
    type: FETCH_USER_REQUEST,
    payload: id,
  };
};

export const fetchUserSuccess = (data) => {
  return {
    type: FETCH_USER_SUCCESS,
    payload: data,
  };
};
export const fetchUserFailure = (error) => ({
  type: FETCH_USER_FAILURE,
  payload: error,
});
export const fetchContentPageRequest = (id) => {
  return {
    type: FETCH_CONTENT_PAGE_REQUEST,
    payload: id,
  };
};

export const fetchContentPagSuccess = (data) => {
  return {
    type: FETCH_CONTENT_PAGE_SUCCESS,
    payload: data,
  };
};
export const fetchContentPagFailure = (error) => ({
  type:FETCH_CONTENT_PAGE_FAILURE,
  payload: error,
});
export const fetchProductRoleRequest = (id) => {
  return {
    type: FETCH_SERVICE_ROLE,
    payload: id,
  };
};
export const fetchProductRoleSuccess = (data) => {
  return {
    type: FETCH_SERVICE_ROLE_SUCCESS,
    payload: data,
  };
};
export const fetchProductRoleFailure = (error) => ({
  type: FETCH_SERVICE_ROLE_FAILURE,
  payload: error,
});
export const fetchProductList = (id) => ({
  type: FETCH_PRODUCT_REQUEST,
  payload: id,
});
export const fetchProductListSuccess = (data) => {
  return {
    type: FETCH_PRODUCT_SUCCESS,
    payload: data,
  };
};
export const fetchProductListFailer = (data) => {
  return {
    type: FETCH_PRODUCT_FAILURE,
    payload: data,
  };
};

export const addUserRequest = (data) => {
  return {
    type: ADD_USER_REQUEST,
    payload: data,
  };
};
export const addUserSuccess = (data) => {
  return {
    type: ADD_USER_SUCCESS,
    payload: data,
  };
};

export const addUserFailure = (error) => ({
  type: ADD_USER_FAILURE,
  payload: error,
});

export const addPermissionRequest = (data) => {
  return {
    type: ADD_PERMISSION_REQUEST,
    payload: data,
  };
};
export const addPermissionSuccess = (data) => {
  return {
    type: ADD_PERMISSION_SUCCESS,
    payload: data,
  };
};

export const addPermissionFailure = (error) => ({
  type: ADD_PERMISSION_FAILURE,
  payload: error,
});

export const fetchPermissionRequest = (id) => {
  console.log(id,"sjfsdkfkdsh")
  return{
  type: FETCH_PERMISSION_REQUEST,
  payload: id,
  }
};

export const fetchPermissionSuccess = (data) => {
  return {
    type: FETCH_PERMISSION_SUCCESS,
    payload: data,
  };
};
export const fetchPermissionFailure = (error) => ({
  type: FETCH_PERMISSION_FAILURE,
  payload: error,
});

export const updateUserRequest = (data) => {
  return {
    type: UPDATE_USER_REQUEST,
    payload: data,
  };
};

export const updateUserSuccess = (data) => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: data,
  };
};

export const updateUserFailure = (error) => ({
  type: UPDATE_USER_FAILURE,
  payload: error,
});
export const deleteUserRequest = (id) => {
  return {
    type: DELETE_USER_REQUEST,
    payload: id,
  };
};

export const deleteUserSuccess = (id) => {
  return {
    type: DELETE_USER_SUCCESS,
    payload: id,
  };
};

export const deleteUserFailure = (error) => ({
  type: DELETE_USER_FAILURE,
  payload: error,
});

export const addRoleRequest = (data) => {
  return {
    type: ADD_ROLE_REQUEST,
    payload: data,
  };
};

export const addRoleSuccess = (data) => {
  return {
    type: ADD_ROLE_SUCCESS,
    payload: data,
  };
};

export const addRoleFailure = (error) => ({
  type: ADD_ROLE_FAILURE,
  payload: error,
});
export const editRoleRequest = (data) => {
  return {
    type: EDIT_ROLE_REQUEST,
    payload: data,
  };
};

export const editRoleSuccess = (data) => {
  return {
    type: EDIT_ROLE_SUCCESS,
    payload: data,
  };
};

export const editRoleFailure = (error) => ({
  type: EDIT_ROLE_FAILURE,
  payload: error,
});

export const allowPermissionRequest = (data) => {
  return {
    type: ALLOW_PERMISSION_REQUEST,
    payload: data,
  };
};

export const allowPermissionSuccess = (data) => {
  return {
    type: ALLOW_PERMISSION_SUCCESS,
    payload: data,
  };
};

export const allowPermisssionFailure = (error) => ({
  type: ALLOW_PERMISSION_FAILURE,
  payload: error,
});

export const fetchRoleRequest = (data) => {
  return {
    type: FETCH_ROLE_REQUEST,
    payload: data,
  };
};

export const fetchRoleSuccess = (data) => {
  return {
    type: FETCH_ROLE_SUCCESS,
    payload: data,
  };
};

export const fetchRoleFailure = (error) => ({
  type: FETCH_ROLE_FAILURE,
  payload: error,
});
export const fetchProvidersList = (id) => {
  return{
  type: FETCH_PROVIDERS_REQUEST,
  payload: id,
  }
}
export const fetchProvidersListSuccess = (id) => {
  return{
  type: FETCH_PROVIDERS_REQUEST_SUCCESS,
  payload: id,
  }
}
export const fetchProvidersListFailure = (id) => {
  return{
  type: FETCH_PROVIDERS_REQUEST_FAILURE,
  payload: id,
  providres:[]
  }
};


// // import axios from "axios";
// // import { logout } from "../Store/Auth/actions";
// // import { Logout, encryptToken, getCookiesData, removeAllCookies, removeAllLocalStorage, setCookiesData } from "./helper";
// // import { toast } from "react-toastify";
// // import CryptoJS from "crypto-js";

// // const instance = axios.create({
// //   baseURL: process.env.REACT_APP_BASE_URL,
// // });

// // const base64Key = "VPdp81HWSW/h4FEUtxVLYeY1bRB8V8Fn6JN27HKfC80=";
// // const base64Iv = "Hci4Z6mO3q43Xjfk/Jglfg==";
// // const encryptUserId = (userId) => {
// //   const key = CryptoJS.enc.Base64.parse(base64Key);
// //   const iv = CryptoJS.enc.Base64.parse(base64Iv);

// //   const encrypted = CryptoJS.AES.encrypt(userId, key, {
// //     iv: iv,
// //     // mode: CryptoJS.mode.CBC,
// //     // padding: CryptoJS.pad.Pkcs7,
// //   });

// //   return encrypted.toString(); // Return encrypted userId as a string
// // };
// // let isRefreshing = false;
// // let failedQueue = [];

// // // Process queue function to retry failed requests after token refresh
// // const processQueue = (error, token = null) => {
// //   failedQueue.forEach(prom => {
// //     if (token) {
// //       prom.resolve(instance(prom.config));
// //     } else {
// //       prom.reject(error);
// //     }
// //   });
// //   failedQueue = [];
// // };

// // // Request Interceptor
// // instance.interceptors.request.use(
// //   async (config) => {
// //     try {
// //       const authentication = getCookiesData("authtication");
// //       if (authentication?.token && config.url.includes("/pv/")) {
// //         config.headers.authorization = `Bearer ${authentication.token}`;
// //          // if (authentication?.userId) {
// //       const encryptedUserId = encryptUserId(authentication.userId);

// //       console.log(encryptedUserId, "encryptUserIdencryptUserId");

// //       config.headers["userId"] = encryptedUserId;
// //       }
// //       console.log(config, "configconfigconfig");
     
// //       // }

// //       return config;
// //     } catch (error) {
// //       return Promise.reject(error);
// //     }
// //   },
// //   (error) => {
// //     return Promise.reject(error);
// //   }
// // );
// // // Response Interceptor
// // instance.interceptors.response.use(
// //   (response) => response,
// //   async (error) => {
// //     const originalRequest = error.config;
// //     if (error?.response?.status === 401 && !originalRequest?._retry) {
// //       if (!isRefreshing) {
// //         originalRequest._retry = true;
// //         isRefreshing = true;

// //         try {
// //           const authentication = getCookiesData("authtication");
// //           const base64EncodedToken = btoa(authentication.refreshToken);

// //           const response = await instance.get(
// //             `signup_svc/pb/users/getnewRefreshToken?tokenModel=${base64EncodedToken}`
// //           );

// //           if (response.data.code === 0 && response.data.result) {
// //             const newToken = response.data.result.jwtToken;
// //             const newRefreshToken = response.data.result.jwtRefreshToken;

// //             setCookiesData(
// //               "authtication",
// //               JSON.stringify({
// //                 token: newToken,
// //                 refreshToken: newRefreshToken,
// //               })
// //             );
// //             const encryptedTokenStorage=encryptToken(newToken)
// //             const encryptedRefreshTokenStorage=encryptToken(newRefreshToken)
            
// //             localStorage.setItem('token', encryptedTokenStorage);
// //             localStorage.setItem('refreshToken', encryptedRefreshTokenStorage);
    
// //             originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
// //             processQueue(null, newToken);

// //             return instance(originalRequest);
// //           } else if (response.data.code === 403 || response.data.code === -1) {
//     //            let url = localStorage.getItem("url");
//     // window.location.href = url; // Redirect to the desired URL
//     //         alert("Your session has expired.");
//     //       }
//     //     } catch (refreshError) {
//     //       processQueue(refreshError, null);
//     //          let url = localStorage.getItem("url");
//     // window.location.href = url; // Redirect to the desired URL
// //         } finally {
// //           isRefreshing = false;
// //         }
// //       } else {
// //         return new Promise((resolve, reject) => {
// //           failedQueue.push({ resolve, reject, config: originalRequest });
// //         });
// //       }
// //     } else if (
// //       error.code === "ERR_NETWORK" ||
// //       error.code === "ERR_BAD_RESPONSE"
// //     ) {
// //       // customSnackBar("Something went wrong. Please try again.");
// //     }

// //     return Promise.reject(error);
// //   }
// // );

// // export default instance;

// import axios from "axios";
// import { logout } from "../Store/Auth/actions";
// import { Logout, getCookiesData, removeAllCookies, removeAllLocalStorage, setCookiesData } from "./helper";

// import { toast } from "react-toastify";
// import CryptoJS from "crypto-js";
// import { customSnackBar ,decryptToken,encryptToken} from ".";

// const instance = axios.create({
//   baseURL: process.env.REACT_APP_BASE_URL,
// });

// let isRefreshing = false;
// let failedQueue = [];

// // Process queue function to retry failed requests after token refresh
// const processQueue = (error, token = null) => {
//   failedQueue.forEach((prom) => {
//     if (token) {
//       console.log(token, "TOKENEN");
//       prom.resolve(instance(prom.config));
//     } else {
//       console.log(token, error, "TOKENEN");

//       prom.reject(error);
//     }
//   });
//   failedQueue = [];
// };

// const base64Key ="VPdp81HWSW/h4FEUtxVLYeY1bRB8V8Fn6JN27HKfC80=";
// const base64Iv = "Hci4Z6mO3q43Xjfk/Jglfg==";

// const encryptUserId = (userId) => {
//   console.log(JSON.stringify(userId), "userIduserId");
//   const key = CryptoJS.enc.Base64.parse(base64Key);
//   const iv = CryptoJS.enc.Base64.parse(base64Iv);

//   const encrypted = CryptoJS.AES.encrypt(JSON.stringify(userId), key, {
//     iv: iv,
//     // mode: CryptoJS.mode.CBC,
//     // padding: CryptoJS.pad.Pkcs7,
//   });
//   console.log(encrypted, "userIduserId");

//   return encrypted.toString(); // Return encrypted userId as a string
// };

// //         const encryptedUserId = encryptUserId(12568);

// instance.interceptors.request.use(
//   async (config) => {
//     try {
//       const authentication = getCookiesData("authtication");
//       console.log(authentication.userId, "authenticationauthentication");
//       const tokenExists = localStorage.getItem("token");
//       const user = localStorage.getItem("userId");
//       const userId = user?.firstName;
//       const decryptTokenExist = decryptToken(tokenExists);
//       console.log(
//         decryptTokenExist,
//         parseInt(user),
//         user,
//         JSON.parse(user),
//         userId,
//         "decryptTokenExistdecryptTokenExist"
//       );
//       if (decryptTokenExist && config.url.includes("/pv/")) {
//         config.headers["Authorization"] = `Bearer ${decryptTokenExist}`;
//         const encryptedUserId = encryptUserId(JSON.parse(user));

//         console.log(encryptedUserId, "encryptUserIdencryptUserId");

//         config.headers["userId"] = encryptedUserId;
//       }
//       console.log(config, "configconfigconfig");
//       // if (authentication?.userId) {

//       // }

//       return config;
//     } catch (error) {
//       return Promise.reject(error);
//     }
//   },
//   (error) => {
//     return Promise.reject(error);
//   }
// );

// instance.interceptors.response.use(
//   (response) => response,

//   async (error) => {
//     console.log(error, "errorerror");
//     const originalRequest = error.config;

//     // Check for 401 Unauthorized and 403 Forbidden errors
//     if (
//       (error?.response?.status === 401 && !originalRequest?._retry) ||
//       error?.response?.status === 403
//     ) {
//       if (!originalRequest._retry) {
//         originalRequest._retry = true;
//       } else {
//         return Promise.reject(error);
//       }

//       if (!isRefreshing) {
//         isRefreshing = true;

//         try {
//           const authentication = getCookiesData("authtication");
//           const tokenExists = localStorage.getItem("refreshToken");
//           const user = JSON.parse(localStorage.getItem("user"));

//           const decryptTokenExist = decryptToken(tokenExists);
//           const base64EncodedToken = btoa(decryptTokenExist);

//           const response = await instance.get(
//             `signup_svc/pb/users/getnewRefreshToken?tokenModel=${base64EncodedToken}`
//           );

//           if (response.data.code === 0 && response.data.result) {
//             const newToken = response.data.result.jwtToken;
//             const newRefreshToken = response.data.result.jwtRefreshToken;

//             // Save the new token and refresh token
//             setCookiesData(
//               "authtication",
//               JSON.stringify({
//                 token: newToken,
//                 refreshToken: newRefreshToken,
//               })
//             );
//             const encryptedTokenStorage = encryptToken(newToken);
//             const encryptedRefreshTokenStorage = encryptToken(newRefreshToken);

//             localStorage.setItem("token", encryptedTokenStorage);
//             localStorage.setItem("refreshToken", encryptedRefreshTokenStorage); 
//             //  localStorage.setItem("token", newToken);
//             // localStorage.setItem("refreshToken", newRefreshToken);

//             // Update original request with new token
//             originalRequest.headers["Authorization"] = `Bearer ${newToken}`;
//             const encryptedUserId = encryptUserId(JSON.parse(user));

//             originalRequest.headers["userId"] = encryptedUserId;

//             processQueue(null, newToken);

//             // Retry the original request
//             return instance(originalRequest);
//           } else {
//               let url = localStorage.getItem("url");
//     window.location.href = url; // Redirect to the desired URL
//             alert("Your session has expired.");
//           }
//         } catch (refreshError) {
//           processQueue(refreshError, null);
//              let url = localStorage.getItem("url");
//     window.location.href = url; // Redirect to the desired URL
//           return Promise.reject(refreshError);
//         } finally {
//           isRefreshing = false;
//         }
//       } else {
//         return new Promise((resolve, reject) => {
//           failedQueue.push({ resolve, reject, config: originalRequest });
//         });
//       }
//     } else if (
//       error.code === "ERR_NETWORK" ||
//       error.code === "ERR_BAD_RESPONSE"
//     ) {
//       customSnackBar("Something went wrong. Please try again.");
//     }

//     return Promise.reject(error);
//   }
// );

// export default instance;


import axios from "axios";
import CryptoJS from "crypto-js";
// import API_ENDPOINTS from "api/endpoints";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

// Add a request interceptor
instance.interceptors.request.use(
  async (config) => {
    // const base64Key = process.env.REACT_APP_BASE64_KEY;
    // const base64Iv = process.env.REACT_APP_BASE64_KEY_IV;
    const base64Key = "j6d7KKC+66unhDowhE6JJogaTpKrKBhnXTBO4YPBSnQ=";
    const base64Iv = "KTZInClCMhcJNGbQj2rN6w==";
    const encryptUserId = (userId) => {
      const key = CryptoJS.enc.Base64.parse(base64Key);
      const iv = CryptoJS.enc.Base64.parse(base64Iv);
      const encrypted = CryptoJS.AES.encrypt(userId, key, {
        iv: iv,
      });
      return encrypted.toString();
    };

    const encryptedId = encryptUserId(localStorage.getItem("userId"));
    
    try {
      if (config.url.includes("/pv/")) {
        const accessToken = localStorage.getItem("token");
        config.headers["userId"] = encryptedId;
        config.headers.authorization = `Bearer ${accessToken}`;
      }
      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error);
  }
);

instance.interceptors.response.use(
  async function (response) {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;

    // Combine 401 and 403 error handling into a single check
    if ((error?.response?.status === 401 || error?.response?.status === 403) && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const token = localStorage.getItem("refreshToken");
        const base64EncodedToken = btoa(token);

        const res = await instance.get(
          `signup_svc/pb/users/getnewRefreshToken?tokenModel=${base64EncodedToken}`,
          
        );

        // If the refresh token request returns 403, log out
        if (res.data.code === 403|| res.data.code === -1|| res.data.code === 1) {
          let url = localStorage.getItem("url");
              window.location.href = url;          // window.location.href = "/";
        }

        if (res.data.code === 0) {
          const { jwtToken, jwtRefreshToken } = res.data.result;

          if (jwtToken && jwtRefreshToken) {
            // Update localStorage with new tokens
            localStorage.setItem("token", jwtToken);
            localStorage.setItem("refreshToken", jwtRefreshToken);

            // Retry the original request with the new access token
            const newAccessToken = `Bearer ${jwtToken}`;
            originalRequest.headers["Authorization"] = newAccessToken;

            return instance(originalRequest);
          }
        }
      } catch (refreshError) {
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);

export default instance;

import React from "react";
import { BiSearch } from "react-icons/bi";
import InputTextField from "../../TextField";

const SearchBar = ({
  searchTerm,
  handleInputChange,
  searchResults,
  handleResultClick,
  message
}) => {
  return (
    <>
            <InputTextField
              id="Address"
              label="Address"
              name="address"
              // value={values.businessName}
              // onChange={(newValue) => {
              //   setFieldValue("businessName", newValue);
              // }}
              className="w-100"
              value={searchTerm}
              onChange={handleInputChange}
              placeholder="Search to find  business address"
              // error={touched.businessName && errors.businessName}
              //size={20}
              variant="filled"
              autoFocused
             
            />
        
        {message && (
        <span className="text-danger f-12">
          {message}
        </span>
      )}
        {searchResults.length > 0 && (
          <ul
            style={{
              listStyleType: "none",
              backgroundColor: "white",
              color: "black",
              width: "100%",
              // marginLeft: "1.4rem",
              textAlign: "left",
              maxHeight: "25vh",
              overflowY: "auto",
              borderBottomLeftRadius: "1rem",
              borderBottomRightRadius: "1rem",
              position: "absolute",
              top: "3rem",
              // left: "1rem",
              
            }}
          >
            {searchResults.map((result, index) => (
              <React.Fragment key={index}>
                <li onClick={() => handleResultClick(result)}>
                  {result.formatted}
                </li>
                <hr />
              </React.Fragment>
            ))}
          </ul>
        )}
      
    </>
  );
};

export default SearchBar;

import React from 'react'
import styles from './AppLayout.module.css'
import TopHeader from './Header/TopHeader'
import DynamicLayout from './DynamicLayout/DynamicLayout'
import TriLayout from './DynamicLayout/TryLayout'
import BottomHeader from './Header/BottomHeader'
import Sidebar from './SideNarBar/Sidebar'

const AppLayout = ({ allSpace, left, middle, right, name, isShow,OnSearch,data, rowData,setRowData }) => {
  return (
    <div className={`${
        name === "Calendar"
          ? styles.appLayoutCalender
          : styles.appLayout
      } `}>
        <header>
          <TopHeader />
          <BottomHeader
       data={data}

           OnSearch={OnSearch}
      rowData={rowData}
      setRowData={setRowData}
           name={name} />
        </header>
        <aside>
          <Sidebar />
        </aside>
        {isShow ? (
          <DynamicLayout allSpace={allSpace} name={name}/>
        ) : (
          <TriLayout left={left} middle={middle} right={right} />
        )}
      </div>
  )
}

export default AppLayout

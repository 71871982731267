import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-phone-input-2/lib/bootstrap.css";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  fetchCountryRequest,
  fetchIndustryRequest,
  fetchTypeRequest,
  getBusinessRequest,
  updateBusinessRequest,
} from "../../../../Store/Business/actions";
import { ClipLoader } from "react-spinners";
import CardLink from "./cardsLink";
import AppLayout from "../../../Layout/AppLayout";
import { customSnackBar } from "../../../../utils";
import { updateBusinessSuccess } from "../../../../Store/Business/actions";
import bottom from "../../../../Assets/Images/arrorbottommm.png";
import CustomPhoneInput from "./CustomPhoneInput";
import upload from "../../../../Assets/Images/uploadImg.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom";
import { Avatar } from "@mui/material";
import instance from "../../../../utils/utils";
import { IconButton } from "@mui/material";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";

const UserDetail = ({ businesProfile, permission }) => {
  const { id } = useParams();
  const userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);

  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [details, setDetails] = useState(false);
  const history = useHistory();
  const [businessType, setBusinessType] = useState(""); // Initialize with an empty string
  const typeListIndustry = useSelector((state) => state.Business.industryList);
  const [businessWebsite, setBusinessWebsite] = useState(
    businesProfile?.websiteUrl
  );

  const [updateimage, setUpdateimage] = useState(null);
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(
    businesProfile&&businesProfile?.Owner&&businesProfile?.Owner?.length&& businesProfile?.Owner[0]?.firstname
  );
  const [businessName, setBusinessName] = useState(businesProfile?.name);
  const typeList = useSelector((state) => state.Business.businessTypeList);
  const [businessIndustry, setBusinessIndustry] = useState(""); // Initialize with an empty string
  const [countryId, setCountryIdy] = useState(""); // Initialize with an empty string
  var countryList = useSelector((state) => state?.Business?.countryList);

  const [lastName, setLastName] = useState(
    businesProfile&&businesProfile?.Owner&&businesProfile?.Owner?.length&& businesProfile?.Owner[0]?.lastname

    );
  // const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(true); // Track component mount state
  const [nameError, setNameError] = useState("");
  const [name, setName] = useState("");

  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(name)) {
      return "Name should only contain letters and spaces.";
    }

    if (!businessName) {
      return "Busines Name is required.";
    } else if (businessName.length < 3 || businessName.length > 25) {
      return "BusinessName should be between 3 and 25 characters long.";
    } else {
      return ""; // No validation error
    }
  };
  const handleNameChangeBusiness = (event) => {
    const value = event.target.value;
    setBusinessName(value);
    setNameError(validateName(value));
  };
  const handleNameChange = (event) => {
    const value = event.target.value;
    setFirstName(value);
    setNameError(validateName(value));
  };
  useEffect(() => {
    // setBusinessType(businessData?.industry?.name);
    const fetchData = async () => {
      await dispatch(fetchIndustryRequest());
      await dispatch(fetchTypeRequest());
      await dispatch(fetchCountryRequest());

      // After fetching industry list, set the initial value from business profile
    };
    fetchData();
  }, [dispatch, businesProfile]);

  useEffect(() => {
    if (
      typeList &&
      typeList.length > 0 &&
      businesProfile &&
      businesProfile.businessType &&
      businesProfile.businessType.id
    ) {
      setTimeout(() => {
        setBusinessType(businesProfile.businessType.id);
      }, 300);
    }
  }, [typeList, businesProfile?.businessType?.id]);
  useEffect(() => {
    if (
      countryList &&
      countryList.length > 0 &&
      businesProfile &&
      businesProfile.country_id
    ) {
      setTimeout(() => {
        setCountryIdy(businesProfile.country_id);
      }, 300);
    }
  }, [countryList, businesProfile?.country_id]);
  useEffect(() => {
    if (
      typeListIndustry &&
      typeListIndustry.length > 0 &&
      businesProfile &&
      businesProfile.industry &&
      businesProfile.industry.id
    ) {
      setTimeout(() => {
        setBusinessIndustry(businesProfile.industry.id);
      }, 300);
    }
  }, [typeListIndustry, businesProfile?.industry?.id]);
  const handleLastNameChange = (event) => {
    const value = event.target.value;
    setLastName(value);
    setNameError(validateName(value));
  };
  const handleFileChange = async (event) => {
    setLoading(true);

    const selectedFile = event.target.files[0];

    // Validate file type (allow only jpg and jpeg)
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (selectedFile && allowedFileTypes.includes(selectedFile.type)) {
      setFile(selectedFile);

      // Create form data
      const formData = new FormData();
      formData.append("Id", businesProfile?.id);
      formData.append("ServiceName", "Business");
      formData.append("Source", "Plexaar");
      formData.append("Files", selectedFile);
      formData.append("Category", "main");
      formData.append("FileType", "i");
      formData.append("CreatedBy", userID);

      try {
        const response = await instance.post(
          `${process.env.REACT_APP_BASE_URL}/serviceinventory_svc/pv/ServiceAttachment/UploadAttachment`,
          formData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        setUpdateimage(response?.data?.paths[0]);
        setLoading(false);
      } catch (error) {
        setLoading(false);

        console.error("Error uploading image:", error);
      }
    } else {
      setLoading(false);
      customSnackBar("Invalid file type. Please select a jpg or jpeg file.");
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const handleback = () => {
    setDetails(false);
  };
  useEffect(() => {
    if (updateimage) {
      const postImageData = async () => {
        try {
          let data = {
            imageUrl: updateimage,
          };
          dispatch(
            updateBusinessRequest({
              data,
              setLoading,
              id,
              userID,
              action: "imageUplaod",
            })
          );
          dispatch(getBusinessRequest({ id, setLoading }));

          // window.location.reload();
        } catch (error) {
          customSnackBar(error);
        } finally {
          setLoading(false);
        }
      };
      postImageData();
    }
  }, [updateimage, businesProfile?.id]);
  const handleDetails = () => {
    setDetails(true);
  };
  const handleTypeChange = (event) => {
    const value = event.target.value;
    setBusinessType(value);
    // setNameError(validateName(value));
  };
  const handleTypeChangeIndustry = (event) => {
    const value = event.target.value;
    setBusinessIndustry(value);
    // setNameError(validateName(value));
  };
  const handleCountryChnage = (event) => {
    const value = event.target.value;
    setCountryIdy(value);
    // setNameError(validateName(value));
  };
  console.log(permission,"permissionpermission")
  const handleUpdate = async () => {
    if(permission === null ||
      permission?.canUpdateBusinessProfile ||
      permission?.canUpdateBusinessProfile === null ||
      permission?.canUpdateBusinessProfile === undefined ){
    // Perform validation before proceeding (e.g., business name, first name, etc.)
    if (
      !businessName ||
      !firstName ||
      !lastName ||
      !businessType ||
      !businessIndustry ||
      !countryId
    ) {
      customSnackBar("Please fill in all required fields.");
      return;
    }
    console.log("updatee");

    const updatedData = {
      "name":businessName,
      firstName,
      lastName,
      "businessType": businessType,
      "industry":businessIndustry,
      "countryId":countryId,
      "websiteUrl": businessWebsite,
      // imageUrl: updateimage, // Use the uploaded image URL if available
    };
    console.log(updatedData, "updatee");

    // setLoading(true); // Set loading state

    // try {
    // Call your dispatch action to update business
    await dispatch(
      updateBusinessRequest({
        data: updatedData,
        setLoading,
        id,
        userID,
        action: "update",
        setDetails,
        // You can pass any specific action type like "update"
      })
    );
    // dispatch(getBusinessRequest({ id, setLoading }));

    // Optionally, you can reload or show success message
    // customSnackBar("Business details updated successfully!");
    // history.push("/business-profile"); // Redirect to the business profile page or elsewhere
    // } catch (error) {
    //   // Handle error if the API call fails
    //   customSnackBar("An error occurred. Please try again.");
    //   console.error("Error updating business details:", error);
    // } finally {
    //   setLoading(false); // Turn off loading state
    // }
  }else{
    customSnackBar("You dont have permission to update business")
  }
  };
  return (
    <>
      <div className="position-relative">
        {details ? (
          <div
            style={{
              position: "absolute",
              top: 0,
              left: "-35px",
            }}
            role="button"
            onClick={handleback}>
            <i
              id="icon_left_color"
              className="fas fa-chevron-left"
              style={{
                position: "relative",
                top: "-4px",
              }}></i>
          </div>
        ) : (
          ""
        )}

        <>
          <div className={`${!isMobile ? "card card-content " : ""}`}>
            <div className="d-flex  justify-content-between">
              {isMobile ? (
                <div className={`${!isMobile ? "d-none " : ""}`}>
                  <IconButton
                    onClick={() => {
                      history.goBack();
                    }}
                    size="small">
                    <KeyboardBackspaceIcon
                      sx={{ fontSize: 24, color: "#AAAAAA" }}
                    />
                  </IconButton>
                </div>
              ) : null}
              <div
                className={`${
                  !isMobile
                    ? "d-flex justify-content-evenly"
                    : "d-flex flex-column justify-content-evenly"
                }`}>
                {loading ? (
                  <div
                    style={{
                      width: 100,
                      height: 100,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}>
                    <div
                      className="spinner-border text-primary mt-3"
                      role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : businesProfile?.imageURL === null ||
                  businesProfile?.imageURL === "" ? (
                  <Avatar className="img_profile_shape1" alt="nodata" />
                ) : (
                  <img
                    className=" img_profile_shape1"
                    src={
                      businesProfile
                        ? businesProfile?.imageUrl
                        : "/images/profile.png"
                    }
                    alt=""
                  />
                )}

                <div className="margin_neg_button1">
                  <div className="upload-btn-wrapper">
                    <img
                      className="pt-2 ps-1"
                      src="../images/camera.svg"
                      alt="nodata"
                    />

                    <input
                      type="file"
                      name="image"
                      onChange={handleFileChange}
                    />
                  </div>
                </div>
                <div className={`${!isMobile ? "p-4 " : "text-center"}`}>
                  <h6 className="mb-1 text-capitalize">
                    {businesProfile?.name}{" "}
                  </h6>
                  <p className="mb-0 text_color_account1 text-capitalize">
                    Business Account
                  </p>
                  <p className="mb-0 id_text_color">ID {businesProfile?.id}</p>
                </div>
              </div>
              {!details && (
                <div className="align-content-end">
                  <button
                    className="profile_button p-2"
                    onClick={handleDetails}>
                    Profile Details
                  </button>
                </div>
              )}
            </div>
          </div>
        </>
        {details ? (
          <>
            <div>
              <div className="d-flex mb-2 mt-3 ">
                <span className=" sub_text_color_account_alltext1 ps-1">
                  Organization Details
                </span>
              </div>
              <div className="row">
                <div className="col-md-6 pe-2">
                  <div
                    className="background_color_showboxes ps-2 pb-1 mb-2"
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Organization Name
                    </label>
                    <input
                      className="profile_input_fields p-0"
                      name="customerFirstName"
                      type="text"
                      id="input1"
                      onChange={handleNameChangeBusiness}
                      value={businessName}
                      placeholder="Enter Organization Name"
                    />
                  </div>
                  {nameError && (
                    <span className="text-danger m-0 f-12">{nameError}</span>
                  )}
                </div>

                <div className="col-md-6 ps-2">
                  <div
                    className="background_color_showboxes ps-2 pb-1 mb-2"
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "8px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Organization Type
                    </label>
                    <select
                      type="text"
                      className="form-control1 changecolor  m-0  p-0 "
                      id="businessIndustry"
                      name="businessIndustry"
                      value={businessIndustry}
                      onChange={handleTypeChangeIndustry}
                      style={{
                        border: "none",
                        outline: "none",
                        padding: "0",
                        margin: "0",
                        // height: "31px",
                      }}
                      // onChange={(e) =>
                      //   setFieldValue("businessType", e.target.value)
                      // }
                    >
                      <option disabled>Select Organization Type</option>
                      {typeListIndustry &&
                        typeListIndustry?.map((v, i) => {
                          return (
                            <option key={i} value={v?.id}>
                              {v?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pe-2">
                  <div
                    className="background_color_showboxes ps-2 pb-1 mb-2"
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "8px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Business Type
                    </label>
                    <select
                      type="text"
                      className="custom-dropdown changecolor m-0  p-0 "
                      id="businessType"
                      name="businessType"
                      value={businessType}
                      onChange={handleTypeChange}
                      style={{
                        border: "none",
                        outline: "none",
                        padding: "0",
                        margin: "0",
                        // height: "31px",
                      }}
                      // onChange={(e) => setFieldValue("industry", e.target.value)}
                    >
                      <option className="text-black-50" disabled>
                        Select Industry Type
                      </option>
                      {typeList &&
                        typeList.map((v, i) => {
                          return (
                            <option key={i} value={v?.id}>
                              {v?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>

                <div className="col-md-6 ps-2">
                  <div
                    className="background_color_showboxes ps-2 pb-1 mb-2"
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "8px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Country ID
                    </label>
                    {/* <input
                      className="profile_input_fields p-0"
                      name="customerFirstName"
                      type="text"
                      id="input1"
                      value={businesProfile?.country_id}
                      placeholder="Enter Country ID"
                    /> */}
                    <select
                      type="text"
                      name="countryId"
                      className="form-control1 changecolor m-0  p-0 "
                      id="countryId"
                      value={countryId}
                      onChange={handleCountryChnage}
                      style={{
                        border: "none",
                        outline: "none",
                        padding: "0",
                        margin: "0",
                        // height: "31px",
                      }}
                      // onChange={(e) => setFieldValue("countryId", e.target.value)}
                    >
                      <option value={""}>Select Country ID</option>
                      {countryList &&
                        countryList?.map((v, i) => {
                          return (
                            <option key={i} value={v?.id}>
                              {v?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div
                    className="background_color_showboxes ps-2 pb-2 mb-2"
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Website
                    </label>
                    <input
                      className="profile_input_fields p-0"
                      name="customerFirstName"
                      type="text"
                      id="input1"
                      onChange={(e) => setBusinessWebsite(e.target.value)}
                      value={businessWebsite}
                      placeholder="www.Expert.one"
                    />
                  </div>
                </div>
              </div>

              <div className="d-flex mt-3 pb-1">
                <span className="sub_text_color_account_alltext1 ps-1 ">
                  Business Owner
                </span>
              </div>
              {/* <div
                        className="background_color_showboxes px-2 pb-1 mb-2"
                        style={{
                          display: "grid",

                          alignItems: "center",
                          gap: "5px",
                        }}
                      >

                        <label className="profile_fields_label" style={{ marginBottom: 0 }}>Business Owner</label>
                        <div className=" d-flex align-items-center justify-content-between">
                          <input
                            className="profile_input_fields p-0"
                            name="customerPrimaryEmail"
                            type="text"
                            id="input1"
                            // value={businesProfile?.externalPlatFormName}
                            placeholder="Other"
                          />
                          <img
                            alt="img"
                            className="img-fluid"
                            src={bottom}
                          />
                        </div>
                      </div> */}
              <div className="row">
                <div className="col-md-6 pe-2">
                  <div
                    className="background_color_showboxes ps-2 pb-2 mb-2"
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      First Name
                    </label>
                    <input
                      className="profile_input_fields p-0"
                      name="customerFirstName"
                      type="text"
                      id="input1"
                      // value={businesProfile?.Owner[0]?.firstname}
                      value={firstName}
                      onChange={handleNameChange}
                      placeholder="Justin"
                    />
                  </div>
                  {/* {nameError && (
                            <span className="text-danger f-12">
                              {nameError}
                            </span>
                          )} */}
                </div>

                <div className="col-md-6 ps-2">
                  <div
                    className="background_color_showboxes ps-2 pb-1 mb-2"
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Last Name
                    </label>
                    <input
                      className="profile_input_fields p-0"
                      name="customerFirstName"
                      type="text"
                      id="input1"
                      value={lastName}
                      onChange={handleLastNameChange}
                      placeholder="Bergson"
                    />
                  </div>
                  {/* {nameError && (
                            <span className="text-danger f-12">
                              {nameError}
                            </span>
                          )} */}
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 pe-2 ">
                  {/* <div
                            className="background_color_showboxes px-1 mb-2">
                            <div className="input-group tel-input-group">
                              <span
                                className="input-label profile_fields_label "
                                style={{
                                  zIndex: 1,
                                  marginTop: "-7px",
                                  marginLeft: "-6px",
                                  paddingTop: "4px",
                                }}
                              >
                                Primary Mobile
                              </span>
                              <div>
                                <CustomPhoneInput
                                  styles="booking"
                                  className="international-phone"


                                />
                              </div>
                            </div>
                          </div> */}
                  <div
                    className="bg-white custom-border"
                    style={{
                      padding: "6px",
                      borderRadius: "8px",
                    }}>
                    <div className="input-group tel-input-group">
                      <span
                        className="input-label profile_fields_label mb-0"
                        style={{
                          zIndex: 1,
                          marginTop: "-7px",
                          marginLeft: "-6px",
                        }}>
                        Primary Mobile
                      </span>
                      <div>
                        <CustomPhoneInput
                          styles="booking"
                          className="international-phone"
                          value={businesProfile?.Owner[0]?.contactNo}
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 ps-2">
                  <div
                    className="background_color_showboxes ps-2 pb-2 mb-2"
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "5px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Email
                    </label>
                    <input
                      className="profile_input_fields p-0"
                      name="customerFirstName"
                      type="text"
                      id="input1"
                      disabled
                      value={businesProfile?.Owner[0]?.email}
                      placeholder="abc6486@gmail.com"
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex me-0 mt-3 pb-1">
                <span className=" sub_text_color_account_alltext1 ps-1">
                  Main Contact Person
                </span>
              </div>
              <div
                className="background_color_showboxes px-2 pb-1 mb-2"
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "5px",
                }}>
                <label
                  className="profile_fields_label"
                  style={{ marginBottom: 0 }}>
                  Contact Person
                </label>
                <div className=" d-flex align-items-center justify-content-between">
                  <input
                    className="profile_input_fields p-0"
                    name="customerPrimaryEmail"
                    type="text"
                    id="input1"
                    disabled
                    value={`${
                      businesProfile?.Owner &&
                      businesProfile.ContactPerson[0]?.firstname
                    } ${
                      businesProfile?.Owner &&
                      businesProfile.ContactPerson[0]?.lastname
                    }`}
                    placeholder="Raheem Dad"
                  />
                  <img alt="img" className="img-fluid" src={bottom} />
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button className="cancelBtn">Cancel</button>
                <button
                  disable={loading}
                  onClick={handleUpdate}
                  className="saveBtn ms-2">
                  {loading ? "Processing..." : "Update"}
                </button>
              </div>
            </div>
          </>
        ) : (
          <CardLink
            isMobile={isMobile}
            permission={permission}
            businesProfile={businesProfile}
          />
        )}
      </div>
    </>
  );
};
export default UserDetail;

import React, { useEffect, useState } from "react";
import Times from "./Times";
import convertToDateTime, { businessScheduleManage } from "../helper";
import dayjs from "dayjs";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
// import Times from "./Times"; // Ensure this import is correct
import { IconButton, Menu, MenuItem } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { AddScheduleSvg, RemoveScheduleSvg } from "../../../../Assets/svgs";
const Info = (props) => {
  const {
    open,
    values,
    handleChange,
    options,
    index,
    active,
    setFieldValue,
    isMobile,
    scheduleData,
    actionState,
    startTimeValues,
    endTimeValues,
    scheduleIndex
  } = props;
  const [slotStates, setSlotStates] = useState({});
  const [activeState, setActiveStates] = useState({});
  const [isMenuOpen, setMenuOpen] = useState(false);
  const handleClickActive = (index) => {
    // Create a copy of the slot states
    const newSlotStates = { ...slotStates };
    // Toggle the active state
    newSlotStates[index] = !newSlotStates[index];

    // Update the image source based on the active state

    // Update the slot states
    setSlotStates(newSlotStates);
    setFieldValue(`${index}.hourFormat`, newSlotStates[index]);
  };
  function convertToDateTime(inputTime) {
    // Check if the input time has the format "YYYY-MM-DDTHH:mm:ss"
    if (dayjs(inputTime, { strict: true }).isValid()) {
      return dayjs(inputTime);
    }

    // Parse input time as "HH:mm:ss"
    const currentTime = dayjs();
    const [hours, minutes, seconds] = inputTime.split(":");

    // Set the time components
    const formattedDateTime = currentTime
      .set("hour", parseInt(hours, 10))
      .set("minute", parseInt(minutes, 10))
      .set("second", parseInt(seconds, 10));

    return formattedDateTime;
  }
  const handleChanges = (index) => {
    const newSlotStates = { ...activeState };
    newSlotStates[index] = !newSlotStates[index];

    // Update the image source based on the active state

    // Update the slot states
    setActiveStates(newSlotStates);
    setFieldValue(`${index}.isAvailable`, newSlotStates[index]);
  };
  useEffect(() => {
    if (scheduleData && scheduleData.length > 0 && actionState === "edit") {
      // Map the scheduleData to match the initialValues structure
      const mappedData = scheduleData.map((item) => ({
        id: item.id,
        businessId: item.businessId,
        day: item.day,
        businessStartTime: convertToDateTime(`${item.businessStartTime}:00`),
        businessEndTime: convertToDateTime(`${item.businessEndTime}:00`),
        isAvailable: item.isAvailable,
        hourFormat:
          item.businessStartTime === "00:00" && item.businessEndTime === "23:59"
            ? true
            : false,
      }));

      // Set the mappedData as the initial values using setFieldValue
      setFieldValue("day", mappedData);
      localStorage.setItem("businessScularUpdatedRecord", mappedData);
    }
  }, [scheduleData, actionState, setFieldValue]);
  const handleAddTimeSlot = () => {
    // const newValues = [...values];
    // newValues.push({
    //   businessStartTime: "",
    //   businessEndTime: "",
    // });
    // setFieldValue("day", newValues);
  };

  const handleRemoveTimeSlot = (index) => {
    const newValues = [...values];
    newValues.splice(index, 1);
    setFieldValue("day", newValues);
  };
  return (
    <TableContainer sx={{border:"1px solid #FFD705"}} component={Paper}>
    <Table aria-label="schedule table">
      <TableHead
        className="text-capitalize font-size-sm font-weight-bold"
        sx={{
          backgroundColor: "#FFD705",
          height: "45px",
        }}>
        <TableRow>
          <TableCell
            style={{
              minWidth: 300,
              color: "#fff",
              position: "sticky",
              left: "auto",
              backgroundColor: "#FFD705",
            }}>
            Day
          </TableCell>
          <TableCell
            colSpan={2}  // Merges the next two cells
            style={{
              minWidth: 320,
              color: "#fff",
              position: "sticky",
              left: "auto",
              backgroundColor: "#FFD705",
            }}>
             <div className="row">
            <div className="col-md-5">From</div>
            <div className="col-md-5">To</div>
          </div>

          </TableCell>
          {/* <TableCell
            style={{
              minWidth: 10,
              color: "#fff",
              position: "sticky",
              left: "auto",
              backgroundColor: "#FFD705",
            }}>
            
          </TableCell> */}
        </TableRow>
      </TableHead>
      <TableBody>
        {values.map((dayData, index) => (
          <TableRow key={index}>
            <TableCell className="p-0">
              <div className="">
                <div className="px-2">
                  <label
                    className=""
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      width: "130px",
                      cursor: "pointer",
                    }}>
                    {/* {open&&( */}
                    <input
                      className="form-check-input"
                      type="checkbox"
                      value={dayData.isAvailable}
                      checked={dayData.isAvailable}
                      onChange={() => handleChanges(index)}
                      style={{
                        width: "20px",
                        height: "20px",
                        marginRight: "10px",
                        borderColor: "#E0E1E4",
                        backgroundColor: "#F8F9FF",
                      }}
                    />
                    {/* )} */}
                    <span className="new-span">{dayData.day}</span>
                  </label>
                </div>
              </div>
            </TableCell>
            <TableCell className="pt-2 pb-0">
              <Times
                options={dayData}
                index={index}
                isMobile={isMobile}
                records={options}
                active={slotStates[index]}
                setFieldValue={setFieldValue}
                {...options}
              />
            </TableCell>
            <TableCell>
              <div className={`${!isMobile ? "col-md-4" : "col-2"}`}>
                {/* <div className="row">
                  <div className={`${!isMobile ? "col-md-6" : "col-6"}`}>
                    <div className="button_schedular">
                      <div
                        variant="outlined"
                        disabled={active}
                        style={{ cursor: "pointer" }}
                        onClick={handleAddTimeSlot}>
                        {active ? (
                          <img alt="no data" src="../images/Layer_1 2.svg" />
                        ) : (
                          <img alt="no data" src="../images/Layer_1.svg" />
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    {startTimeValues && startTimeValues.length > 1 && (
                      <div className="button_schedular">
                        <div onClick={() => handleRemoveTimeSlot(index)}>
                          <img alt="no data" src="../images/crossButton.svg" />
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
                <div className="pb-3">
                    {/* {index === 0 ? ( */}
                      <>
                        <div onClick={handleAddTimeSlot}>
                          <AddScheduleSvg />
                        </div>
                        <div>
                          <Menu
                            anchorEl={isMenuOpen}
                            open={Boolean(isMenuOpen)}
                            onClose={() => setMenuOpen(null)}
                          >
                            <MenuItem onClick={handleAddTimeSlot}>Add</MenuItem>
                          </Menu>
                        </div>
                      </>
                    {/* ) : (
                      <div onClick={() => handleRemoveTimeSlot(index)}>
                        <RemoveScheduleSvg />
                      </div>
                    )} */}
                  </div>
              </div>
            </TableCell>
          </TableRow>
        ))}
        
      </TableBody>
    </Table>
  </TableContainer>
  
  
  );
};

export default Info;
 


import NewBusiness from "../Pages/BusinessProfile/NewBusiness/index";
import AddOwnerDetail from "../Pages/BusinessProfile/NewBusiness/OwnerDetail/OwnerDetail";
import ManageBusiness from "../Pages/BusinessProfile/Business/ManageBusiness";
import SelectAddress from "../Pages/BusinessProfile/NewBusiness/Addaddressfrommap/Addaddressfrommap";
// import BusinessColumnList from "../Pages/BusinessProfile/Business/ColumnList";
// import BusinessList from "../Pages/Dashboard/MainComponent/BusinessList";
// import SelectBusiness from "../Calendar/scheduler/layouts/BusinessCalendar";
// import BusinessSchedular from "../Pages/schedulerModule/pages/BusinessList";
import BusinessAddress from "../Pages/BusinessProfile/NewBusiness/BusinessAddress";
import BusinessSchdulars from "../Pages/BusinessProfile/NewBusiness/BusinessSchdular/index"
// import BusinessSchedularColumnList from "../Pages/schedulerModule/components/ColumnListBusiness";
import BusinessProfile from "../Pages/BusinessProfile/NewBusiness/BusinessProfiles/index"
import EditName from "../Pages/BusinessProfile/NewBusiness/BusinessProfiles/editbusinessName"
import BusinessAddressMap from "../Pages/BusinessProfile/NewBusiness/BusinessProfiles/busineeAddressMap"
import EditWebsite from "../Pages/BusinessProfile/NewBusiness/BusinessProfiles/editWebsite"
import EditBusienssType from "../Pages/BusinessProfile/NewBusiness/BusinessProfiles/editBusinessType"
import EditIndustry from "../Pages/BusinessProfile/NewBusiness/BusinessProfiles/editIndusty"
import EditBusinessAddress from "../Pages/BusinessProfile/NewBusiness/BusinessProfiles/editBusinessAddress"
import AddBusinessAddress from "../Pages/BusinessProfile/NewBusiness/BusinessAddress/index"
import EditOwnerDetails from "../Pages/BusinessProfile/NewBusiness/BusinessProfiles/BusinessOwner/editOwnerInfo";
import AddBusinessInfo from "../Pages/NewBusiness/AddBusiness/AddBusiness";
import ContentPage from "../Pages/ContentPage";
import ContentPageView from "../Pages/ContentPage/ViewTemplate";
import CreateContentPage from "../Pages/ContentPage/ContentCreation";
// import BusinessSchdulars from "../Pages/BusinessProfile/NewBusiness/BusinessSchdular/index"

export const BusinessRoute = [
  {
    path: "/business",
    component: ManageBusiness,
    ispublic: false,
    exact: true,
  },
  {
    path: "/",
    component: NewBusiness,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-owner-details",
    component: AddOwnerDetail,
    ispublic: false,
    exact: true,
  },
  {
    path: "/edit-owner-detail",
    component: EditOwnerDetails,
    ispublic: false,
    exact: true,
  },
  {
    path: "/select-address",
    component: SelectAddress,
    ispublic: false,
    exact: true,
  },

  {
    path: "/content-page",
    component:ContentPage ,
    ispublic: false,
    exact: true,
  },
  {
    path: "/view-template/:id",
    component:ContentPageView ,
    ispublic: false,
    exact: true,
  },
  {
    path: "/content-page-creation",
    component:CreateContentPage ,
    ispublic: false,
    exact: true,
  },
  {
    path: "/content-page-creation/:id",
    component:CreateContentPage ,
    ispublic: false,
    exact: true,
  },
  // {
  //   path: "/add-business",
  //   component: AddBusiness,
  //   ispublic: false,
  //   exact: true,
  // },
  // {
  //   path: "/select-business",
  //   component: SelectBusiness,
  //   ispublic: false,
  //   exact: true,
  // },
  // {
  //   path: "/business-staff",
  //   component: BusinessList,
  //   ispublic: false,
  //   exact: true,
  // },
  // {
  //   path: "/businessScheduler",
  //   component: BusinessSchedular,
  //   ispublic: false,
  //   exact: true,
  // },
  // {
  //   path: "/businessScheduler/columns-list",
  //   component: BusinessSchedularColumnList,
  //   ispublic: false,
  //   exact: true,
  // },
  {
    path: "/business-profile/:id",
    component: BusinessProfile,
    ispublic: false,
    exact: true,
  },
  {
    path: "/edit-business-name",
    component: EditName,
    ispublic: false,
    exact: true,
  },
  {
    path: "/edit-business-website",
    component: EditWebsite,
    ispublic: false,
    exact: true,
  },
  {
    path: "/edit-map",
    component:BusinessAddressMap,
    ispublic: false,
    exact: true,
  },
  {
    path: "/edit-business-type", 
    component:EditBusienssType,
    ispublic: false,
    exact: true,
  },
  {
    path: "/edit-industry-type",
    component:EditIndustry,
    ispublic: false,
    exact: true,
  },
  {
    path: "/edit-business-address",
    component:EditBusinessAddress,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-business-address",
    component: AddBusinessAddress,
    ispublic: false,
    exact: true,
  },
  {
    path: "/add-business-schedular/:id",
    component: BusinessSchdulars,
    ispublic: false,
    exact: true,
  },
];

import React, { useEffect, useState } from "react";
import AppLayout from "../../Layout/AppLayout";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { AddSvg, RemoveSvg } from "../../../Assets/svgs";
import { Button } from "@mui/material";
import instance from "../../../utils/utils";
import { customSnackBar } from "../../../utils";
import { ClipLoader } from "react-spinners";
import ImageLogoSummary from "./Sections/BusinessNameSection";
import ServiceSection from "./Sections/ServiceSection";
import OpeningHours from "./Sections/OpeningHourSection";
import AddressSection from "./Sections/AddressSection";
import BusinessGallery from "./Sections/BusinessGallery";
import Provider from "./Sections/ProviderListSection";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
const sectionComponents = {
  "Image-Logo-Business Name-Summary": ImageLogoSummary,
  "Service-Section": ServiceSection,
  "Opening-Hours": OpeningHours,
  "Business-Gallery": BusinessGallery,
  Address: AddressSection,
  "Provider-List": Provider,
};
const CreateContent = () => {
  const history = useHistory();
  const typeList = [
    { id: "1", name: "Red" },
    { id: "2", name: "Blue" },
    { id: "3", name: "Green & Blue" },
    { id: "4", name: "Blue" },
  ];
  const location = useLocation();
  const action = (location && location.state) || "";
  const { id } = useParams();

  const [sectionOrder, setSectionOrder] = useState([]);
  const [showSection, setShowSection] = useState(false);
  const [template, setTemplate] = useState("");
  const [color, setColor] = useState(typeList[0]?.id);
  const [sectionOn, setSectionOn] = useState(false);
  const [error, setError] = useState("");
  const [sectionLength, setSectionLength] = useState(sectionOrder.length); // State to store fetched sections
  const [sections, setSections] = useState([]); // State to store fetched sections
  const [pageSections, setpageSections] = useState([]); // State to store fetched sections
  const [templateResponse, setTemplateResponse] = useState(); // State to store fetched sections
  const [loader, setLoader] = useState(false);
  const businessRecrord = JSON.parse(localStorage.getItem("businessRecord"));
  const addSection = (section) => {
    if (!sectionOrder.find((item) => item.id === section.id)) {
      const updatedSectionOrder = [...sectionOrder, section];
      setSectionOrder(updatedSectionOrder);
      setSectionLength(updatedSectionOrder.length); // Set the correct length
    }
  };
  console.log(sectionOrder, "sectionOrdersectionOrder");

  const removeSection = (sectionId) => {
    const updatedSections = sectionOrder.filter(
      (item) => item.id !== sectionId
    );
    setSectionOrder(updatedSections);
    setSectionLength(updatedSections.length);
  };

  // Handle drag-and-drop reordering
  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination) return; // Drop outside valid area

    const reorderedSections = Array.from(sectionOrder);
    const [movedSection] = reorderedSections.splice(source.index, 1);
    reorderedSections.splice(destination.index, 0, movedSection);

    setSectionOrder(reorderedSections);
  };

  const userID = useSelector((state) => state.Auth.userloginsuccessyasir.id);
  const getSectionPosition = (id) => {
    const index = sectionOrder.findIndex((section) => section.id === id);
    return index !== -1 ? index + 1 : null;
  };
  const fetchSections = async () => {
    try {
      setLoader(true); // Set loader to true before API call
      const response = await instance.get(
        `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/section/list/`
      );

      console.log(response, "response");

      // Check for successful response
      if (response?.data?.code === 0) {
        const requiredSections = [
          "Image-Logo-Business Name-Summary",
          "Service-Section",
          "Opening-Hours",
          "Business-Gallery",
          "Address",
          "Provider-List",
        ];

        // Filter sections by matching names

        const result = response?.data?.result || [];
        const filteredSections = result.filter((section) =>
          requiredSections.includes(section.name)
        );

        setSections(filteredSections); // Save the result to state
      } else {
        console.error("Unexpected response code:", response?.data?.code);
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
    } finally {
      setLoader(false); // Set loader to false after API call completes
    }
  };

  const fetchSectionsList = async () => {
    try {
      setLoader(true); // Set loader to true before API call
      const newId = id ? id : templateResponse?.id;
      const response = await instance.get(
        `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page-service/page-content/by/service/?service_name=Business&entity_id=${businessRecrord?.id}&page_id=${newId}&is_mobile=false&entity_type=BUSINESS&is_admin=true`
        // `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page-service/page-content/by/service/?entity_id=${businessRecrord?.id}&page_id=${templateResponse?.id}&is_admin=true`
      );

      // Check for successful response
      if (response?.data?.code === 0) {
        const result = response?.data?.result?.sections || [];
        setpageSections(result);
        if (action && action === "edit") {
          const transformedData = result.map((item, index) => ({
            id: item.section_id, // Generate unique IDs
            is_deleted: false,
            name: item.section_name,
            description: item.section_description,
            created_at: new Date().toISOString(),
            created_by: "admin",
            updated_at: new Date().toISOString(),
            updated_by: "admin",
            position: item?.section_position,
          }));

          if (transformedData.length === 0) {
            history.push("/content-page");
            customSnackBar(response?.data?.message);
          } else {
            setSectionOrder(transformedData);
            setSectionLength(transformedData.length);
          }
        }
      } else {
        console.error("Unexpected response code:", response?.data?.code);
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
    } finally {
      setLoader(false); // Set loader to false after API call completes
    }
  };

  const fetchPageSection = async () => {
    try {
      setLoader(true); // Set loader to true before API call
      const newId = id ? id : templateResponse?.id;
      const response = await instance.get(
        `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page-section/by/page-id/?page_id=${newId}`
      );

      console.log(response, "response");

      // Check for successful response
      if (response?.data?.code === 0) {
        const result = response?.data?.result || [];
        if (action === "edit") {
          // setSectionOn(true);

          setShowSection(true);
        }
      } else {
        if (action === "edit") {
          history.push("/content-page");
          customSnackBar(response?.data?.message);
        }
        console.error("Unexpected response code:", response?.data?.code);
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
    } finally {
      setLoader(false); // Set loader to false after API call completes
    }
  };

  const fetchCreatedPages = async () => {
    try {
      setLoader(true); // Set loader to true before API call
      const response = await instance.get(
        `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page-service/page-content/by/service/?service_name=Business&entity_id=${businessRecrord?.id}&page_id=${templateResponse?.id}&is_mobile=false&entity_type=BUSINESS&is_admin=true`
      );

      console.log(response, "response");

      // Check for successful response
      if (response?.data?.code === 0) {
        const result = response?.data?.result || [];
        // setpageSections(result); // Save the result to state
      } else {
        console.error("Unexpected response code:", response?.data?.code);
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
    } finally {
      setLoader(false); // Set loader to false after API call completes
    }
  };

  const fecthSinglePage = async () => {
    try {
      setLoader(true); // Set loader to true before API call
      const response = await instance.get(
        `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page/?id=${id}`
      );

      console.log(response, "response");

      // Check for successful response
      if (response?.data?.code === 0) {
        const result = response?.data?.result || {};
        setTemplate(result?.name);
        setSectionOn(true);
      } else {
        console.error("Unexpected response code:", response?.data?.code);
      }
    } catch (error) {
      console.error("Error fetching section data:", error);
    } finally {
      setLoader(false); // Set loader to false after API call completes
    }
  };

  useEffect(() => {
    if (sectionOn) {
      fetchSections();
      fetchSectionsList();
      fetchPageSection();
    }
  }, [sectionOn]);

  useEffect(() => {
    if (action && action === "edit") {
      fetchPageSection();
      fecthSinglePage();
    }
  }, []);
  const validateName = (name) => {
    if (!name) {
      return "Tempalate Tile is required.";
    } else if (name.length < 2 || name.length > 15) {
      return "Tempalate should be between 3 and 15 characters long.";
    } else {
      return ""; // No validation error
    }
  };
  const handleSubmitTemplate = async (e) => {
    try {
      const validationError = validateName(template);
      if (validationError) {
        setError(validationError);
        return; // Stop submission if there's a validation error
      } else {
        setError("");
        setLoader(true);

        const body = {
          name: template,
          description: color,
        };
        const response = await instance.post(
          `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page/`,
          body
        );

        if (response.data.code === 0) {
          // Parse permissions and modules directly
          setTemplateResponse(response.data.result);
          setSectionOn(true);

          setLoader(false);
        } else {
          setLoader(false);
          customSnackBar("Something went wrong. Please try again.");
        }
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching role data:", error);
      customSnackBar(
        "An error occurred while fetching data. Please try again."
      );
    }
  };
  const handleSubmitSection = async () => {
    try {
      if (sectionOrder.length > 0) {
        setLoader(true);

        // Transform the data
        const transformedData = sectionOrder.map((item) => ({
          position: item.position,
          service_sku: "",
          page: templateResponse?.id,
          section: item.id,
          entity_id: businessRecrord?.id,
          entity_type: "BUSINESS",
        }));

        const response = await instance.post(
          `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page-section/`,
          transformedData
        );

        if (response.data.code === 0) {
          fetchSectionsList();
          setShowSection(true);

          setLoader(false);
        } else {
          setLoader(false);
          customSnackBar(response.data.message);
        }
      } else {
        customSnackBar("Please Select atleast one Section");
      }
    } catch (error) {
      setLoader(false);
      console.error("Error fetching role data:", error);
      customSnackBar(
        "An error occurred while fetching data. Please try again."
      );
    }
  };

  const handleSubmitAssignPage = async () => {
    if (sectionLength !== 0) {
      customSnackBar("Please submit all the content of the selected section");
    } else {
      try {
        setLoader(true);

        // Transform the data

        const data = {
          entity_SKU: null,
          entity_id: businessRecrord?.id,
          is_active: true,
          is_deleted: false,
          page: templateResponse?.id,
          service_name: businessRecrord?.name,
          entity_type: "BUSINESS",
        };

        const response = await instance.post(
          `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/page-service/`,
          data
        );

        if (response.data.code === 0) {
          // handleSubmitAllPageContent();
          // history.push(`/view-template/${templateResponse?.id}`);
          history.push(`/content-page`);
          setShowSection(false);

          setLoader(false);
        } else {
          setLoader(false);
          customSnackBar(response.data.message);
          setShowSection(false);

          history.push(`/content-page`);
        }
      } catch (error) {
        setLoader(false);
        console.error("Error fetching role data:", error);
        customSnackBar(
          "An error occurred while fetching data. Please try again."
        );
      }
    }
  };

  console.log(sectionOrder, sectionLength, "sectionOrdersectionOrder");
  return (
    <AppLayout
      name={action === "edit" ? "Update Template" : "Create Template"}
      isShow={showSection}
      allSpace={
        showSection ? (
          <div className="selected-sections mt-1">
            {sectionOrder.map((section, index) => {
              const SectionComponent = sectionComponents[section.name];

              const sectionDetails =
                pageSections.length > 0 &&
                pageSections.find((item) => item.section_name === section.name); // Find the section from the API data

              const sectionId = sectionDetails
                ? sectionDetails.section_id
                : null;

              return (
                <>
                  <div key={section.id} className="mb-4">
                    {SectionComponent ? (
                      <>
                        <SectionComponent
                          userID={userID}
                          sectionOrder={sectionLength}
                          setSectionLength={setSectionLength}
                          businessRecrord={businessRecrord}
                          pageId={templateResponse}
                          pageSections={pageSections}
                          fetchPageSection={fetchPageSection}
                          sectionId={sectionId} // Pass section_id to the component
                        />
                        <hr />
                      </>
                    ) : (
                      <p>Component not available</p>
                    )}
                  </div>
                </>
              );
            })}
            <div className="w-100 row pt-5 justify-content-center  ">
              <div className="col-md-4 "></div>

              <div className="col-md-4 p-2">
                <Button
                  type="submit"
                  sx={{
                    width: "100%",
                    backgroundColor: "#FFD705",
                    height: "40px",
                    borderRadius: "10px",
                    textTransform: "capitalize",
                    boxShadow: "none",

                    "&:hover": {
                      backgroundColor: "#FFD705",
                      color: "white",
                      boxShadow: "none",
                    },
                  }}
                  onClick={() => {
                    fetchCreatedPages();

                    handleSubmitAssignPage();
                  }}
                  variant="contained"
                  disabled={loader}>
                  {loader ? <ClipLoader color="#fffff" size={16} /> : "Save"}{" "}
                </Button>
              </div>
              <div className="col-md-4 "></div>
            </div>
          </div>
        ) : null
      }
      middle={
        <>
          {!showSection ? (
            <div className="container ">
              <div className="d-flex mb-2 ">
                <span className=" sub_text_color_account_alltext1 ps-1">
                  Basic Info
                </span>
              </div>
              <div className="row mb-2">
                <div className="col-md-6 pe-2">
                  <div
                    className="background_color_showboxes ps-2 pb-1 "
                    style={{
                      display: "grid",

                      alignItems: "center",
                      gap: "10px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Template Name
                    </label>
                    <input
                      className="profile_input_fields p-0"
                      type="text"
                      id="template-name"
                      label="Template Name"
                      name="templateName"
                      value={template}
                      onChange={(e) => {
                        setTemplate(e.target.value);
                        setError(validateName(e.target.value));
                      }}
                      //   error={touched.businessName && errors.businessName}
                      placeholder="Enter Template Name"
                    />
                  </div>
                  {error && <span className="text-danger f-12">{error}</span>}
                </div>

                <div className="col-md-6 ps-2">
                  <div
                    className="background_color_showboxes ps-2 pb-1 "
                    style={{
                      display: "grid",

                      alignItems: "center",
                      // gap: "15px",
                    }}>
                    <label className="profile_fields_label sub_text_color_account_alltext_show">
                      Color Scheme
                    </label>
                    <select
                      type="text"
                      className="form-control1 changecolor  m-0  p-0 "
                      id="color"
                      name="color"
                      value={color}
                      style={{
                        border: "none",
                        outline: "none",
                        padding: "0",
                        margin: "0",
                        height: "31px",
                      }}
                      onChange={(e) => setColor(e.target.value)}>
                      <option disabled>Select Color Type</option>
                      {typeList &&
                        typeList?.map((v, i) => {
                          return (
                            <option key={i} value={v?.id}>
                              {v?.name}
                            </option>
                          );
                        })}
                    </select>
                  </div>
                </div>
                {/* {errors.businessType && (
              <span className="text-danger f-12">{errors.businessType}</span>
            )} */}
              </div>
              <>
                {sectionOn ? (
                  <>
                    <div className="d-flex mb-3 mt-3 ">
                      <span className=" sub_text_color_account_alltext1 ps-1">
                        Section
                      </span>
                    </div>
                    <div className="d-flex  justify-content-between">
                      <div className="card">
                        <label className="profile_fields_label mb-3 sub_text_color_account_alltext_show">
                          Select Section
                        </label>{" "}
                        <div
                          className="hide_scrollbar"
                          style={{ height: "200px", overflow: "auto" }}>
                          {sections?.map((section) => {
                            return (
                              <div
                                key={section.id}
                                className="d-flex justify-content-between align-items-center mb-2 "
                                //   style={{
                                //     backgroundColor: "white",
                                //     borderRadius: "5px",
                                //   }}
                              >
                                <span className="content-text">
                                  {section.name}
                                </span>
                                <div
                                  className="cursor-pointer"
                                  onClick={() => addSection(section)}>
                                  <AddSvg />
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                      {/* Selected Sections */}
                      <div className="card">
                        <label className="profile_fields_label mb-3 sub_text_color_account_alltext_show">
                          Selection Order
                        </label>{" "}
                        <div
                          className="hide_scrollbar"
                          style={{ height: "200px", overflow: "auto" }}>
                          <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="sections">
                              {(provided) => (
                                <div
                                  {...provided.droppableProps}
                                  ref={provided.innerRef}
                                  style={{ minHeight: "190px" }}>
                                  {sectionOrder.length > 0 ? (
                                    sectionOrder.map((section, index) => (
                                      <Draggable
                                        key={section.id} // This must be unique and consistent
                                        draggableId={section.id.toString()} // Ensure it's a string
                                        index={index}>
                                        {(provided) => (
                                          <div
                                            ref={provided.innerRef}
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            className="d-flex justify-content-between align-items-center  mb-2 "
                                            style={{
                                              // backgroundColor: "#e9ecef",
                                              // borderRadius: "5px",
                                              ...provided.draggableProps.style,
                                            }}>
                                            <span className="content-text text-black">
                                              {index + 1}. {section.name}
                                            </span>
                                            <div
                                              className="cursor-pointer"
                                              onClick={() =>
                                                removeSection(section.id)
                                              }>
                                              <RemoveSvg />
                                            </div>
                                          </div>
                                        )}
                                      </Draggable>
                                    ))
                                  ) : (
                                    <div
                                      className="p-5 m-4 text-center"
                                      style={{
                                        color: "#aaa",
                                        fontSize: "14px",
                                      }}>
                                      No sections added yet.
                                    </div>
                                  )}
                                  {provided.placeholder}
                                </div>
                              )}
                            </Droppable>
                          </DragDropContext>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </>
              <div className="w-100 d-flex pt-5 justify-content-center  ">
                <div className="p-2">
                  <Button
                    type="submit"
                    sx={{
                      width: "100%",
                      backgroundColor: "#FFD705",
                      height: "40px",
                      borderRadius: "10px",
                      textTransform: "capitalize",
                      boxShadow: "none",

                      "&:hover": {
                        backgroundColor: "#FFD705",
                        color: "white",
                        boxShadow: "none",
                      },
                    }}
                    onClick={() => {
                      if (sectionOn) {
                        handleSubmitSection();
                      } else {
                        handleSubmitTemplate();
                      } // Update the onClick handler
                    }}
                    variant="contained"
                    disabled={loader}>
                    {loader ? (
                      <ClipLoader color="#fffff" size={16} />
                    ) : action === "edit" ? (
                      "Update  & Continue"
                    ) : (
                      "Save & Continue"
                    )}{" "}
                  </Button>
                </div>
              </div>
            </div>
          ) : null}
        </>
      }
    />
  );
};

export default CreateContent;

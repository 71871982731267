import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import "react-phone-input-2/lib/bootstrap.css";
// import "../AddBusiness/AddBusiness.scss";
import SideNavBar from "../../../../../SideNavBar";
import { Button } from "@mui/material";
import { ClipLoader } from "react-spinners";
import { updateUserRequest } from "../../../../../Store/User/actions";

const EditOwnerInfo = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const businesProfile = useSelector((state) => state?.Business?.businesProfile);
  const id = businesProfile?.Owner[0]?.id;
  const userID = useSelector(
    (state) => state?.Auth?.userloginsuccessyasir?.id
  );

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [loading, setLoading] = useState(false);
  const [mounted, setMounted] = useState(true); // Track component mount state
  const [nameError, setNameError] = useState("");
  const validateName = (name) => {
    const nameRegex = /^[a-zA-Z\s]*$/;
    if (!nameRegex.test(name)) {
      return "Name should only contain letters and spaces.";
    }
    return "";
  };
  const handleNameChange = (event) => {
    const value = event.target.value;
    setFirstName(value);
    setNameError(validateName(value));
  };

  const handleLastNameChange = (event) => {
    const value = event.target.value;
    setLastName(value);
    setNameError(validateName(value));
  };

  const handleSubmit = () => {
    if (mounted) {
      setLoading(true);
      const data = {
        firstname: firstName,
        lastname:lastName,
        business:businesProfile?.id
      };
      const businessId=businesProfile?.id;
      const action="businessProfile"
      dispatch(updateUserRequest({ data, setLoading, id, userID,action,businessId}));
    }
  };

  useEffect(() => {
    return () => {
      // This cleanup function is called when the component unmounts.
      // Set the mounted state to false when the component unmounts.
      setMounted(false);
    };
  }, []);
  useEffect(() => {
    if(businesProfile){
      setFirstName(businesProfile?.Owner[0]?.firstname || "");
      setLastName(businesProfile?.Owner[0]?.lastname || "");
    }
  }, [businesProfile]);

  return (
    <SideNavBar>
      <div
        className="padding_left_right hide_scrollbar  mt-2 "
        style={{ height: "100vh", paddingBottom: "100px", overflow: "auto" }}
      >
        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">First Name</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="firstname"
            label="First Name"
            name="firstName"
            value={firstName}
            onChange={handleNameChange}
            // onChange={handleChange}
            placeholder="Enter First Name"
            // placeholder="Enter Organization Name"
            // onChange={(event) => {
            //     setFirstName(event.target.value);
            //     clearError('firstName'); // Clear the error
            //   }}
          />
        </div>
        {nameError && (
          <span className="text-danger f-12">{nameError}</span>
        )}

        <div className="col-md-12 border_rad_new px-2 pb-2 mt-3 ">
          <label className="label_text_business">Last Name</label>
          <input
            className="form-control1 input_style_fix"
            type="text"
            id="last-name"
            label="Last Name"
            name="lastName"
            value={lastName}
            onChange={handleLastNameChange}
            // onChange={handleChange}
            placeholder="Enter Last Name"
            // placeholder="Enter Organization Name"
            // onChange={(event) => {
            //     setFirstName(event.target.value);
            //     clearError('firstName'); // Clear the error
            //   }}
          />
        </div>
        {nameError && (
          <span className="text-danger f-12">{nameError}</span>
        )}
        <div className="w-100 d-flex mt-5 pt-5 flex-wrap justify-content-end ">
          <div className="pe-2">
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#edf0f5",
                height: "40px",
                color: "#b6bec8",
                borderRadius: "10px",
                textTransform: "capitalize",
                "&:hover": {
                  backgroundColor: "#c0c0c0",
                  color: "#ffffff",
                },
              }}
              onClick={() => {
                history.goBack();
              }}
              variant="contained"
              className=" btn btn-secondary padding_next_secondary_button"
            >
              {"Cancel"}
            </Button>
          </div>
          <div>
            <Button
              sx={{
                width: "150px",
                backgroundColor: "#FFD705",
                height: "40px",
                borderRadius: "10px",
                textTransform: "capitalize",
              }}
              className="padding_next_primary_button"
              onClick={handleSubmit}
              variant="contained"
            >
              {loading ? <ClipLoader color="#fffff" size={30} /> : "Update"}
            </Button>
          </div>
        </div>
      </div>
    </SideNavBar>
  );
};

export default EditOwnerInfo;

import React, { useState } from "react";
import { ClipLoader } from "react-spinners";
import instance from "../../../../utils/utils";
import BusinessContentImage from "../../../../Assets/svgs/NEWiMAGE.svg";
import styles from "../style.module.css";
import { customSnackBar } from "../../../../utils";

const BusinessGallery = ({
  sectionId,
  pageId,
  fetchPageSection,
  businessRecrord,
  setSectionLength,
  sectionOrder,
  userID
}) => {
  const [formData, setFormData] = useState({
    uploadedImages: [],
    titleText: "Text",
    subText: "Text",
  });
  const [errors, setErrors] = useState({});
  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const [submit, setSubmit] = useState(false);
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: false }); // Clear error on change
  };
  const handleFileUpload = async (event, imageType) => {
    const selectedFiles = Array.from(event.target.files);
    const allowedFileTypes = ["image/jpeg", "image/jpg", "image/png"];

    // Filter valid files
    const validFiles = selectedFiles.filter((file) =>
      allowedFileTypes.includes(file.type)
    );

    if (validFiles.length === 0) {
      customSnackBar("Please upload only jpg, jpeg, or png files.");
      return;
    }

    setIsLoadingImage(true);
    const uploadedPaths = [];

    for (const file of validFiles) {
      const uploadData = new FormData();
      uploadData.append("Id", 16);
      uploadData.append("ServiceName", "serviceinventory");
      uploadData.append("Source", "Plexaar");
      uploadData.append("Files", file);
      uploadData.append("Category", "main");
      uploadData.append("FileType", "i");
      uploadData.append("CreatedBy", userID);

      try {
        const response = await instance.post(
          `${process.env.REACT_APP_BASE_URL}/serviceinventory_svc/pv/ServiceAttachment/UploadAttachment`,
          uploadData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (response.data.code === 0) {
          uploadedPaths.push(response.data.paths[0]);
        } else {
          customSnackBar("Error uploading one or more images.");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
        customSnackBar("Error uploading one or more images. Please try again.");
      }
    }

    // Update formData with uploaded paths
    setFormData((prevData) => ({
      ...prevData,
      [imageType]: [...prevData[imageType], ...uploadedPaths],
    }));

    setIsLoadingImage(false);
  };
  const handleSubmit = async () => {
    const positions = {
      titleText: 1,
      subText: 2,
      uploadedImagesStart: 3,
    };
    const variation = {
      titleText: 1,
      subText: 2,
      uploadedImagesStart: 4,
    };

    // Validate fields
    const newErrors = {
      titleText: !formData.titleText || formData.titleText === "Text",
      subText: !formData.subText || formData.subText === "Text",
      uploadedImages:
        !formData.uploadedImages || formData.uploadedImages.length === 0,
      // uploadBannerImage: !formData.uploadBannerImage, // Uncomment if required
    };

    setErrors(newErrors);

    const hasErrors = Object.values(newErrors).some((error) => error);

    if (!hasErrors) {
      let currentPosition = positions.uploadedImagesStart;

      // Build submission data
      const submissionData = [];

      // Add text fields to submission data
      Object.keys(formData).forEach((key) => {
        if (key !== "uploadedImages") {
          let contentType = 1; // Default: 1 for text
          let contentVariation = formData.titleText ? 1 : 2; // Default: 1 for text

          submissionData.push({
            service_sku: "",
            section_id: sectionId,
            page_id: pageId?.id,
            entity_id: businessRecrord?.id,
            entity_type: "BUSINESS",
            content: formData[key],
            attachment_url: null,
            alignment: null,
            content_Width: 2147483647,
            content_Height: 2147483647,
            content_style: null,
            position: positions[key],
            font: null,
            font_color: null,
            font_family: null,
            font_style: null,
            font_size: null,
            content_type: contentType,
            content_variation: variation[key],
          });
        }
      });

      // Add uploaded images to submission data
      formData.uploadedImages.forEach((imageUrl) => {
        submissionData.push({
          service_sku: "",
          section_id: sectionId,
          page_id: pageId?.id,
          content: null,
          attachment_url: imageUrl,
          alignment: null,
          content_Width: 2147483647,
          content_Height: 2147483647,
          content_style: null,
          entity_id: businessRecrord?.id,
          entity_type: "BUSINESS",
          position: currentPosition++,
          font: null,
          font_color: null,
          font_family: null,
          font_style: null,
          font_size: null,
          content_type: 2, // Images have content_type = 2
          content_variation: 4, // Images have content_variation = 4
        });
      });

      // Attach API call
      try {
        setLoading(true);
        const response = await instance.post(
          `https://expertgateway-staging.findanexpert.net/dynamicpage_svc/content/`,
          submissionData
        );

        if (response.data.code === 0) {
          console.log("API Response:", response.data);
          fetchPageSection();
          setSectionLength(sectionOrder - 1);
          setLoading(false);
          setSubmit(true);
          customSnackBar("Form Submitted Successfully!");
        } else {
          setLoading(false);

          console.error("Error:", response.data.message);
          customSnackBar("Failed to submit form. Please try again.");
        }
      } catch (error) {
        setLoading(false);

        console.error("API Error:", error);
        customSnackBar(
          "Error submitting form. Please check your network or try again."
        );
      }
    } else {
      customSnackBar("Please fill all the required fields!");
    }
  };

  return (
    <div>
      <div className="row ">
        <div className="col-md-4"></div>
        <div className="col-md-4">
          <div className="pt-4">
            {/* Editable Title */}
            <div className={styles.largeBox}>
              <input
                type="text"
                name="titleText"
                placeholder="Text"
                value={formData.titleText}
                onChange={handleInputChange}
                style={{
                  backgroundColor: "transparent",
                  color: "#000",
                  fontSize: "24px",
                  fontWeight: 500,
                  textAlign: "center",
                }}
                className={`${styles.inputField} p-0 ${
                  errors.titleText ? styles.errorBorder : ""
                }`}
              />
              {errors.titleText && (
                <span className={styles.errorText}>Title is required</span>
              )}
            </div>
            <div className={`${styles.largeBox} mt-2`}>
              <textarea
                type="text"
                name="subText"
                placeholder="Enter Title"
                value={formData.subText}
                onChange={handleInputChange}
                style={{
                  height: 45,
                  textAlign: "center",
                }}
                className={`${styles.textareaField} ${
                  errors.subText ? styles.errorBorder : ""
                }`}
              />
              {errors.subText && (
                <span className={styles.errorText}>Title is required</span>
              )}{" "}
            </div>
          </div>
        </div>
        <div className="col-md-4"></div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center pt-4">
          <label className={styles.uploadLabel}>
            <input
              type="file"
              accept="image/*"
              multiple
              onChange={(e) => handleFileUpload(e, "uploadedImages")}
              style={{ display: "none" }}
            />
            {isLoadingImage ? (
              <ClipLoader size={25} />
            ) : (
              <div className={styles.uploadButton}>Click to Upload Images</div>
            )}
          </label>
          {errors.uploadedImages && (
            <span className={styles.errorText}>
              Please upload at least one image.
            </span>
          )}
        </div>
      </div>

      <div className="row pt-4">
        <div className="d-flex flex-wrap justify-content-around">
          {formData.uploadedImages.length > 0 ? (
            formData.uploadedImages.map((imagePath, index) => (
              <div key={index} className={styles.backgroundImageColor}>
                <img
                  alt="Uploaded"
                  src={imagePath}
                  style={{
                    width: "100px",
                    height: "100px",
                    objectFit: "cover",
                  }}
                />
              </div>
            ))
          ) : (
            <div className={styles.backgroundImageColor}>
              <img
                alt="Placeholder"
                src={BusinessContentImage}
                style={{ width: "100px", height: "100px", objectFit: "cover" }}
              />
            </div>
          )}
        </div>
      </div>

      <div className={styles.saveContainer}>
        <button
  disabled={loading || submit} // Fixed typo here
  className={styles.submitButton}
          onClick={handleSubmit}>
          {loading ? (
            <ClipLoader size={10} color="#fff" />
          ) : submit ? (
            "Submitted"
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </div>
  );
};

export default BusinessGallery;

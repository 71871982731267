import React from "react";
import { CiSearch } from "react-icons/ci";

const SearchBar = ({
  searchTerm,
  handleInputChange,
  searchResults,
  handleResultClick,
  message,
}) => {
  // Define a validation function to check if the search term is empty

  return (
    <>
      <div
        className="position-relative"
        style={{ zIndex: "1000", width: "100%" }}>
        <div className="d-flex align-items-center w-100">
           
              <div className="d-flex w-100">
                <div className="position-relative w-100">
                  <input
                    className="dynamicSearchInput w-100"
                    type="text"
                    onClick={(e) => {
                      e.preventDefault();
                    }}
                    id="Address"
                    name="address"
                    value={searchTerm}
                    onChange={handleInputChange}
                    placeholder="Type Your Address"
                    // placeholder="Search"
                  />

                  <span className="dynamicSearch">
                    <CiSearch color="#969696" size={20} />
                  </span>
                </div>
              </div>
        </div>
        {message && <span className="text-danger f-12">{message}</span>}
        {searchResults.length > 0 && (
          <ul
            style={{
              backgroundColor: "white",
              color: "black",
              width: "100%",
              textAlign: "left",
              maxHeight: "25vh",
              overflowY: "auto",
              fontSize: "12px",
              borderBottomLeftRadius: "1rem",
              borderBottomRightRadius: "1rem",
              position: "absolute",
            }}>
            {searchResults.map((result, index) => (
              <React.Fragment key={index}>
                <li onClick={() => handleResultClick(result)}>
                  {result.formatted}
                </li>
                <hr />
              </React.Fragment>
            ))}
          </ul>
        )}
      </div>
    </>
  );
};

export default SearchBar;

import React, { useCallback, useEffect, useRef, useState } from "react";
import InputTextField from "../../TextField";
import DescriptionField from "../../TextField/descriptionField";
import SideNavone from "../../../../SideNavBar";
import { Form, Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  businessAddressInitialValue,
  formateUpdateBusinessAddress,
  formateUpdateData,
  validationSchemaOfBusinessAddress,
} from "../../Business/helper";
// import "../AddBusiness/AddBusiness.scss";
import {
  addBusinessAddressesRequest,
  getBusinessRequest,
  updateBusinessRequest,
} from "../../../../Store/Business/actions";

import { formateData, formateDataUpdate, initialValues } from "../helper";
import { businessDatas } from "../../User/helper";
import { TimePicker } from "@mui/lab";
import AdapterDateFns from "@mui/lab/AdapterDateFns"; // Import the date adapter for localization
import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { Button, IconButton, TextField } from "@mui/material";
import { renderTimeViewClock } from "@mui/x-date-pickers/timeViewRenderers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Times from "./Times";
import { ClipLoader } from "react-spinners";
import axios from "axios";
import {
  useHistory,
  useLocation,
} from "react-router-dom/cjs/react-router-dom.min";
import Info from "./info";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { customSnackBar } from "../../../../utils";
import instance from "../../../../utils/utils";
import LeftSection from "./LeftSection/LeftSection";
import RightSection from "./RightSection/RightSection";
import Schdular from "./Schudule";
import AppLayout from "../../../Layout/AppLayout";

const BusinessSchdular = (props) => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const formRef = useRef();

  const history = useHistory();
  const location = useLocation();
  const selectedBusiness = useSelector(
    (state) => state.Business?.selectedBusiness
  );
  const businessRecrord = JSON.parse(localStorage.getItem("businessRecord"));
  // const businesProfile = useSelector((state) => state.Business.businesProfile);

  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [business, setBusiness] = useState([]);
  const [scheduleData, setScheduleData] = useState({});
  const [scheduleRecord, setScheduleDRecord] = useState({});
  const [isMobile, setIsMobile] = useState(false);
  const [isTablet, setIsTablet] = useState(false);
  const [open, setOpen] = useState(false);

  const actions = location?.state?.action;
  const editData = scheduleData.data || "";

  useEffect(() => {
    if (id) {
      dispatch(getBusinessRequest({ id, setLoading }));
    }
  }, [id]);
  const handleOpen = () => {
    // customSnackBar("Time is In past you cannot Select that");
    setOpen(true); // Open the modal by setting open state to true
  };
  // Close the popup by setting open state to false and all the selected states to default
  const handleClose = () => {
    setOpen(false);
    // clearStates();
    // Close the modal by setting open state to false and all the selected states to default
  };

  let myData = {};
  useEffect(() => {
    if (actions !== "new" || actions !== "add-schdule") {
      const businessData = localStorage.getItem("businessRecord");
      myData = JSON.parse(businessData);
      // setBusiness(JSON.parse(business));
    }
  }, []);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 600);
      setIsTablet(window.innerWidth >= 600 && window.innerWidth <= 1200);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  const actionState = scheduleData.actionState || "new";
  useEffect(() => {
    if (actionState === "edit") {
      const businessDataUpdate = localStorage.getItem(
        "businessScularUpdatedRecord"
      );
      myData = JSON.parse(businessDataUpdate);
      setScheduleDRecord(businessDataUpdate);
    }
  }, []);
  const busiessId =
    actions === "new"
      ? id
      : actions === "add-schdule"
        ? selectedBusiness.id
        : myData.id;
  const AddSchedule = async (values) => {
    setLoading(true);

    const data = formateData(values, id);

    await instance

      .post(
        `/provideravailability_svc/pv/business/`,
        { data, timeZone: "Europe/London" }
      )
      .then((res) => {
        if (res?.data?.statusCode === 0) {
          handleClose()
          fetchBusinessSchdular(formRef.current);

          // if (actions !== "new") {
          //   history.push(`/business-profile/${selectedBusiness.id}`);

          //   // history.push("/business/");
          // // } else if (actions === "add-schdule") {
          // //   history.push(`/calendar`);
          // } else {
          //   history.push("/business/");

          //   // history.push(`/business-profile/${selectedBusiness.id}`);
          // }
          setLoading(false);
        }
        else {
          setLoading(false);

          customSnackBar(res?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };
  const UpdateSchedule = async (values) => {
    setLoading(true);

    const data = formateDataUpdate(values, id);
    await instance

      .post(
        `/provideravailability_svc/pv/update/business/`,
        { data, timeZone: "Europe/London" }
      )
      .then((res) => {
        if (res?.data?.statusCode === 0) {
          handleClose()
          fetchBusinessSchdular(formRef.current);
          // if (actions === "new") {
          //   history.push("/business/");
          // } else if (actions === "businessProfile") {

          //   history.push(`/business-profile/${selectedBusiness.id}`);
          // }
          setLoading(false);
        }
        else {
          setLoading(false);

          customSnackBar(res?.data?.message);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };
  const fetchBusinessSchdular = useCallback(async (formik) => {
    if (id !== 0) {
      setLoader(true);
      await instance
        .get(
          `/provideravailability_svc/pv/business?businessId=${id}&timeZone=Europe/London`
        )
        .then((response) => {
          if (response?.data?.result?.length > 0) {
            const data = {
              data: response?.data?.result,
              actionState: "edit",
            };

            setScheduleData(data);
            formik.setFieldValue();

            setLoader(false)
          } else {
            customSnackBar("Business Schedualr not exist");
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => setLoader(false));
    }
    else {
      setLoader(false); // Stop the loader

    }
  }, [id]);
  const handleSubmit = (values, handles, result) => {
    if (actionState === "edit") {
      UpdateSchedule(values);
    } else {
      AddSchedule(values);
    }
    // updateBusiness(values, handles, result);
  }; useEffect(() => {
    // if (fetchBusinessSchdular && formRef && formRef.current) {
    fetchBusinessSchdular(formRef.current);
    // }
  }, [fetchBusinessSchdular, formRef]);
  const callBackHandler = () => {
    history.goBack();
  };


  return (
    <>
      <AppLayout

        name="Business Schdular"
        left={
          <LeftSection
            providerRecrord={businessRecrord} />
        }
         middle={
          <div className="position-relative">
             <div
              style={{
                position: "absolute",
                top: "7px",
                left: "-35px",
              }}
              onClick={callBackHandler}
              role="button"
            >
              <i id="icon_left_color" className="fas fa-chevron-left"></i>
            </div>
              <Schdular
                open={open}
                handleOpen={handleOpen}
                handleClose={handleClose}
                actionState={actionState}
                scheduleData={scheduleData}
                setScheduleData={setScheduleData}
                editData={editData}
                actions={actions}
                isMobile={isMobile}
                loading={loading}
                loader={loader}
                submitData={handleSubmit}
              />     
          </div>
        }
        right={
          <RightSection
            handleOpen={handleOpen}
            handleClose={handleClose}
            scheduleData={scheduleData}
          />
        }
      />
    </>
  );
};

export default BusinessSchdular;

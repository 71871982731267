import React, { useEffect } from "react";
import SideNavone from "../../../../SideNavBar";
import Mapsection from "../Mapsection/Mapsection";
import AppLayout from "../../../Layout/AppLayout";


function Addaddressfrommap() {
 
  useEffect(() => {
    // Scroll to the top of the page when the component loads
    window.scrollTo(0, 0);
  }, []);
  return (
    <AppLayout
    name="Business Locations"
      middle={<div className=" "
       style={{ height: "100vh", paddingBottom: "10px", overflow: "auto" }}>
        <Mapsection />
       
      </div>}
      />
  );
}

export default Addaddressfrommap;

import styles from "../AppLayout.module.css";

const TriLayout = ({ left, middle, right }) => {
  return (
    <div className={`${styles.triLayout} p-3 pb-5`}>
      <div className="row">
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 pe-0">
          <div className="text-break">{left}</div>
        </div>
        <div className="col-12 col-sm-4 col-md-12 col-lg-8 px-md-0 d-flex justify-content-center">
          <div className={`${styles.middle} text-break`}>{middle}</div>
        </div>
        <div className="col-12 col-sm-4 col-md-3 col-lg-2 ps-0">
          <div className="text-break">{right}</div>
        </div>
      </div>
    </div>
  );
};
export default TriLayout;

import React from "react";
import Actions from "./Actions";
import Avatar from "@mui/material/Avatar";
import { deepPurple } from "@mui/material/colors";
import { DataNotFound } from "../../../utils";
import TableCell from "@mui/material/TableCell";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import axios from "axios";
import instance from "../../../utils/utils";
const TableRows = (props) => {
  const {
    rowData,
    toggleDrawerHandler,
    selectedCheckboxes,
    rowsPerPage,
    page,
    handleRowCheckboxChange,
    deleteUser,
    business,
    permission
  } = props;

  const label = { inputProps: { "aria-label": "Switch demo" } };
  const tableData = rowData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );
  const [data, setData] = React.useState(rowData);
  const [switchStates, setSwitchStates] = React.useState([]);

  React.useEffect(() => {
    setSwitchStates(rowData.map((row) => row.isActive || false));
  }, [rowData]);
  const handleSwitchChange = (event, index, row) => {
    const updatedSwitchStates = [...switchStates];
    updatedSwitchStates[index] = event.target.checked;
    setSwitchStates(updatedSwitchStates);

    instance
      .patch(
        `/business_svc/pv/user/${row.id}/`,
        { isActive: event.target.checked }
      )
      .then((response) => {
        // Handle the response if needed
      })
      .catch((error) => {
        // Handle the error if needed
      });

    // Here, we're just updating the local state directly
    const updatedRow = { ...row, isActive: event.target.checked };
    setData((prevState) => {
      const updatedData = [...prevState];
      updatedData[index] = updatedRow;
      return updatedData;
    });
  };

  return (
    <>
      {rowData.length > 0 ? (
        rowData.map((row, index) => {
          return (
            <TableRow
              style={{ height: 20 }}
              hover
              role="checkbox"
              tabIndex={-1}
              key={row.code}
            >
               <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-center"
                id={"action-" + index}
              >
                <Actions
                permission={permission}
                  row={row}
                  index={index}
                  deleteUser={deleteUser}
                  business={business}
                  toggleDrawerHandler={toggleDrawerHandler}
                />
              </TableCell>
              <TableCell style={{ border: "1px solid #ededed" }}>
                <div className="d-flex align-items-center">
                  {/* <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                    {row && row.image ? (
                      <Avatar
                        src={row.image}
                        alt={"img"}
                        className=""
                        style={{
                          width: "30px",
                          height: "30px",
                          borderRadius: "50px",
                          marginRight: 15,
                        }}
                      />
                    ) : (
                      <div className="d-flex align-items-center">
                        <div className="text-white d-flex align-items-center justify-content-center rounded-pill mr-3 text-purple">
                          <Avatar
                            sx={{
                              bgcolor: deepPurple[500],
                              width: "30px",
                              height: "30px",
                              marginRight: 2,
                            }}
                          >
                            <span>OP</span>
                          </Avatar>
                        </div>
                      </div>
                    )}
                  </div> */}
                  <div>
                    {row.firstname ? (
                      <div className="font-size-sm text-capitalize">
                        <span className="bolds">
                          
                        {row.firstname} {row.lastname}
                        </span>
                      </div>
                    ) : (
                      <span className="bolds">
                        user Name
                        </span>
                    )}
                  </div>
                </div>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-center"
              >
                <span className="bolds">{row.id}</span>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-center"
              >
                {row?.role?.slice(0, 5).map((role) => (
                  <span className="bolds text-capitalize">{role}, &nbsp; </span>
                ))}
                {/* {row?.role?.length > 5 && (
                  <span className="size"> +{row.userNo.length - 5}</span>
                )} */}
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-center"
              >
                <span className="bolds">{row.email}</span>
              </TableCell>
              <TableCell
                style={{
                  backgroundColor:
                    row.status &&
                    (row.status.toLowerCase() === "pending"
                      ? "#e9b67a"
                      : row.status.toLowerCase() === "active"
                      ? "#b8d693"
                      : "transparent"),

                  border: "1px solid #ededed",
                }}
                className="text-center"
              >
                {/* <span
                  className={`${
                    row.status === "Pending" ? "pending" : "success"
                  }`}
                > */}
                <span className="statusText">{row.status}</span>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="d-flex justify-content-center"
              >
                <div className="d-flex justify-content-center">
                  <Switch
                    {...label}
                    onChange={(event) => handleSwitchChange(event, index, row)}
                    checked={row.isActive}
                    color="primary"
                    className="d-flex justify-content-center"
                  />
                </div>
              </TableCell>
              <TableCell
                style={{ border: "1px solid #ededed" }}
                className="text-center"
                id={"action-" + index}
              >
                <Actions
                permission={permission}

                  row={row}
                  index={index}
                  deleteUser={deleteUser}
                  business={business}
                  toggleDrawerHandler={toggleDrawerHandler}
                />
              </TableCell>
            </TableRow>
          );
        })
      ) : (
        <TableRow>
          <TableCell colSpan={7} align="center">
            <DataNotFound />{" "}
          </TableCell>
        </TableRow>
      )}
    </>
  );
};
export default TableRows;

import styles from "../AppLayout.module.css";

const DynamicLayout = ({ allSpace, name }) => {
  return (
    <div
      className={`${
        name === "Calendar"
          ? styles.dynamicLayoutCalender
          : styles.dynamicLayout
      }  ${name === "Create Template" ? "pb-5 ps-3 pe-3" : "p-3 pb-5"}`}>
      <div className="text-break">{allSpace}</div>
    </div>
  );
};
export default DynamicLayout;

const initialState = {
    selectedRole: [],
    selectedSwitch:[]
  };
  
  export const roleReducer = (state = initialState, action) => {
    switch (action.type) {
      case "SELECT_SWITCH":
            return { ...state, selectedSwitch: action.payload };
          case "DESELECT_SWITCH":
            return { ...state, selectedSwitch: [] };
      case 'SELECT_ROLE':
        return { ...state, selectedRole: action.payload };
      case 'DESELECT_ROLE':
        return { ...state, selectedRole: [] };
      default:
        return state;
    
    }    
         
  };
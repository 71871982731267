import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
  import Logo from "../../../Assets/Images/logo.png";
import { GoBell } from "react-icons/go";
import { Avatar } from "@mui/material";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { permissionModule } from "../../../Store/Auth/actions";

const TopHeader= ({ alluserdata ,selectedBusines}) => {
  const history = useHistory();
  const location = useLocation();
  const businessRecrord = JSON.parse(localStorage.getItem("businessRecord"));
  const userID = localStorage.getItem("userId");
  const dispatch = useDispatch();

  useEffect(() => {
    if(businessRecrord){
    dispatch(
      permissionModule({
        userID,
        businessID: businessRecrord?.id ? businessRecrord.id : 0,
      })
    );
  }
  }, []);
  return (
    <>
      <div className=" pt-1 pb-2 background_nav1_header">
        <div className="d-flex justify-content-between align-item-center">
           <div className="mx-2 ">
                <div >
                  <img
                    className="img-fluid"
                      src={Logo}
                    alt="kdsnda"
                    style={{
                      cursor: "pointer",
                      height: "1.8rem",
                      width: "1.8rem",
                    }} // Change cursor on hover
                  />
                </div>
             </div>
          {location.pathname.includes("/terms") ||
          location.pathname.includes("/privacy") ? null : (
            <div className="d-flex">
              <div className="d-flex">
                <div className="pt-1 ">
                  <GoBell
                    fontSize={18}
                    color="#fff"
                    alt="np-data"
                    src="/images/bell.svg"
                  />
                </div>
                &nbsp; &nbsp;
                <div className="pt-1 pe-4 hide" role="button" onClick={()=>history.push("/settings")}>
                  {/* <TfiSettings
               fontSize={19}
               color="#fff" */}
                  <img alt="np-data" src="/images/setting.svg" />
                </div>
                <div className="vl mt-md-0 me-md-3 pb-1" />
              </div>

              {/*-------------------------Selecting Business From Header HERE --------------------------*/}
              {/* {location.pathname.includes("/scheduler/create_booking") ||
              location.pathname.includes("/scheduler/create_break") ||
              location.pathname.includes("/scheduler/create_timeblock") ||
              location.pathname.includes("/view-page") ||
              location.pathname.includes(`/add-staff/${selectedBusines?.id}`) ||
              location.pathname.includes("/create-new-service") ||
              location.pathname.includes("/add-new-business") ||
              location.pathname.includes("/select-address") ||
              location.pathname.includes("/add-business-address") ||
              location.pathname.includes(`/add-business-schedular/${selectedBusines?.id}`) ||
              location.pathname.includes("/dynamic-form") ||
              location.pathname.includes("/customer-dynamic-fields") ||
              location.pathname.includes("/scheduler/create_event") ? null : (
                <BusinessListFromHeader />
              )} */}

              <div className=" pe-3 pt-md-0 section">
                <NavLink to="#">
                  {alluserdata?.imageURL ? (
                    <img
                      className=" width_profile_header"
                      src={alluserdata?.imageURL}
                      alt="B"
                      width="20px"
                      height="20px"
                    />
                  ) : (
                    <Avatar
                      sx={{ width: "2rem", height: "2rem" }}
                      //  src=
                      //  {user.imageURL}
                    />
                  )}
                  {/* <button className="btn btn-warning">Profile</button> */}
                </NavLink>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default TopHeader;

import React, { useEffect } from "react";
// import "../AddBusiness/AddBusiness.scss";
import SideNavone from "../../../../SideNavBar";
import { useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Field } from "formik";
import CustomPhoneInput from "../BusinessProfiles/CustomPhoneInput";

const OwnerDetail = ({
  values,
  errors,
  onSubmit,
  touched,
  setFieldValue,
  handleChange,
  action,
  isMobile,
  userInfo,
}) => {
  const [checkboxChecked, setCheckboxChecked] = useState(true);
  const [checkboxChecked1, setCheckboxChecked1] = useState(false);

  const history = useHistory();
  // var userInfo = useSelector((state) => state?.Auth?.alluserdataafterlogin);
  useEffect(() => {
    if (checkboxChecked) {
      setFieldValue("Owner.firstname", userInfo?.firstName);
      setFieldValue("Owner.lastname", userInfo?.lastName);
      setFieldValue("Owner.userEmail", userInfo?.primaryEmail);
      setFieldValue("Owner.gender", userInfo?.genderName);
      setFieldValue("Owner.contactNo", userInfo?.primaryMobile);
      setFieldValue("checkboxChecked", checkboxChecked);
    }
  }, [checkboxChecked, setFieldValue, userInfo]);

  useEffect(() => {
    if (checkboxChecked1) {
      setFieldValue("contactPerson.firstname", userInfo?.firstName);
      setFieldValue("contactPerson.lastname", userInfo?.lastName);
      setFieldValue("contactPerson.userEmail", userInfo?.primaryEmail);
      setFieldValue("contactPerson.gender", userInfo?.genderName);
      setFieldValue("contactPerson.contactNo", userInfo?.primaryMobile);
      setFieldValue("checkboxChecked1", checkboxChecked1);
    }
  }, [checkboxChecked1, setFieldValue, userInfo]);

  const handleCheckboxChange = (event) => {
    setCheckboxChecked(event.target.checked);
  };

  const handleCheckboxChange1 = (event) => {
    setCheckboxChecked1(event.target.checked);
  };
  return (
    <div className="">
      <div className="d-flex  mt-3 ">
        <span className=" sub_text_color_account_alltext1 ps-1">
          Business Owner
        </span>
      </div>{" "}
      <div className="col-md-12 border_rad_new px-2 py-1  mb-2 mt-1 ">
        <div className="  py-2">
          <div className="row">
            <div className={`${!isMobile ? "col-md-11 " : "col-10"}`}>
              <span
                className={`ps-1 text-size ${
                  checkboxChecked ? "text-black" : "owner-text"
                }`}>
                {userInfo && userInfo.firstName} {userInfo && userInfo.lastName}
              </span>
            </div>
            <div
              className={`${
                !isMobile ? "col-md-1 text-center " : "col-1 ms-4"
              }`}>
              {/* <div
                onChange={handleCheckboxChange}
                >
              <svg width="9" height="6" viewBox="0 0 9 6" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M1 1L4.75 4.75L8.5 1" stroke="#AAAAAA" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

              </div> */}
              <input
                className="form-check-input text-end"
                type="checkbox"
                defaultValue=""
                id="defaultCheck1"
                checked={checkboxChecked}
                onChange={handleCheckboxChange}
              />
            </div>
          </div>
        </div>
      </div>
      {checkboxChecked ? (
        <>
          <div className="row  mb-2">
            <div className="col-md-6 pe-2">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "10px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  First Name
                </label>
                <input
                  className="form-control1 input_style_fix"
                  type="text"
                  id="Owner.firstname"
                  name="Owner.firstname"
                  value={values?.Owner?.firstname}
                  // onChange={(newValue) => {
                  //   setFieldValue("businessName", newValue);
                  // }}
                  onChange={handleChange}
                  placeholder="Enter First Name"
                />
              </div>
              {errors?.Owner?.firstname && (
                <p className="text-danger f-12">{errors.Owner.firstname}</p>
              )}
            </div>
            <div className="col-md-6 pe-2">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "10px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Last Name
                </label>
                <input
                  className="form-control1 input_style_fix"
                  type="text"
                  id="Owner.lastname"
                  name="Owner.lastname"
                  value={values?.Owner?.lastname}
                  // onChange={(newValue) => {
                  //   setFieldValue("businessName", newValue);
                  // }}
                  onChange={handleChange}
                  placeholder="Enter Last Name"
                />
              </div>
              {errors?.Owner?.lastname && (
                <p className="text-danger f-12">{errors?.Owner?.lastname}</p>
              )}
            </div>
          </div>

          <div className="row  mb-2">
          <div className="col-md-6 pe-2 ">
              <div
                className="bg-white custom-border"
                style={{
                  padding: "6px",
                  borderRadius: "8px",
                }}>
                <div className="input-group tel-input-group">
                  <span
                    className="input-label profile_fields_label mb-0"
                    style={{
                      zIndex: 1,
                      marginTop: "-7px",
                      marginLeft: "-6px",
                    }}>
                    Contact No
                  </span>
                  <div>
                    <CustomPhoneInput
                      styles="booking"
                      id="Owner.contactNo"
                  name="Owner.contactNo"
                  value={values?.Owner?.contactNo}
                  // onChange={(newValue) => {
                  //   setFieldValue("businessName", newValue);
                  // }}
                  onChange={handleChange}
                  placeholder="Enter Contact Number"
                    />
                  </div>
                </div>
              </div>
              {errors?.Owner?.contactNo && (
                <p className="text-danger f-12">{errors?.Owner?.contactNo}</p>
              )}
            </div>
            <div className="col-md-6 pe-2">
            <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "10px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Email
                </label>
                <input
                  className="form-control1 input_style_fix"
                  type="email"
                  id="Owner.userEmail"
                  name="Owner.userEmail"
                  value={values.Owner.userEmail}
                  // onChange={(newValue) => {
                  //   setFieldValue("businessName", newValue);
                  // }}
                  onChange={handleChange}
                  placeholder="Enter Email"
                />
              </div>
              {errors?.Owner?.userEmail && (
                <p className="text-danger f-12">{errors?.Owner?.userEmail}</p>
              )}
            </div>
         
          </div>
        </>
      ) : (
        <div className="pt-2 pb-2">
          {/* <Button
            sx={{
              border: "1px solid #FFD705",
              backgroundColor: "#fff",
              height: "30px",
              borderRadius: "8px",
              color: "#FFD705",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: "200",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#FFD705",
              },
            }}
            variant="contained"
            onClick={() => {
              setCheckboxChecked(false);
            }}>
            + &nbsp;{"Add New Owner"}
          </Button> */}
        </div>
      )}
      {/* Second Section    -----------------------------------------------------------------------------------------         */}
      <div className="d-flex  ">
        <span className=" sub_text_color_account_alltext1 ps-1">
          Main Contact Person{" "}
        </span>
      </div>{" "}
      <div className="col-md-12 border_rad_new px-2 py-1  mb-2  mt-1 ">
        <div className="  py-2">
          <div className="row">
            <div className={`${!isMobile ? "col-md-11 " : "col-10"}`}>
              <span
                className={`ps-1 text-size ${
                  !checkboxChecked1 ? "text-black" : "owner-text"
                }`}>
                {userInfo && userInfo?.firstName}{" "}
                {userInfo && userInfo?.lastName}
              </span>
            </div>
            <div
              className={`${
                !isMobile ? "col-md-1 text-center " : "col-1 ms-4"
              }`}>
              <input
                className="form-check-input text-end"
                type="checkbox"
                defaultValue=""
                id="defaultCheck1"
                checked={checkboxChecked1}
                onChange={handleCheckboxChange1}
              />
            </div>
          </div>
        </div>
      </div>
      {checkboxChecked1 ? (
        <>
          <div className="row  mb-2">
            <div className="col-md-6 pe-2">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "10px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  First Name
                </label>
                <input
                  className="form-control1 input_style_fix"
                  type="text"
                  id="contactPerson.firstname"
                  name="contactPerson.firstname"
                  value={values?.contactPerson?.firstname}
                  // onChange={(newValue) => {

                  //   setFieldValue("businessName", newValue);
                  // }}
                  onChange={handleChange}
                  placeholder="Enter First Name"
                />
              </div>
              {errors?.contactPerson?.firstname && (
                <p className="text-danger f-12">
                  {errors?.contactPerson?.firstname}
                </p>
              )}
            </div>
            <div className="col-md-6 pe-2">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "10px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Last Name
                </label>
                <input
                  className="form-control1 input_style_fix"
                  type="text"
                  id="contactPerson.lastname"
                  name="contactPerson.lastname"
                  placeholder="Enter Last Name"
                  value={values?.contactPerson?.lastname}
                  onChange={handleChange}
                />
              </div>
              {errors?.contactPerson?.lastname && (
                <p className="text-danger f-12">
                  {errors?.contactPerson?.lastname}
                </p>
              )}
            </div>
          </div>

          <div className="row  mb-2">
            <div className="col-md-6 pe-2">
              <div
                className="background_color_showboxes ps-2 pb-1 "
                style={{
                  display: "grid",

                  alignItems: "center",
                  gap: "10px",
                }}>
                <label className="profile_fields_label sub_text_color_account_alltext_show">
                  Email
                </label>
                <input
                  className="form-control1 input_style_fix"
                  type="email"
                  id="contactPerson.userEmail"
                  name="contactPerson.userEmail"
                  placeholder="Enter Email"
                  value={values?.contactPerson?.userEmail}
                  onChange={handleChange}
                />
              </div>
              {errors?.contactPerson?.userEmail && (
                <p className="text-danger f-12">
                  {errors.contactPerson.userEmail}
                </p>
              )}
            </div>
            <div className="col-md-6 pe-2 ">
              <div
                className="bg-white custom-border"
                style={{
                  padding: "6px",
                  borderRadius: "8px",
                }}>
                <div className="input-group tel-input-group">
                  <span
                    className="input-label profile_fields_label mb-0"
                    style={{
                      zIndex: 1,
                      marginTop: "-7px",
                      marginLeft: "-6px",
                    }}>
                    Contact No
                  </span>
                  <div>
                    <CustomPhoneInput
                      styles="booking"
                      type="text"
                      id="contactPerson.contactNo"
                      name="contactPerson.contactNo"
                      placeholder="Enter Contact"
                      value={values?.contactPerson?.contactNo}
                      onChange={handleChange}
                    />
                  </div>
                </div>
              </div>
              {errors?.contactPerson?.contactNo && (
            <p className="text-danger f-12">
              {errors?.contactPerson?.contactNo}
            </p>
          )}
            </div>
          </div>
        </>
      ) : (
        <div className="pt-2">
          {/* <Button
            sx={{
              border: "1px solid #FFD705",
              backgroundColor: "#fff",
              height: "30px",
              borderRadius: "8px",
              color: "#FFD705",
              textTransform: "capitalize",
              fontSize: "12px",
              fontWeight: "200",
              "&:hover": {
                backgroundColor: "#fff",
                color: "#FFD705",
              },
            }}
            variant="contained"
            onClick={() => {
              setCheckboxChecked1(false);
            }}>
            + &nbsp;{"Add New Manager"}
          </Button> */}
        </div>
      )}
    </div>
  );
};

export default OwnerDetail;
